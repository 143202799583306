import React, { useEffect, useState, useContext } from "react";
import "../Login/Login.css";
import NavbarMain from "../../Components/NavbarMain";

import { FaEyeSlash, FaRegEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { logIn, logOut } from "../../Redux/Action/index";
import Axios from "axios";

import { logInStatus } from "../../Redux/Action/loginstatus";
import LoadingSpinner from "../../Components/Loading/Loading";
import { AuthContext } from "../../context/AuthContext";

const Login = () => {
  const { setAuthUser } = useContext(AuthContext);
  const LoginImg =
    "https://marstrackblobstorage.blob.core.windows.net/marstrackwebapp/safari5.png";
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errData, setErrData] = useState("");
  const [emailFormatError, setEmailFormatError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const myState = useSelector((state) => state.changeTheState);
  const [servererror, setserverError] = useState("");
  const loginState = useSelector((state) => state.changeLoginState);
  let serverResponse = true;
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();
  // const socket = io("http://localhost:3001");
  function clearState() {
    dispatch(logIn([]));

    dispatch(logInStatus("FALSE"));
  }
  useEffect(() => {
    clearState();
  }, []);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  function handleClick() {
    setIsLoading(true);
    if (
      (email == "admin@gmail.com" || email == "Admin@gmail.com") &&
      password === "admin123"
    ) {
      navigate("/SafariDashboard");
    } else {
      if (serverResponse) {
        // if (!/\S+@\S+\.\S+/.test(email)) {
        //   alert("Invalid email format");
        //   setIsLoading(false);
        //   return;
        // }

        try {
          //console.log(email);
          //console.log(password);

          Axios.post(`${BASEURL}/driverlogin`, {
            email: email,
            password: password,
          })
            .then((response) => {
              //console.log(response);
              try {
                if (response.data.message) {
                  setIsLoading(false);
                  setserverError(response.data.message);
                  dispatch(logIn([])); // Clear previous login data
                  dispatch(logInStatus("FALSE"));
                } else {
                  dispatch(logIn(response.data));
                  dispatch(logInStatus("TRUE"));
                  //console.log(response);
                  window.sessionStorage.setItem(
                    "auth-token",
                    response.data.authenticationToken
                  );
                  window.sessionStorage.setItem(
                    "session-token",
                    response.data.sclientSecret
                  );
                  setAuthUser(response.data.authenticationToken);

                  console.log(response.data.sclientSecret);
                  if (response.data.user.designation === "DRIVER") {
                    navigate("/DriverMenu");
                  } else if (response.data.user.designation === "REP") {
                    navigate("/DriverMenu");
                  } else if (response.data.user.designation === "MANAGER") {
                    navigate("/ManagerMenu");
                  }

                  setIsLoading(false);
                }
              } catch {
                if (response.status === 501) {
                  setserverError("Internal server error");
                } else if (response.status === 401) {
                  setserverError("Wrong username or password");
                }
                setIsLoading(false);
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status === 401) {
                  setserverError("Wrong username or password");
                } else if (error.response.status === 501) {
                  setserverError("Internal server error");
                }
                setIsLoading(false);
              } else {
                setserverError(
                  "No response from server or unmatched email or password"
                );
                setIsLoading(false);
              }
            });
        } catch {
          setserverError(
            "No response from server or unmatched email or password"
          );
          setIsLoading(false);
        }
      } else {
        setserverError("Server is down. Please try again later.");
        setIsLoading(false);
      }
    }
  }
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      {/* <NavbarMain /> */}
      <div className="background-image-container">
        <img
          src={LoginImg}
          alt="Login Background"
          className="background-image"
        />
        <h1 className="text-[#fff] z-[700] lg:absolute text-[30px] top-3 lg:left-8 lg:p-5 mx-1 lg:mx-0 flex justify-center mt-[50px] al rounded bg-[#000000b7]">
          {" "}
          TecH23 Safari Application
        </h1>
        <div className="content">
          <div className="flex justify-end h-[80vh] lg:h-[100vh] items-center z-[500] ">
            <div className="container image_forbg">
              <div className="text mt-[20px] text-white"></div>
              <div className="mt-[20px] font-bold text-center text-[30px] text-white">
                Login
              </div>
              <div className="form__div">
                <div className="form-row">
                  <div className="input-data">
                    <input
                      type="text"
                      required
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailFormatError(false); // Clear email format error on input change
                      }}
                    />
                    <div className="underline"></div>
                    <label htmlFor="">Email Address</label>
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-data">
                    <input
                      type={showPassword ? "text" : "password"}
                      required
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    <div className="underline"></div>
                    <label htmlFor="">Password</label>
                    <p className="cursor__pointer" onClick={togglePassword}>
                      {showPassword ? <FaRegEye /> : <FaEyeSlash />}
                    </p>
                    <span class="w-[100%] inline-block my-[10px] text-[#f9f9f4] text-sm">
                      <Link to="/ForgotPwd">
                        <a class="forgotpass">forgot password</a>
                      </Link>
                    </span>
                  </div>
                </div>

                <div className="flex justify-center mt-[50px]">
                  <button
                    onClick={handleClick}
                    className="bg-violet-700 hover:bg-violet-900 font-bold text-white py-2 px-10 rounded hover:shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
