import React from "react";
import "./UploadLoader.css";
const UploadLoader = () => {
  return (
    <div className="upload-overlay">
      <div className="flex justify-center items-center flex-col upload-loader z-50">
        <p className="my-2"> Uploading...</p>
        <p class="UploadLoader"></p>
      </div>
    </div>
  );
};

export default UploadLoader;
