import React, { useState, useEffect } from "react";
import axios from "axios";
import MenuNavbar from "../../Components/Navbar/MenuNavbar";
import { FaEdit } from "react-icons/fa";
import { BsFillClipboardDataFill } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import Loading from "../../Components/Loading/Loading";
import Select from "react-select";
import "../InternalTransfer/InternalTransfer.css";
function InternalTransfer() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [pickupplace, setpickupplace] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  function ModuleInterface() {
    setIsLoading(true);
    axios
      .get(`${BASEURL}/getLoadingPoints`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        //console.log(response.data, "setSafariDetails");
        //console.log(response.data, "new");
        setpickupplace(response.data);
        setIsLoading(false);
      });
  }
  useEffect(() => {
    ModuleInterface();
  }, []);
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "400px",
      marginTop: "0px",
      minHeight: "initial",
      height: "45px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  const [activeTab1, setActiveTab1] = useState(1);
  const [activeTab2, setActiveTab2] = useState(1);

  const handleTabClick1 = (tabNumber) => {
    setActiveTab1(tabNumber);
  };
  const handleTabClick2 = (tabNumber) => {
    setActiveTab2(tabNumber);
  };
  const renderList = (
    <div>
      <MenuNavbar />
      <header className="header__of__main flex justify-between items-end px-3">
        <h1 className="header__of__page">Safari Planning</h1>
        <aside className="btn_end">
          <button className="button_style ">New</button>
          <button className="button_style ">View Occupied Days</button>
        </aside>
      </header>

      <div className="last_transac">
        <fieldset className="fieldset_pro">
          <legend className="text-xl">Last Transaction</legend>
          <label>Trans No</label>
          <input style={{ width: "100px" }} />
          <input style={{ width: "100px" }} />
          <label>Date</label>
          <input />
          <label>Time</label>
          <input />
          <label>Location</label>
          <input />
          <label>Pax</label>
          <input />
        </fieldset>
      </div>
      <article className="button_full_width">
        <div className="mx-2">
          <button
            className={activeTab1 === 1 ? "active__tab" : "button_style__tab"}
            onClick={() => handleTabClick1(1)}
          >
            Safari Trip
          </button>
          <button
            className={activeTab1 === 2 ? "active__tab" : "button_style__tab"}
            onClick={() => handleTabClick1(2)}
          >
            Journey Planning
          </button>
        </div>
      </article>

      <section
        style={{ display: activeTab1 === 1 ? "block " : "none" }}
        className={activeTab1 === 1 ? "active__section" : ""}
      >
        {" "}
        <aside className="main">
          <p>
            <label>Transaction No</label>
            <input style={{ width: "100px" }}></input>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>Vehicle</label>
            <div
              style={{
                width: "300px",
                margin: "5px",
              }}
            >
              <Select style={customStyless}></Select>
            </div>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>Driver</label>
            <div
              style={{
                width: "300px",
                margin: "5px",
              }}
            >
              {" "}
              <Select style={customStyless}></Select>
            </div>
          </p>
          <p>
            <label>Pax</label>
            <input />
          </p>
        </aside>
        <aside className="main">
          <p>
            <label>Date</label>
            <input type="date"></input>
          </p>
          <p>
            <label>Pick Time</label>
            <input type="time"></input>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>Pick Up</label>
            <p
              style={{
                width: "300px",
                margin: "5px",
              }}
            >
              <Select
              // options={pickupplace.map((item) => ({
              //   value: item.LOADINGPOINT,
              //   label: item.LOADINGPOINT,
              // }))}
              // type="text"
              ></Select>
            </p>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>Drop</label>
            <div
              style={{
                width: "300px",
                margin: "5px",
              }}
            >
              <Select type="text"></Select>
            </div>
          </p>
          <p>
            <label>Drop Time</label>
            <input type="time"></input>
          </p>
        </aside>
        <aside>
          <main className="main_narr">
            <div className="main">
              <label>Narration</label>
              <textarea></textarea>
            </div>
            <div>
              <p>
                <label>Booking Ref</label>
                <input></input>
                <button className="button_style ">...</button>
                <input></input>
                <button className="button_style ">Save</button>
              </p>
              <p>
                <label>Client</label>
                <input></input>
              </p>
            </div>
          </main>
        </aside>
        <aside className="main_narr">
          <div>
            <label>From</label>
            <input type="date"></input>
          </div>
          <div>
            <label>To</label>
            <input type="date"></input>
          </div>
          <div>
            <button className="button_style " s>
              Refresh
            </button>
          </div>
        </aside>
      </section>
      <section
        style={{ display: activeTab1 === 2 ? "block" : "none" }}
        className={activeTab1 === 2 ? "active__section" : ""}
      >
        <aside className="main_narr">
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>Pick Up</label>
            <p
              style={{
                width: "300px",
                margin: "5px",
              }}
            >
              <Select
                type="text"
                options={pickupplace.map((item) => ({
                  value: item.LOADINGPOINT,
                  label: item.LOADINGPOINT,
                }))}
              ></Select>
            </p>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>Drop</label>
            <div
              style={{
                width: "300px",
                margin: "5px",
              }}
            >
              <Select type="text"></Select>
            </div>
          </p>
          <div>
            <input type="date"></input>
          </div>
          <div>
            <input type="time"></input>
          </div>
          <div className="flex">
            <button className="button_style ">Add</button>
            <button className="button_style ">Remove</button>
          </div>
        </aside>
        <aside className="table__reference__app ">
          <table class="custom-table">
            <thead class="header-cell">
              <td>MODULE&nbsp;ID</td>

              <td>EDIT</td>
            </thead>
            <tbody>
              <tr class="table-row">
                <td className="alignleft">1</td>

                <td>
                  <FaEdit />
                </td>
              </tr>
            </tbody>
          </table>
        </aside>
        <div className="flex justify-end">
          <button className="px-5 py-1  bg-green-300 font-bold rounded mx-1">
            Save Plan
          </button>
          <button button className="px-5 py-1  bg-green-300 font-bold rounded">
            Print Plan
          </button>
        </div>
      </section>

      <article className="button_full_width">
        <div className="mx-2">
          <button
            className={activeTab2 === 1 ? "active__tab" : "button_style__tab"}
            onClick={() => handleTabClick2(1)}
          >
            Pending Safari - Confirmed Bookings
          </button>
          <button
            className={activeTab2 === 2 ? "active__tab" : "button_style__tab"}
            onClick={() => handleTabClick2(2)}
          >
            Pending Drop offs
          </button>
        </div>
      </article>

      <section
        style={{ display: activeTab2 === 1 ? "block " : "none" }}
        className={activeTab2 === 1 ? "active__section" : ""}
      >
        <aside className="table__reference__app ">
          <table class="custom-table">
            <thead class="header-cell">
              <td>MODULE&nbsp;ID</td>

              <td>EDIT</td>
            </thead>
            <tbody>
              <tr class="table-row">
                <td className="alignleft">1</td>

                <td>
                  <FaEdit />
                </td>
              </tr>
            </tbody>
          </table>
        </aside>
      </section>
      <section
        style={{ display: activeTab2 === 2 ? "block" : "none" }}
        className={activeTab2 === 2 ? "active__section" : ""}
      ></section>
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
}

export default InternalTransfer;
