const initialState = {};
const journeyStatus = (state = initialState, action) => {
  switch (action.type) {
    case "JOURNEYSTATUSUPDATE":
      return action.data;
    default:
      return state;
  }
};

export default journeyStatus;
