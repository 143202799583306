// import React, { useState, useEffect } from "react";
// import { FaLocationDot } from "react-icons/fa6";
// import { IoIosSunny } from "react-icons/io";
// const WeatherApp = () => {
//   const [weatherData, setWeatherData] = useState(null);
//   const apiKey = "44a3b014636d4a838e7123935243101"; // Replace with your actual WeatherAPI API key
//   const [userLocation, setUserLocation] = useState(null);
//   const currentDate = new Date();
//   const days = [
//     "Sunday",
//     "Monday",
//     "Tuesday",
//     "Wednesday",
//     "Thursday",
//     "Friday",
//     "Saturday",
//   ];
//   const months = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];

//   const dayOfWeek = days[currentDate.getDay()];
//   const dayOfMonth = currentDate.getDate();
//   const month = months[currentDate.getMonth()];

//   useEffect(() => {
//     // Get user's current location
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const { latitude, longitude } = position.coords;
//           setUserLocation({ latitude, longitude });
//         },
//         (error) => {
//           console.error("Error getting user location:", error);
//         }
//       );
//     } else {
//       console.error("Geolocation is not supported by this browser.");
//     }
//   }, []);

//   useEffect(() => {
//     if (userLocation) {
//       const { latitude, longitude } = userLocation;
//       const apiUrl = `http://api.weatherapi.com/v1/forecast.json?key=${apiKey}&q=${latitude},${longitude}&days=7&aqi=yes&alerts=yes`;

//       fetch(apiUrl)
//         .then((response) => response.json())
//         .then((data) => {
//           setWeatherData(data);
//           //console.log(data);
//         })
//         .catch((error) => {
//           console.error("Error fetching data:", error);
//         });
//     }
//   }, [userLocation]);

//   return (
//     <div>
//       <h1>Weather App</h1>

//       {weatherData ? (
//         <div>
//           <div className="mx-5 rounded px-5 py-2 flex bg-gradient-to-r gap-3 from-blue-500 to-cyan-500 text-white items-center justify-between w-[500px]">
//             <div className="flex flex-col items-center">
//               <p className="mx-2"> {weatherData?.current?.condition?.text}</p>{" "}
//               <p className="text-[40px]">
//                 {" "}
//                 {weatherData?.current?.temp_c}°
//                 <span className="text-[24px]">C</span>
//               </p>
//             </div>
//             <div>
//               <aside className="flex items-center gap-1">
//                 <h2 className="text-[12px]">
//                   <FaLocationDot />
//                 </h2>
//                 <h2 className="text-[18px]">{weatherData?.location?.name}</h2>
//               </aside>
//               <aside>
//                 <p>
//                   {dayOfWeek}, {dayOfMonth} {month}
//                 </p>

//                 <p>Wind: {weatherData?.current?.wind_kph} km/h</p>
//               </aside>
//             </div>
//             <div className="text-[100px] text-yellow-200  flex justify-end">
//               <IoIosSunny />
//             </div>
//           </div>

//           {/* Display forecast data */}
//           {weatherData.forecast.forecastday.map((forecastDay) => (
//             <div key={forecastDay.date}>
//               <h3>{forecastDay.date}</h3>
//               <p>Max Temperature: {forecastDay.day.maxtemp_c}°C</p>
//               <div className="flex gap-2 w-[400px] overflow-auto">
//                 {forecastDay.hour.map((hour) => (
//                   <div key={hour.time}>
//                     <p>H: {hour.time.slice(10)}</p>
//                     <p>T: {hour.temp_c}°C</p>
//                     {/* Add more hourly forecast information here */}
//                   </div>
//                 ))}
//               </div>
//               <p>Min Temperature: {forecastDay.day.mintemp_c}°C</p>
//               {/* Add more forecast information here */}
//             </div>
//           ))}
//           {/* Display weather alerts */}

//           {weatherData.alerts ? (
//             <div>
//               <h2>Alerts</h2>
//               {weatherData.alerts.alert.map((alert) => (
//                 <div key={alert.headline}>
//                   <h3>{alert.headline}</h3>
//                   <p>Severity: {alert.severity}</p>
//                   <p>Category: {alert.category}</p>
//                   {/* Add more alert information here */}
//                 </div>
//               ))}
//             </div>
//           ) : (
//             <p>No alerts</p>
//           )}
//         </div>
//       ) : (
//         <p>Loading...</p>
//       )}
//     </div>
//   );
// };

// export default WeatherApp;
import React from "react";

const WeatherApp = () => {
  return <div>WeatherApp</div>;
};

export default WeatherApp;
