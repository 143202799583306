import React, { useState, useContext } from "react";

import "./Navbar.css";

import { FaBars, FaTimes } from "react-icons/fa";
// import "../src/Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdLogout } from "react-icons/md";
import { logIn } from "../../Redux/Action";
import { FaStepBackward } from "react-icons/fa";
import { AuthContext } from "../../context/AuthContext";
const DriverNavbar = () => {
  const { setAuthUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const state = useSelector((state) => state?.changeTheState);
  //console.log(state?.user?.userName, "nav");
  //console.log(state?.user?.ADMINISTRATOR, "ADMINISTRATOR");

  //console.log(state, "nav");
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  const dispatch = useDispatch();
  function logOut() {
    dispatch(logIn([]));
    navigate("/");
    setAuthUser(null);
  }

  const handleCategory = (category) => {
    setSelectedCategory((prevCategory) =>
      prevCategory === category ? null : category
    );
  };
  return (
    <nav className="centernav sticky top-0 z-[1000] bg-white">
      <div className="navbar-container">
        <nav className="navbar block ">
          <div className="block ">
            <div className="fabar" onClick={toggleMenu}>
              {isMenuOpen ? <FaTimes /> : <FaBars />}
            </div>
          </div>
          <ul className={`nav-menu ${isMenuOpen ? "open" : ""}`}>
            <div className="hover-dropdown uppercase">
              <li onClick={() => navigate("/EntireTripDetails")}>
                All Trip Details
              </li>{" "}
              <li onClick={() => navigate("/DriverAllTripDetails")}>
                My Trip Details
              </li>
              {state?.user?.designation === "DRIVER" && (
                <li onClick={() => navigate("/DriverFuelVoucher")}>
                  Fuel Voucher
                </li>
              )}
              {state?.user?.designation === "DRIVER" && (
                <li onClick={() => navigate("/DriverAttendence")}>
                  Driver Attendence
                </li>
              )}
              {/* <li onClick={() => navigate("/HistoricalFuelVoucher")}>
                Historical Fuel Voucher
              </li> */}
            </div>
          </ul>
        </nav>
        <div className="flex justify-between items-center w-[80%]">
          <aside className="welcom__nav">
            <h1 className="font-bold text-lg text-white ">Welcome to TecH23</h1>
          </aside>
          <aside>
            <h1 className="font-bold ml-1 md:text-lg text-white uppercase">
              Welcome : {state?.user?.userName ? state?.user?.userName : " "}
            </h1>
          </aside>
        </div>
        <aside>
          <button onClick={logOut} className="button__logout__navbar">
            {" "}
            LOGOUT
          </button>
        </aside>
        {/* <aside>
          <button
            onClick={() => {
              navigate("/DriverMenu");
            }}
            className="button__logout__navbar"
          >
            {" "}
            DASHBOARD
          </button>
        </aside> */}
        <div className="logout__fullscren">
          <button onClick={logOut} className="log__out__icon">
            {" "}
            <MdLogout />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default DriverNavbar;
