import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import DriverMenuNavbar from "../../Components/Navbar/DriverMenuNavbar";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { VscFeedback } from "react-icons/vsc";
import { FaRegEdit } from "react-icons/fa";
import Loading from "../../Components/Loading/Loading";
const SafariPlan = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const state = useSelector((state) => state.journeyStatus);
  //console.log(state);
  const TripState = useSelector((state) => state.Tripstatusreducer);
  const stateDriverName = useSelector(
    (stateDriverName) => stateDriverName?.changeTheState
  );
  const [address, setAddress] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [dateVariable, setDateVariable] = useState("");
  const [timeVariable, setTimeVariable] = useState("");
  const [isOpen2, setIsOpen2] = useState(false);
  const [safariPlanData, setSafariPlanData] = useState([]);
  const [tripreferenecNumber, setTripReferenceNumber] = useState("");
  const [tripNumberpass, setTripnumberpass] = useState("");
  //km
  const [currentDateTime, setcurrentDateTime] = useState("");
  const [unitIdFuel, setUnitIdFuel] = useState("");
  const [kmReadingGeo, setKmReadingGeo] = useState("");
  const [vehiclePlannedData, setVehiclePlannedData] = useState("");
  const [fromAddressToPass, setFromAddressToPass] = useState("");
  const [toAddressToPass, setToAddressToPass] = useState("");
  const [bookingRefPass, setBookingRefPass] = useState("");
  const [bookingTimePass, setBookingTimePass] = useState("");
  const [startTripHandle, setStartTripHandle] = useState(null);
  const [endTripHandle, setEndTripHandle] = useState(null);

  const openPopup2 = () => {
    setIsOpen2(true);
  };

  const closePopup2 = () => {
    setIsOpen2(false);
  };
  const navigate = useNavigate();
  //console.log(state, "state submit");
  //console.log(state.geoCod);
  let timeDifferenceFormatted = "";
  if (state.dbData.STARTDATETIME > "00:00:00.000") {
    const startDate = state.dbData.STARTDATETIME;
    const dateString = startDate;
    const date = new Date(dateString);

    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    const milliseconds = String(date.getUTCMilliseconds()).padStart(3, "0");

    const formattedTime = `${hours}:${minutes}:${seconds}.${milliseconds}`;

    //console.log(formattedTime);
    // kenya
    const currentTimeKenyaObj = new Date().toLocaleTimeString("en-US", {
      timeZone: "Africa/Nairobi",
      hour12: false,
    });

    //console.log(`Current time in Kenya (EAT): ${currentTimeKenyaObj}`);

    const formattedTimeMillis = new Date(
      `2000-01-01T${formattedTime}Z`
    ).getTime();
    const kenyaTimeMillis = new Date(
      `2000-01-01T${currentTimeKenyaObj}Z`
    ).getTime();

    // Calculate the time difference in milliseconds
    const timeDifferenceMillis = kenyaTimeMillis - formattedTimeMillis;

    // Convert the time difference to a formatted string
    timeDifferenceFormatted = new Date(timeDifferenceMillis)
      .toISOString()
      .substr(11, 8);

    //console.log(`Time difference: ${timeDifferenceFormatted}`);
  }
  //console.log(
  //   stateDriverName?.user?.userName,
  //   "stateDriverName?.user?.userName"
  // );

  async function updateStatus(type) {
    if (state.address) {
      //console.log(state.address);
      const confirmed = window.confirm(
        `Are you sure you want to update the status to ${type}?`
      );

      if (confirmed) {
        //console.log(type);
        setIsLoading(true);
        try {
          const response = await axios.post(
            `${BASEURL}/updateDriverTripStatus`,
            {
              BOOKINGID: state.dbData.BOOKINGID,
              TRIPID: state.dbData.TRIPNUMBER,
              STARTORENDUPDATE: type,
              GEOCOORDINATES: state.geoCod,
              LOCATIONDESCRIPTION: state.address,
              DATE: dateVariable,
              TIME: timeVariable,
              TRIPSTARTENDBY: stateDriverName?.user?.userName,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          );
          //console.log(response);
          alert(response.data.message);
          navigate("/DriverMenu");
          setIsLoading(false);
        } catch (err) {
          alert(err.response.data.message);
          setIsLoading(false);
        }
      } else {
        alert("Update canceled by the user.");
      }
    }
  }

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];
    const currentTime = currentDate.toTimeString().split(" ")[0];
    const formatWithLeadingZeros = (number) => {
      return number.toString().padStart(2, "0");
    };
    setDateVariable(formattedDate);
    setTimeVariable(currentTime);
    gatFuelData();
    const now = new Date();
    const currentHours = formatWithLeadingZeros(now.getHours());
    const currentMinutes = formatWithLeadingZeros(now.getMinutes());
    const formattedTime = `${currentHours}:${currentMinutes}`;

    const currentYear = now.getFullYear();
    const currentMonth = formatWithLeadingZeros(now.getMonth() + 1); // Months are zero-indexed
    const currentDay = formatWithLeadingZeros(now.getDate());

    const currentSeconds = formatWithLeadingZeros(now.getSeconds());
    const formattedDateTime = `${currentDay}/${currentMonth}/${currentYear}T${currentHours}${currentMinutes}${currentSeconds}`;

    setcurrentDateTime(formattedDateTime);

    // fetchData();

    setIsLoading(false);
  }, [unitIdFuel]);
  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };
  //console.log("Date Variable:", dateVariable);
  //console.log("Time Variable:", timeVariable);
  const isTripStarted = state.dbData.STARTEDTRIP;
  const isTripEnded = state.dbData.ENDEDTRIP;
  const dataFromPage = state?.fromPage;
  //console.log(dataFromPage);
  //console.log(isTripStarted, isTripEnded, "isTripEnded");
  useEffect(() => {
    fetchSafariPlanData();
  }, []);
  function fetchSafariPlanData() {
    //console.log(state.dbData.TRIPNUMBER);
    //console.log(state.dbData.REFNO);

    const referenceNumber = state.dbData.REFNO;
    const tripNumber = state.dbData.TRIPNUMBER;
    setTripReferenceNumber(state.dbData.REFNO);
    setTripnumberpass(state.dbData.TRIPNUMBER);
    if (referenceNumber === "") {
      alert("Journey REF NO not available");
      return;
    }
    if (tripNumber === "") {
      alert("Journey TRIP NUMBER not available");
      return;
    }
    if (referenceNumber && tripNumber) {
      axios
        .post(
          `${BASEURL}/getSafariPlan`,
          {
            BOOKINGREF: referenceNumber,
            TRIPNUMBER: tripNumber,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          openPopup2();
          //console.log(response.data, "setSafariPlanData");
          setSafariPlanData(response.data);
        });
    }
  }
  const calculateTimeDifference = (startDate, startTime, endDate, endTime) => {
    //console.log("Raw Values:");
    //console.log("Start Date:", startDate?.slice(0, 10));
    //console.log("Start Time:", startTime);
    //console.log("Start Time:", startTime?.slice(11, 23));
    //console.log("End Date:", endDate);
    //console.log("End Time:", endTime);

    const startDateTime = new Date(
      `${startDate?.slice(0, 10)}T${startTime?.slice(11, 23)}Z`
    );
    const endDateTime = new Date(
      `${endDate?.slice(0, 10)}T${endTime?.slice(11, 23)}Z`
    );

    //console.log("Formatted Values:");
    //console.log("Start DateTime:", startDateTime);
    //console.log("End DateTime:", endDateTime);

    const timeDifferenceMillis = endDateTime - startDateTime;

    const totalSeconds = Math.floor(timeDifferenceMillis / 1000);
    const totalDays = Math.floor(totalSeconds / (24 * 3600));
    const totalHours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
    const totalMinutes = Math.floor((totalSeconds % 3600) / 60);
    const remainingSeconds = totalSeconds % 60;

    //console.log("Start DateTime:", startDateTime);
    //console.log("End DateTime:", endDateTime);
    //console.log("Time Difference in Milliseconds:", timeDifferenceMillis);
    //console.log("Total Seconds:", totalSeconds);
    //console.log("Total Days:", totalDays);
    //console.log("Total Hours:", totalHours);
    //console.log("Total Minutes:", totalMinutes);
    //console.log("Remaining Seconds:", remainingSeconds);

    return `${totalDays} days, ${totalHours} hours, ${totalMinutes} minutes, ${remainingSeconds} seconds`;
  };
  const [selectedRow, setSelectedRow] = useState(null);

  const handleClick = (index, item) => {
    setSelectedRow(index);

    //console.log("BOOKINGID:", state.dbData.BOOKINGID);
    //console.log("TRIPID:", state.dbData.TRIPNUMBER);
    // //console.log("STARTORENDUPDATE:", type); // start or end
    //console.log("DATE:", dateVariable);
    //console.log("TIME:", timeVariable);
    //console.log("GEOCOORDINATES:", state.geoCod);
    //console.log("LOCATIONDESCRIPTION:", state.address);
    setStartTripHandle(item.TRIPSTARTED);
    setEndTripHandle(item.TRIPENDED);
    setFromAddressToPass(item.STARTFROM);
    setToAddressToPass(item.ENDTO);
    setBookingRefPass(item.BOOKINGREF);
    setBookingTimePass(item?.STARTDATE?.slice(0, 10));

    //console.log("FROMLOCATION:", item.STARTFROM); // assuming FROMLOCATION is a state variable
    //console.log("TOLOCATION:", item.ENDTO); // assuming TOLOCATION is a state variable
    // //console.log("KMREADING:", state.KMREADING);
    //console.log("TRIPSTARTENDBY:", stateDriverName?.user?.userName);

    //console.log();
  };

  // km reading
  function gatFuelData() {
    setIsLoading(true);

    //getVehicleList

    // if (state?.dbData?.VEHICLEPLANNED) {
    //   axios
    //     .post(
    //       `${BASEURL}/getUnitID`,
    //       {
    //         VEHICLEREGNO: state?.dbData?.VEHICLEPLANNED,
    //       },
    //       {
    //         headers: {
    //           "auth-token": authToken,
    // "session-token": sessiontoken,
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       setUnitIdFuel(response.data[0].UNITID);
    //       //console.log(response.data[0].UNITID, "VEHICLEREGNO");
    //       if (unitIdFuel) {
    //         fetchData();
    //       }
    //     })
    //     .catch((error) => console.error(error));
    // }

    if (state?.dbData?.REFNO) {
      axios
        .post(
          `${BASEURL}/getVehiclePlannedforTrip`,
          {
            REFNO: state?.dbData?.REFNO,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          //console.log(
          //   response.data[0].VEHICLEPLANNED,
          //   "getVehiclePlannedforTrip newwww"
          // );
          if (response?.data[0]?.VEHICLEPLANNED) {
            axios
              .post(
                `${BASEURL}/getUnitID`,
                {
                  VEHICLEREGNO: response?.data[0]?.VEHICLEPLANNED,
                },
                {
                  headers: {
                    "auth-token": authToken,
                    "session-token": sessiontoken,
                  },
                }
              )
              .then((response) => {
                setUnitIdFuel(response.data[0].UNITID);
                //console.log(response.data[0].UNITID, "VEHICLEREGNO");
                // if (unitIdFuel) {
                //   fetchData();
                // }
              })
              .catch((error) => console.error(error));
          }
        })
        .catch((error) => console.error(error));
    }
  }
  // function fetchData() {
  //   setIsLoading(true);
  //   //console.log(currentDateTime);
  //   //console.log(unitIdFuel, "unitIdFuel");
  //   if (unitIdFuel.length > 0) {
  //     axios
  //       .post(
  //         "https://mtt-ts-api.azurewebsites.net/tracking/getunitposition",
  //         {
  //           // REQUESTEDDATE: "09/01/2024T000000",
  //           // REQUESTEDUNITID: "5BDFC8",
  //           // REQUESTEDDATE: "09/01/2024T000000",
  //           // REQUESTEDUNITID: "T298DST",
  //           REQUESTEDDATE: currentDateTime,
  //           REQUESTEDUNITID: unitIdFuel,
  //         },
  //         {
  //           headers: {
  //             "auth-token": authToken,
  // "session-token": sessiontoken,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         // setResponseData(response.data);
  //         //console.log(response.data, "geo fuel");
  //         //console.log(response.data.Result.Position.Odometer, "geo fuel");
  //         setKmReadingGeo(response.data.Result.Position.Odometer);
  //         setIsLoading(false);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching data:", error);
  //         setIsLoading(false);
  //       });
  //   }
  // }
  async function fetchKmDataToPass(status) {
    if (startTripHandle === true && endTripHandle === true) {
      alert("Trip has been Completed");
      return;
    }
    if (
      startTripHandle === true &&
      endTripHandle === null &&
      status === "START"
    ) {
      alert("Trip has alredy been started");
      return;
    }
    if (startTripHandle === null && status === "END") {
      alert("Trip has not been started");
      return;
    }

    const formatWithLeadingZeros = (number) => {
      return number.toString().padStart(2, "0");
    };
    const currentDate = new Date();
    const formattedDateTOPASS = currentDate.toLocaleDateString("en-GB"); // Adjust locale as needed
    const formattedTimeTOPASS = currentDate.toLocaleTimeString("en-GB"); // Adjust locale as needed

    const now = new Date();
    const currentHours = formatWithLeadingZeros(now.getHours());
    const currentMinutes = formatWithLeadingZeros(now.getMinutes());
    const formattedTime = `${currentHours}:${currentMinutes}`;

    const currentYear = now.getFullYear();
    const currentMonth = formatWithLeadingZeros(now.getMonth() + 1); // Months are zero-indexed
    const currentDay = formatWithLeadingZeros(now.getDate());
    const formattedDate = `${currentYear}-${currentMonth}-${currentDay}`;
    const currentSeconds = formatWithLeadingZeros(now.getSeconds());
    const formattedDateTime = `${currentDay}/${currentMonth}/${currentYear}T${currentHours}${currentMinutes}${currentSeconds}`;

    //console.log(formattedDateTime);
    //console.log(
    //   "Using user's system:",
    //   formattedDateTOPASS,
    //   formattedTimeTOPASS
    // );
    //console.log(unitIdFuel);
    if (Number(kmReadingGeo) < 1) {
      alert("Fuel KM cannot be less than 0");

      return;
    }
    if (kmReadingGeo) {
      const confirmed = window.confirm(
        `Are you sure you want to ${status} trip?`
      );

      if (confirmed) {
        setIsLoading(true);
        //console.log("BOOKINGREF:", bookingRefPass);
        //console.log("STARTDATE:", bookingTimePass);
        //console.log("BOOKINGID:", state.dbData.BOOKINGID);
        //console.log("TRIPID:", state.dbData.TRIPNUMBER);
        //console.log("STARTORENDUPDATE:", status); // start or end
        //console.log("DATE:", formattedDateTOPASS);
        //console.log("TIME:", formattedTimeTOPASS);

        //console.log("GEOCOORDINATES:", state.geoCod);
        //console.log("LOCATIONDESCRIPTION:", state.address);
        //console.log("FROMLOCATION:", fromAddressToPass); // assuming FROMLOCATION is a state variable
        //console.log("TOLOCATION:", toAddressToPass); // assuming TOLOCATION is a state variable
        //console.log("KMREADING:", kmReadingGeo);
        //console.log("TRIPSTARTENDBY:", stateDriverName?.user?.userName);

        //console.log();
        try {
          const response = await axios.post(
            `${BASEURL}/updateDriverTripStatusSafari`,
            {
              BOOKINGREF: bookingRefPass,
              STARTDATE: bookingTimePass,
              BOOKINGID: state.dbData.BOOKINGID,
              TRIPID: state.dbData.TRIPNUMBER,
              STARTORENDUPDATE: status,
              DATE: formattedDateTOPASS,
              TIME: formattedTimeTOPASS,
              GEOCOORDINATES: state.geoCod,
              LOCATIONDESCRIPTION: state.address,
              FROMLOCATION: fromAddressToPass,
              TOLOCATION: toAddressToPass,
              KMREADING: kmReadingGeo ? kmReadingGeo : 0,
              TRIPSTARTENDBY: stateDriverName?.user?.userName,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          );

          //console.log(response);
          alert(response.data.message);
          window.location.reload();
        } catch (err) {
          alert(err.response.data.message);
        } finally {
          setIsLoading(false);
        }
      } else {
        alert("Submission canceled by the user.");
      }
    } else {
      alert("Fetching latest km reading try again.");
    }
    // if (unitIdFuel.length > 0 && formattedDateTime) {
    //   try {
    //     const response = await axios.post(
    //       "https://mtt-ts-api.azurewebsites.net/tracking/getunitposition",
    //       {
    //         REQUESTEDDATE: formattedDateTime,
    //         REQUESTEDUNITID: unitIdFuel,
    //       },
    //       {
    //         headers: {
    //           "auth-token": authToken,
    // "session-token": sessiontoken,
    //         },
    //       }
    //     );

    //     //console.log(response?.data, "geo fuel");
    //     //console.log(
    //       response?.data.Result?.Position?.Odometer,
    //       "geo to pass fuel"
    //     );
    //     const kmreadigpass = response?.data?.Result?.Position?.Odometer;

    //     setIsLoading(false);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //     setIsLoading(false);
    //   }
    // }
  }

  const renderList = (
    <div>
      <DriverMenuNavbar />
      {/* <header className="header__of__main flex justify-between lg:mx-3 items-end px-3 uppercase">
        {isTripStarted && isTripEnded && (
          <h1 className="header__of__page">TRIP summary</h1>
        )}
        {isTripStarted && !isTripEnded && (
          <h1 className="header__of__page">TRIP DETAIL</h1>
        )}
        {!isTripStarted && !isTripEnded && (
          <h1 className="header__of__page">TRIP DETAIL</h1>
        )}
        <div className="flex gap-2 items-center">
          <div className="my-1">
            {" "}
            <button
              type="submit"
              onClick={() => fetchSafariPlanData()}
              style={{ backgroundColor: "#2f2f5a" }}
              className="text-white px-2 py-1 rounded"
            >
              {" "}
              JOURNEY PLAN
            </button>
          </div>
          {dataFromPage === "DriverAllocation" && (
            <div className="my-1">
              {" "}
              <button
                type="submit"
                onClick={() => navigate("/DriverAllocation")}
                style={{ backgroundColor: "#2f2f5a" }}
                className="text-white px-2 py-1 rounded"
              >
                {" "}
                BACK
              </button>
            </div>
          )}
          {dataFromPage === "DriverAllTripDetails" && (
            <div className="my-1">
              {" "}
              <button
                type="submit"
                onClick={() => navigate("/DriverAllTripDetails")}
                style={{ backgroundColor: "#2f2f5a" }}
                className="text-white px-2 py-1 rounded"
              >
                {" "}
                BACK
              </button>
            </div>
          )}
        </div>
      </header>
      <div className="m-1 md:mx-5 text-lg bg-slate-100 p-2 rounded for__submit__driver uppercase">
        <div>
          <h2 className="md:w-[170px]">BOOKING ID : </h2>

          <h3>{state.dbData.BOOKINGID}</h3>
        </div>

        <div>
          <h2 className="md:w-[170px]">TRIP NUMBER : </h2>

          <h3>{state.dbData.TRIPNUMBER}</h3>
        </div>
        <div>
          <h2 className="md:w-[170px]">REF NUMBER : </h2>

          <h3>{state.dbData.REFNO}</h3>
        </div>
        <div>
          <h2 className="md:w-[170px]">DRIVER NAME : </h2>
          <h3> {state.dbData.DRIVERNAME ? state.dbData.DRIVERNAME : " N/A"}</h3>
        </div>
        <div>
          <h2 className="md:w-[170px]">Guest Name: </h2>
          <h3>
            {" "}
            {state.dbData["CLIENT NAME"]
              ? state.dbData["CLIENT NAME"]
              : "NOT AVAILABLE"}
          </h3>
        </div>
        <div>
          <h2 className="md:w-[170px]">REP NAME : </h2>

          <h3>
            {state.dbData.REPNAME ? state.dbData.REPNAME : "NOT AVAILABLE"}
          </h3>
        </div>
        <div>
          <h2 className="md:w-[170px]">PICK-UP FROM :</h2>{" "}
          <h3> {state.dbData.PICKUPFROM}</h3>
        </div>
        <div>
          <h2 className="md:w-[170px]">DROP-OFF PLACE :</h2>{" "}
          <h3>{state.dbData.DROPOFFPLACE}</h3>
        </div>
        {(TripState === "NOTYETSTARTED" || TripState === "COMPLETED") && (
          <div>
            <h2 className="md:w-[170px]">PLAN PICK-UP DATE : </h2>

            <h3>
              {state.dbData.PLANPICKUPDATE
                ? `${formattedDate(state.dbData.PLANPICKUPDATE).slice(0, 10)} `
                : "N/A"}
            </h3>
          </div>
        )}
        {(TripState === "NOTYETSTARTED" || TripState === "COMPLETED") && (
          <div>
            <h2 className="md:w-[170px]"> PLAN PIC-KUP TIME: </h2>

            <h3>
              {state.dbData.PLANPICKUPTIME
                ? `${state.dbData.PLANPICKUPTIME.slice(11, 19)}`
                : "N/A"}
            </h3>
          </div>
        )}
        {(TripState === "INPROGRESS" || TripState === "COMPLETED") && (
          <div>
            <h2 className="md:w-[170px]">START DATE : </h2>

            <h3>
              {state.dbData.STARTDATETIME
                ? `${formattedDate(state.dbData.STARTDATETIME).slice(0, 10)} `
                : "N/A"}
            </h3>
          </div>
        )}
        {(TripState === "INPROGRESS" || TripState === "COMPLETED") && (
          <div>
            <h2 className="md:w-[170px]"> START TIME: </h2>

            <h3>
              {state.dbData.STARTTIME
                ? `${state.dbData.STARTTIME.slice(11, 19)}`
                : "N/A"}
            </h3>
          </div>
        )}
        <div>
          <h2 className="md:w-[170px]"> PLAN DROP DATE: </h2>

          <h3>
            {state.dbData.PLANDROPDATE
              ? `${formattedDate(state.dbData.PLANDROPDATE).slice(0, 10)} `
              : "N/A"}
          </h3>
        </div>
        <div>
          <h2 className="md:w-[170px]"> PLAN DROP TIME: </h2>

          <h3>
            {state.dbData.PLANDROPTIME
              ? `${state.dbData.PLANDROPTIME.slice(11, 19)}`
              : "N/A"}
          </h3>
        </div>
        {TripState === "COMPLETED" && (
          <div>
            <h2 className="md:w-[170px]">END DATE : </h2>

            <h3>
              {state.dbData.ENDDATETIME
                ? `${formattedDate(state.dbData.ENDDATETIME).slice(0, 10)} `
                : "N/A"}
            </h3>
          </div>
        )}
        {TripState === "COMPLETED" && (
          <div>
            <h2 className="md:w-[170px]"> END TIME: </h2>

            <h3>
              {state.dbData.ENDTIME
                ? `${state.dbData.ENDTIME.slice(11, 19)}`
                : "N/A"}
            </h3>
          </div>
        )}
        {TripState === "COMPLETED" && (
          <div>
            <h2 className="md:w-[170px]"> Time Taken: </h2>
            <h3>
              {calculateTimeDifference(
                state.dbData.STARTDATETIME,
                state.dbData.STARTTIME,
                state.dbData.ENDDATETIME,
                state.dbData.ENDTIME
              )}
            </h3>
          </div>
        )}

        <div>
          <h2 className="md:w-[170px]">VEHICLE : </h2>{" "}
          <h3> {state.dbData.VEHICLEPLANNED}</h3>
        </div>

        {/* {isTripStarted && !isTripEnded && (
          <div>
            <h2 className="md:w-[170px]">JOURNEY TIME :</h2>{" "}
            <h3>{timeDifferenceFormatted ? timeDifferenceFormatted : "N/A"}</h3>
          </div>
        )} */}

      {/* <div className="">
          <h2 className="md:w-[170px]">ADDRESS :</h2>{" "}
          <h3 className="w-[80%]">{state.address}</h3>
        </div> */}
      {/* <div>
          <h2 className="md:w-[170px]">NARRATION : </h2>

          <h3 className="lg:w-[80%]">{state.dbData.NARRATION}</h3>
        </div>
        <div>
          <h2 className="md:w-[170px]">KM READING : </h2>

          <input className=" w-[150px] text-right" type="number"></input>
        </div>
      </div>
      <div className="flex gap-5 mx-5">
        {" "}
        {!isTripStarted && !isTripEnded && (
          <button
            className="w-[140px] py-1 bg-green-300 rounded font-semibold"
            onClick={() => {
              updateStatus("START");
            }}
          >
            START MY TRIP
          </button>
        )}
        {isTripStarted && !isTripEnded && (
          <button
            className="w-[140px] py-1 bg-red-300 rounded font-semibold"
            onClick={() => {
              updateStatus("END");
            }}
          >
            END MY TRIP
          </button>
        )}
      </div> */}
      {/* POPUP FOR SAFARI PLANNING (JOURNEY PLAN)  */}
      <div>
        <>
          <div></div>
          <div>
            <div className="flex px-4  justify-between mb-3 lg:mb-2">
              {" "}
              <header className="text-2xl  font-bold text-[#002d62] font-serif">
                <h1 className=" ">SAFARI PLAN</h1>
              </header>
              <button
                onClick={() => navigate("/DriverAllocation")}
                style={{ backgroundColor: "#2f2f5a" }}
                className="text-white px-2 py-1 rounded"
              >
                BACK
              </button>
            </div>
            <section className="px-4 lg:flex justify-between gap-4">
              <div className="flex gap-2">
                <p>BOOKING REFERENCE : </p>
                <p className="font-semibold text-red-600">
                  {tripreferenecNumber}
                </p>
              </div>
              <div className="flex  gap-2 lg:w-mx-2">
                <p>TRIP NUMBER : </p>
                <p className="font-semibold text-red-600">{tripNumberpass}</p>
              </div>
              <div className="lg:flex items-center gap-2 my-1">
                <h2 className="">KM Reading : </h2>

                <input
                  className=" w-[150px] text-right"
                  type="number"
                  onChange={(e) => {
                    setKmReadingGeo(e.target.value);
                  }}
                  defaultValue={kmReadingGeo ? kmReadingGeo : "0"}
                ></input>
              </div>
              <div className="flex flex-col lg:flex-row gap-2">
                <button
                  className="w-[120px] rounded bg-green-200"
                  onClick={() => {
                    fetchKmDataToPass("START");
                  }}
                >
                  START MY TRIP
                </button>
                <button
                  className="w-[120px] rounded bg-red-300"
                  onClick={() => {
                    fetchKmDataToPass("END");
                  }}
                >
                  END MY TRIP
                </button>
              </div>
            </section>
            <aside className="table__reference__app ">
              {/* <h2 className="my-2 text-[22px] font-semibold">All Trip details</h2> */}
              <table className="custom-table ">
                <thead className="header-cell">
                  <td>JOURNEY FROM </td>
                  <td>JOURNEY TO </td>
                  <td>PLANNED DATE AND TIME </td>
                  <td>START DATE AND TIME</td>
                  <td>END DATE AND TIME</td>
                  <td>STATUS</td>

                  <td>TRIP START KM</td>
                  <td>TRIP END KM</td>
                </thead>
                <tbody>
                  {safariPlanData && safariPlanData.length > 0 ? (
                    safariPlanData.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          className={`table-row ${
                            selectedRow === index
                              ? "selected__row__stops__btn"
                              : "btn__cursor"
                          }`}
                          onClick={() => handleClick(index, item)}
                        >
                          <td>
                            <p> {item.STARTFROM ? item.STARTFROM : "N/A"}</p>
                          </td>
                          <td>
                            <p> {item.ENDTO ? item.ENDTO : "N/A"}</p>
                          </td>
                          <td className="text-right">
                            {item.STARTDATE
                              ? `${formattedDate(item.STARTDATE).slice(
                                  0,
                                  10
                                )} ${item.STARTTIME.slice(11, 19)}`
                              : "N/A"}
                          </td>

                          <td className="text-right">
                            {item.TRIPSTARTDATE
                              ? `${formattedDate(item.TRIPSTARTDATE).slice(
                                  0,
                                  10
                                )} ${item.TRIPSTARTTIME.slice(11, 19)}`
                              : "N/A"}
                          </td>
                          <td className="text-right">
                            {item.TRIPENDDATE
                              ? `${formattedDate(item.TRIPENDDATE).slice(
                                  0,
                                  10
                                )}  ${item.TRIPENDTIME.slice(11, 19)}`
                              : "N/A"}
                          </td>
                          <td>
                            <p>
                              {" "}
                              {item.TRIPSTARTED === true &&
                              item.TRIPENDED === null
                                ? "STARTED"
                                : item.TRIPSTARTED === true &&
                                  item.TRIPENDED === true
                                ? "COMPLETED"
                                : "NOT STARTED"}
                            </p>
                          </td>

                          <td className="text-right">
                            <p className="text-right">
                              {" "}
                              {item.TRIPSTARTKM
                                ? Number(item.TRIPSTARTKM).toLocaleString()
                                : "N/A"}
                            </p>
                          </td>
                          <td>
                            <p className="text-right">
                              {" "}
                              {item.TRIPENDKM
                                ? Number(item.TRIPENDKM).toLocaleString()
                                : "N/A"}
                            </p>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="11">Journey plan not available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </aside>
          </div>
        </>
      </div>
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
};

export default SafariPlan;
