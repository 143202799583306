import changeTheState from "./loginLogout";
import journeyStatus from "./journeyStatusReducer";
import changeLoginState from "./loginstatred";
import selectStatements from "./statements";
import Tripstatusreducer from "./tripStatusReducer"
import { combineReducers } from "redux";

const rootReducers = combineReducers({
  changeTheState,
  changeLoginState,
  selectStatements,
  journeyStatus,
  Tripstatusreducer
});

export default rootReducers;
