import React, { useState, useEffect, useRef } from "react";
import MenuNavbar from "../../Components/Navbar/NavbarTrainer";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Add from "../../Components/Advertising/InfiniteCardSlider";
import { GiNotebook } from "react-icons/gi";
import { GiMineTruck } from "react-icons/gi";
import { FaTruckArrowRight } from "react-icons/fa6";
import { HiPuzzlePiece } from "react-icons/hi2";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { GiExpense } from "react-icons/gi";
import { GiTakeMyMoney } from "react-icons/gi";
import { TbFileReport } from "react-icons/tb";
import { FaEdit } from "react-icons/fa";

import "./SafariDashboard.css";
const SafariDashboard = () => {
  const navigate = useNavigate();

  return (
    <div>
      <MenuNavbar />
      <section className="flex all__page__main__section">
        {/* <aside className="w-[100%]">
          <Add />
        </aside> */}
      </section>
      <header className="dashboard__header">
        <div onClick={() => navigate("/BookingReference")}>
          <aside>
            <GiNotebook />
          </aside>
          <p>BOOKINGS</p>
        </div>
        <div onClick={() => navigate("/InternalTransfer")}>
          <aside>
            {" "}
            <GiMineTruck />
          </aside>
          <p>TRANSFERS</p>
        </div>
        <div onClick={() => navigate("/SafariPlanning")}>
          <aside>
            {" "}
            <FaTruckArrowRight />
          </aside>
          <p>SAFARI SCHEDULING</p>
        </div>
        <div>
          <aside>
            {" "}
            <BsFillFuelPumpFill />
          </aside>
          <p>FUEL ISSUE </p>
        </div>
        <div>
          <aside>
            {" "}
            <HiPuzzlePiece />
          </aside>
          <p>MILEAGE ISSUE</p>
        </div>
        <div>
          <aside>
            {" "}
            <GiExpense />
          </aside>
          <p>MAINTENANCE EXPENSES</p>
        </div>
        <div>
          <aside>
            {" "}
            <GiTakeMyMoney />
          </aside>
          <p>TRACKING</p>
        </div>
        <div>
          <aside>
            {" "}
            <TbFileReport />
          </aside>
          <p>REPORTS</p>
        </div>
      </header>
      <main className="flex flex-wrap justify-between">
        <aside
          className="
          table__safari__app "
        >
          <h1>BOOKINGS PENDING TRANSFERS</h1>
          <table class="custom-table">
            <thead class="header-cell">
              <td>MODULE&nbsp;ID</td>

              <td>EDIT</td>
            </thead>
            <tbody>
              <tr class="table-row">
                <td className="alignleft">1</td>

                <td>
                  <FaEdit />
                </td>
              </tr>
            </tbody>
          </table>
        </aside>
        <aside
          className="
             table__safari__app "
        >
          <h1>PICK UPS DONE AND PENDING ARRIVALS</h1>
          <table class="custom-table">
            <thead class="header-cell">
              <td>MODULE&nbsp;ID</td>

              <td>EDIT</td>
            </thead>
            <tbody>
              <tr class="table-row">
                <td className="alignleft">1</td>

                <td>
                  <FaEdit />
                </td>
              </tr>
            </tbody>
          </table>
        </aside>
        <aside
          className="
             table__safari__app "
        >
          <h1>TRANSFERS CONFIRMED PENDING PICK UPS</h1>
          <table class="custom-table">
            <thead class="header-cell">
              <td>MODULE&nbsp;ID</td>

              <td>EDIT</td>
            </thead>
            <tbody>
              <tr class="table-row">
                <td className="alignleft">1</td>

                <td>
                  <FaEdit />
                </td>
              </tr>
            </tbody>
          </table>
        </aside>
        <aside
          className="
             table__safari__app "
        >
          <h1>AVAILABLE VS BOOKED VEHICLES</h1>
          <table class="custom-table">
            <thead class="header-cell">
              <td>MODULE&nbsp;ID</td>

              <td>EDIT</td>
            </thead>
            <tbody>
              <tr class="table-row">
                <td className="alignleft">1</td>

                <td>
                  <FaEdit />
                </td>
              </tr>
            </tbody>
          </table>
        </aside>
        <aside
          className="
             table__safari__app "
        >
          <h1>PENDING JOURNEY PLANNING</h1>
          <table class="custom-table">
            <thead class="header-cell">
              <td>MODULE&nbsp;ID</td>

              <td>EDIT</td>
            </thead>
            <tbody>
              <tr class="table-row">
                <td className="alignleft">1</td>

                <td>
                  <FaEdit />
                </td>
              </tr>
            </tbody>
          </table>
        </aside>
        <aside
          className="
             table__safari__app "
        >
          <h1>SAFARI TRIPS IN PROGRESS</h1>
          <table class="custom-table">
            <thead class="header-cell">
              <td>MODULE&nbsp;ID</td>

              <td>EDIT</td>
            </thead>
            <tbody>
              <tr class="table-row">
                <td className="alignleft">1</td>

                <td>
                  <FaEdit />
                </td>
              </tr>
            </tbody>
          </table>
        </aside>
      </main>
    </div>
  );
};

export default SafariDashboard;
