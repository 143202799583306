import React, { useState, useEffect, useCallback, useRef } from "react";
import Select from "react-select";
import axios from "axios";
import Loading from "../../Components/Loading/Loading";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import { MdDeleteForever } from "react-icons/md";
import UploadLoader from "../../Components/Loader/UploadLoader";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import Draggable from "react-draggable";
import _ from "lodash";

const DriverAndVehicleAllocation = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [height, setHeight] = useState(500);
  const [popupHeight, setPopupHeight] = useState(500);
  const [popupWidth, setPopupWidth] = useState(950);

  // Debounced resize handler for the new popup
  const handlePopupResize = useCallback(
    _.debounce((event, { size }) => {
      setPopupHeight(size.height);
      setPopupWidth(size.width);
    }, 100), // Adjust debounce delay as needed
    []
  );
  const handleResize = useCallback(
    _.debounce((event, { size }) => {
      setHeight(size.height);
    }, 100), // Adjust the debounce delay as needed (100ms here)
    []
  ); // Adjust debounce delay as needed

  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  console.log(sessiontoken);
  const state = useSelector((state) => state?.changeTheState);
  const userLogedIn = state?.user?.userName;

  const [safariDetails, setSafariDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [transactionNumbers, setTransactionNumbers] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState("");
  const [SlectedSheetName, setSlectedSheetName] = useState("");
  const [selectedTransferSafari, setSelectedTransferSafari] = useState("");
  const [bookingData, setBookingData] = useState([]);
  const [DRIVERData, setDRIVERData] = useState([]);
  const [REPRESENTATIVEData, setREPRESENTATIVEData] = useState([]);
  const [VEHICLEData, setVEHICLEData] = useState([]);
  const [AGENTData, setAGENTData] = useState([]);
  const [editedColumn, setEditedColumn] = useState(null);
  const [AddRowPopUP, setAddRowPopUP] = useState(false);
  const [cashsalepopup3, setcashsalepopup3] = useState(false);
  const currentDate = new Date().toISOString();
  const ymdDate = currentDate.split("T")[0];
  const currentYear = currentDate?.slice(0, 4);
  const currentMonth = currentDate?.slice(5, 7);
  const currentDay = currentDate?.slice(8, 10);
  const datePass = currentDay + "/" + currentMonth + "/" + currentYear;
  const [formattedData, setFormattedData] = useState([]);
  const [newRows, setNewRows] = useState([]);
  const [isUPLoading, setIsUPLoading] = useState(false);
  const [TransationdPass, setTransationdPass] = useState("");
  const [DuplicateAndNewTrueFalse, setDuplicateAndNewTrueFalse] =
    useState(false);

  console.log(formattedData);
  console.log(newRows);
  const [fieldNames, setFieldNames] = useState([
    "DATE",
    "TIME",
    "CLIENT NAME",
    "REF",
    "PAX",
    "PICK UP",
    "DROP OFF ",
    "CARS",
    "NOTES / REMARKS",
    "AGENT",
  ]);
  const handleAgentSelection = (index, selectedOption) => {
    handleNewRowChange(index, "AGENT", selectedOption.label);
  };
  const addNewRow = () => {
    setAddRowPopUP(true);
    const emptyRow = fieldNames.reduce((acc, fieldName) => {
      acc[fieldName] = "";
      return acc;
    }, {});
    setNewRows([...newRows, emptyRow]);
  };

  const handleNewRowChange = (index, fieldName, value) => {
    const updatedRows = newRows.map((row, i) =>
      i === index ? { ...row, [fieldName]: value } : row
    );
    setNewRows(updatedRows);
  };

  const removeNewRow = (index) => {
    setNewRows(newRows.filter((row, i) => i !== index));
  };

  const validateRows = (rows) => {
    const requiredFields = [
      "DATE",
      "REF",
      "TIME",
      "PAX",
      "CARS",
      "PICK UP",
      "DROP OFF ",
      "NOTES / REMARKS",
      "AGENT",
      "CLIENT NAME",
    ];
    let isValid = true;
    let missingFieldsMessage = "";

    if (!rows) {
      // Handle the case where rows is undefined or null
      return {
        isValid: false,
        missingFieldsMessage: "Rows are undefined or null",
      };
    }

    rows.forEach((row, rowIndex) => {
      requiredFields.forEach((field) => {
        if (!row || !row[field]) {
          isValid = false;
          missingFieldsMessage += `Row ${rowIndex + 1} is missing ${field}\n`;
        }
      });
    });

    return { isValid, missingFieldsMessage };
  };
  console.log(formattedData);
  function duplicateAndNew() {
    setDuplicateAndNewTrueFalse(true);
    setIsLoading(true);

    axios
      .get(`${BASEURL}/getNewtransactionId`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        //console.log(response.datayyy);
        setTransationdPass(response.data[0].NewTransID);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }
  async function pushBulkExcelData() {
    if (formattedData.length < 1 && newRows.length < 1) {
      alert("Excel data is empty. Please provide a excel.");
      return;
    }

    const { isValid, missingFieldsMessage } = validateRows(newRows);

    if (!isValid) {
      alert(missingFieldsMessage);
      return;
    }

    const formatDate = (dateStr) => {
      const dateParts = dateStr?.split("/");
      return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    };

    const combinedData = [
      // ...formattedData,
      ...newRows.map((row) => {
        const headers = {
          BOOKINGTRANSID: Number(selectedTransaction),
          BOOKINGTRANSDATE: formatDate(datePass),
          BOOKINGTRANSBY: userLogedIn,
          BOOKINGDATE: row["DATE"] || "",
          BOOKINGREFNO: row["REF"] || "",
          FROMCOUNTRY: "",
          SUPPLIERNAME: "",
          TRANSFERORSAFARI: "",
          BOOKINGDESCRIPTION: row["NOTES / REMARKS"] || "",
          NOOFCARS: Number(row["CARS"]) || 0,
          ADULTCOUNT: Number(row["PAX"]) || 0,
          CHILDCOUNT: 0,
          INFANTCOUNT: 0,
          PICKUPTIME: row["TIME"] || "",
          DROPOFFTIME: "",
          PICKUPPLACE: row["PICK UP"] || "",
          DROPOFFPLACE: row["DROP OFF "] || "",
          FLIGHTNO: row["FLIGHT NUMBER"] || "",
          BOOKINGNOTES: row["NOTES / REMARKS"] || "",
          BOOKINGAGENTNAME: row["AGENT"] || "",
          CLIENTCODE: row["CLIENT NAME"] || "",
          BOOKINGMONTH: 0,
          BOOKINGYEAR: 0,
          CLIENTLANGUAGE: "",
          EXCELSHEETNAME: SlectedSheetName || "",
        };
        const values = {
          BOOKINGTRANSID: Number(selectedTransaction),
          BOOKINGREFNO: row["REF"] || "",
          BOOKINGVEHICLE: "",
          PLANPICKUPDATE: row["DATE"] || "",
          PLANPICKUPTIME: row["TIME"] || "",
          BOOKINGNARRATION: row["NOTES / REMARKS"] || "",
        };

        return {
          tripHeader: headers,
          tripItem: values,
        };
      }),
    ];

    const confirmed = window.confirm(`Are you sure you want to submit data?`);
    console.log(combinedData);
    if (confirmed) {
      // try {
      //   const responseDELETE = await axios.post(
      //     `${BASEURL}/deleteBooking`,
      //     {
      //       BOOKINGTRANSID: Number(selectedTransaction),
      //     },
      //     {
      //       headers: {
      //         "auth-token": authToken,
      // "session-token": sessiontoken,,
      //       },
      //     }
      //   );
      //   alert(responseDELETE.data.message);
      // } catch (err) {
      //   alert(err.response.data.message);
      // }
      setIsUPLoading(true);

      try {
        const response = await axios.post(
          `${BASEURL}/insertBookings`,
          {
            bookingItems: combinedData,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        alert(response.data.message);

        if (response.data.message === "BOOKINGS SAVED") {
          setFormattedData([]);
          setNewRows([]);
          setAddRowPopUP(false);

          setSelectedTransaction(selectedTransaction);
          fetchBookingData();
        }
        setIsUPLoading(false);
      } catch (err) {
        setIsUPLoading(false);
        alert(err.response.data.message);
      }
    }
  }
  const TypeOptions = [
    { value: "", label: "ALL" },
    { value: "Transfer", label: "Transfer" },
    { value: "Safari", label: "Safari" },
    { value: "HD", label: "HD" },
    { value: "FD", label: "FD" },
    { value: "M&A", label: "M&A" },
    { value: "TON", label: "TON" },
  ];
  const [selectedType, setselectedType] = useState(TypeOptions[0]);
  const [search, setSearch] = useState("");

  const editedColumnRef = useRef(null);

  //
  const formatBookingData = (data, sheetName, userLogedIn) => {
    return data.map((row) => {
      const headers = {
        BOOKINGTRANSID: Number(selectedTransaction),
        BOOKINGTRANSDATE: new Date().toISOString().split("T")[0], // Current date
        BOOKINGTRANSBY: userLogedIn,
        BOOKINGDATE: row.PLANPICKUPDATE?.split("T")[0] || "",
        BOOKINGREFNO: row.REFNO || "",
        FROMCOUNTRY: "",
        SUPPLIERNAME: "",
        TRANSFERORSAFARI: row.TRANSFERSAFARI || "",
        BOOKINGDESCRIPTION: row.TRIPNARRATION || "",
        NOOFCARS: 0,
        ADULTCOUNT: Number(row.PAXCOUNT) || 0,
        CHILDCOUNT: 0,
        INFANTCOUNT: 0,
        PICKUPTIME: row.PLANPICKUPTIME || "",
        DROPOFFTIME: "",
        PICKUPPLACE: row.PICKUPPLACE || "",
        DROPOFFPLACE: row.DROPOFFPLACE || "",
        FLIGHTNO: row.FLIGHTNO || "",
        BOOKINGNOTES: row.TRIPNARRATION || "",
        BOOKINGAGENTNAME: row.AGENTNAME || "",
        CLIENTCODE: "",
        BOOKINGMONTH: 0,
        BOOKINGYEAR: 0,
        CLIENTLANGUAGE: "",
        EXCELSHEETNAME: sheetName || "",
      };
      const values = {
        BOOKINGTRANSID: Number(selectedTransaction),
        BOOKINGREFNO: row.REFNO || "",
        BOOKINGVEHICLE: row.VEHICLE || "",
        PLANPICKUPDATE: row.PLANPICKUPDATE?.split("T")[0] || "",
        PLANPICKUPTIME: row.PLANPICKUPTIME.slice(11, 16) || "",
        BOOKINGNARRATION: row.TRIPNARRATION || "",
      };

      return {
        tripHeader: headers,
        tripItem: values,
      };
    });
  };

  //

  const updateBookingTrip = useCallback(
    (item) => {
      axios
        .post(
          `${BASEURL}/updateBookingTrips`,
          {
            BOOKINGID: item.BOOKINGID,
            BOOKINGTRIPNO: DuplicateAndNewTrueFalse
              ? TransationdPass
              : item.TRIPNO,
            PLANPICKUPDATE: item.PLANPICKUPDATE || "",
            PLANPICKUPTIME: item.PLANPICKUPTIME || "",
            PLANDROPOFFDATE: item.PLANDROPDATE || "",
            PLANDROPOFFTIME: item.PLANDROPTIME || "",
            DRIVERCODE: item.DRIVERCODE || "",
            REPCODE: item.REPCODE || "",
            PAXCOUNT: item.PAXCOUNT ? Number(item.PAXCOUNT) : 0,
            VEHICLE: item.VEHICLE || "",
            AGENTCODE: item.AGENTCODE || "", // Using AGENTCODE here
            NARRATION: item.TRIPNARRATION || "",
            DROPOFFPLACE: item.DROPOFFPLACE || "",
            FLIGHTNO: item.FLIGHTNO || "",
            PICKUPPLACE: item.PICKUPPLACE || "",
          },
          {
            headers: { "auth-token": authToken, "session-token": sessiontoken },
          }
        )
        .then((response) => {
          console.log(DuplicateAndNewTrueFalse ? TransationdPass : item.TRIPNO);
          console.log(DuplicateAndNewTrueFalse, TransationdPass, item.TRIPNO);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    [
      BASEURL,
      authToken,
      sessiontoken,
      DuplicateAndNewTrueFalse,
      TransationdPass,
    ]
  );

  useEffect(() => {
    if (editedColumn) {
      updateBookingTrip(editedColumn);
    }
  }, [editedColumn, updateBookingTrip]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const transNumbersResponse = await axios.get(
          `${BASEURL}/gettransNumbers`,
          {
            headers: { "auth-token": authToken, "session-token": sessiontoken },
          }
        );
        setTransactionNumbers(transNumbersResponse.data);
        //console.log(transNumbersResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      try {
        const transgetBookingHeaders = await axios.get(
          `${BASEURL}/getBookingHeaders`,
          {
            headers: { "auth-token": authToken, "session-token": sessiontoken },
          }
        );
        // console.log(transgetBookingHeaders.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [BASEURL, authToken]);

  const fetchBookingData = async () => {
    //console.log(selectedTransaction);
    if (selectedTransaction) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${BASEURL}/getDataForDriverandvehicleAllocation`,
          {
            BOOKINGTRANSACTION: Number(selectedTransaction),
          },
          {
            headers: { "auth-token": authToken, "session-token": sessiontoken },
          }
        );
        console.log(response.data, "/getDataForDriverandvehicleAllocation");
        const sortedBookingData = [...response.data].sort((a, b) => {
          // Combine date and time into a comparable format (ISO format is ideal)
          const dateA = new Date(
            `${a.PLANPICKUPDATE?.slice(0, 10)}T${a.PLANPICKUPTIME?.slice(
              11,
              16
            )}`
          );
          const dateB = new Date(
            `${b.PLANPICKUPDATE?.slice(0, 10)}T${b.PLANPICKUPTIME?.slice(
              11,
              16
            )}`
          );

          // Sort in ascending order (1 if a > b, -1 if b > a)
          return dateA - dateB; // Ascending order
        });

        setBookingData(sortedBookingData);

        const formatted = formatBookingData(
          response.data,
          SlectedSheetName,
          userLogedIn
        );
        setFormattedData(formatted);
      } catch (error) {
        console.error("Error fetching booking data:", error);
      }

      try {
        const setDRIVERDataResponse = await axios.get(
          `${BASEURL}/getEmployeeLists?DESIGNATION=DRIVER`,
          {
            headers: { "auth-token": authToken, "session-token": sessiontoken },
          }
        );
        setDRIVERData(
          setDRIVERDataResponse.data.map((item) => ({
            label: item["EMPLOYEE NAME"].trim(),
            value: item["EMPLOYEE CODE"], // Using EMPLOYEE CODE here
            vehicle: item["VEHICLE ALLOCATED"],
          }))
        );
        //console.log(setDRIVERDataResponse.data);
      } catch (error) {
        console.error("Error fetching DRIVER data:", error);
      }

      try {
        const VEHICLEDataResponse = await axios.get(`${BASEURL}/getVehicles`, {
          headers: { "auth-token": authToken, "session-token": sessiontoken },
        });
        setVEHICLEData(
          VEHICLEDataResponse.data.map((item) => ({
            label: item["VEHICLE"],
            value: item["VEHICLE"],
          }))
        );
      } catch (error) {
        console.error("Error fetching VEHICLE data:", error);
      }

      try {
        const REPRESENTATIVEDataResponse = await axios.get(
          `${BASEURL}/getEmployeeLists?DESIGNATION=REP`,
          {
            headers: { "auth-token": authToken, "session-token": sessiontoken },
          }
        );
        setREPRESENTATIVEData(
          REPRESENTATIVEDataResponse.data.map((item) => ({
            label: item["EMPLOYEE NAME"].trim(),
            value: item["EMPLOYEE CODE"], // Using EMPLOYEE CODE here
          }))
        );
        //console.log(REPRESENTATIVEDataResponse.data);
      } catch (error) {
        console.error("Error fetching REPRESENTATIVE data:", error);
      }

      try {
        const getAgentsResponse = await axios.get(`${BASEURL}/getAgents`, {
          headers: { "auth-token": authToken, "session-token": sessiontoken },
        });
        setAGENTData(
          getAgentsResponse.data.map((item) => ({
            label: item["AGENTNAME"].trim(),
            value: item["AGENTCODE"],
          }))
        );
        //console.log(getAgentsResponse.data);
      } catch (error) {
        console.error("Error fetching AGENT data:", error);
      }

      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (selectedTransaction) {
      fetchBookingData();
    }
  }, [selectedTransaction, BASEURL, authToken]);

  const handleTransactionChange = (selectedOption) => {
    setSelectedTransaction(selectedOption.value);
  };

  const handleTransferSafariChange = (selectedOption) => {
    setSelectedTransferSafari(selectedOption.value);
  };

  const handleInputChange = (index, field, value) => {
    const updatedBookingData = [...bookingData];
    updatedBookingData[index][field] = value;
    setBookingData(updatedBookingData);

    // Immediate updateBookingTrip call
    updateBookingTrip(updatedBookingData[index]);
  };

  const handleDriverChange = (index, selectedOption) => {
    const updatedBookingData = [...bookingData];
    const selectedDriver = DRIVERData.find(
      (driver) => driver.value === selectedOption.value
    );
    updatedBookingData[index].DRIVERNAME = selectedOption.label;
    updatedBookingData[index].DRIVERCODE = selectedOption.value;
    updatedBookingData[index].VEHICLE = selectedDriver.vehicle;
    setBookingData(updatedBookingData);
    // Immediate updateBookingTrip call
    updateBookingTrip(updatedBookingData[index]);
    //console.log("Driver changed:", updatedBookingData[index]);
  };

  const handleRepresentativeChange = (index, selectedOption) => {
    const updatedBookingData = [...bookingData];
    updatedBookingData[index].REPNAME = selectedOption.label;
    updatedBookingData[index].REPCODE = selectedOption.value;
    setBookingData(updatedBookingData);
    // Immediate updateBookingTrip call
    updateBookingTrip(updatedBookingData[index]);
    //console.log("Representative changed:", updatedBookingData[index]);
  };

  const handleAgentChange = (index, selectedOption) => {
    const updatedBookingData = [...bookingData];
    updatedBookingData[index].AGENTNAME = selectedOption.label;
    updatedBookingData[index].AGENTCODE = selectedOption.value;
    setBookingData(updatedBookingData);
    // Immediate updateBookingTrip call
    updateBookingTrip(updatedBookingData[index]);
    //console.log("Agent changed:", updatedBookingData[index]);
  };

  const handleVehicleChange = (index, selectedOption) => {
    const updatedBookingData = [...bookingData];
    updatedBookingData[index].VEHICLE = selectedOption.value;
    setBookingData(updatedBookingData);
    // Immediate updateBookingTrip call
    updateBookingTrip(updatedBookingData[index]);
    //console.log("Vehicle changed:", updatedBookingData[index]);
  };

  const handleStatusChange = (selectedOption) => {
    setselectedType(selectedOption);
  };
  const filteredData = bookingData?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);

    const textMatch =
      searchData?.length === 0 ||
      searchData.every((term) => {
        return [
          "REFNO",
          "BOOKINGID",
          "TRIPNO",
          "PLANPICKUPDATE",
          "PLANPICKUPTIME",
          "PLANDROPDATE",
          "PLANDROPTIME",
          "DRIVERNAME",
          "REPNAME",
          "AGENTNAME",
          "VEHICLE",
          "TRIPNARRATION",
          "PICKUPPLACE",
          "DROPOFFPLACE",
          "FLIGHTNO",
        ].some((key) => {
          const columnValue = String(item[key])?.toLowerCase();
          return columnValue?.includes(term);
        });
      });

    const statusMatch =
      selectedType.value === "" || selectedType.value === item.TRANSFERSAFARI;

    return textMatch && statusMatch;
  });
  // console.log(transactionNumbers);
  // console.log(filteredData);
  const filtHistoricCash = transactionNumbers.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    // console.log("Filtering item:", item); // Debugging

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["BOOKINGTRANSNO", "EXCELSHEEETNAME"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const match = textMatch;

    return match;
  });
  return (
    <div>
      <div className="lg:flex items-center mx-5 gap-8">
        {selectedTransaction ? (
          <div className="lg:flex gap-5 uppercase">
            {" "}
            <p>
              TRANSACTION ID :{" "}
              {DuplicateAndNewTrueFalse ? TransationdPass : selectedTransaction}{" "}
            </p>
            <p>Sheet Name : {SlectedSheetName} </p>
          </div>
        ) : (
          <p>PLEASE SEARCH TRANSACTION ID OR SHEET NAME</p>
        )}
        <button
          onClick={() => {
            setcashsalepopup3(true);
          }}
          className=" ml-1 border border-black bg-[#002d62] text-white rounded-md px-3  mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
        >
          SEARCH
        </button>
        {filteredData.length > 0 && (
          <div>
            <button onClick={addNewRow} className="button_style__pop my-1">
              + Add Row
            </button>
            {/* <button
              onClick={() => {
                duplicateAndNew();
              }}
              className="button_style__pop my-1 mx-5"
            >
              Duplicate and New
            </button> */}
          </div>
        )}

        {AddRowPopUP && (
          <div className="absolute top-1/2 left-1/2  transform -translate-x-1/2 -translate-y-1/2 z-[600] ">
            <Draggable handle=".popup-header">
              <ResizableBox
                width={popupWidth}
                height={popupHeight}
                minConstraints={[500, 300]} // Minimum size for this popup
                maxConstraints={[1200, 700]} // Maximum size for this popup
                onResize={handlePopupResize} // Debounced resize handler for this popup
              >
                <div className="bg-white border border-black h-full p-1 z-[9999] w-full overflow-auto">
                  <div className="popup-header flex justify-between px-5 cursor-move">
                    <h1 className="text-lg font-bold">Add new Rows</h1>
                    <div className="flex gap-2">
                      <button
                        onClick={addNewRow}
                        className="px-2 bg-blue-900 rounded text-white"
                      >
                        + Add Row
                      </button>
                      {isUPLoading ? (
                        <UploadLoader />
                      ) : (
                        <button
                          onClick={pushBulkExcelData}
                          className="px-2 bg-blue-900 rounded text-white"
                        >
                          Save
                        </button>
                      )}
                      <button
                        className="px-2 bg-blue-900 rounded text-white"
                        onClick={() => setAddRowPopUP(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>

                  <main className="lg:flex lg:flex-wrap justify-between overflow-auto">
                    <aside className="table__reference__app overflow-y-auto h-[400px]">
                      {formattedData && (
                        <div>
                          <table className="custom-table text-sm overflow__y__ relative">
                            <thead className="header-cell sticky top-[0px] left-[10px]">
                              <tr>
                                <td>Sl.No</td>
                                {fieldNames?.map((cell, index) => (
                                  <td
                                    key={index}
                                    style={{
                                      padding: "5px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        width:
                                          cell === "Supplier"
                                            ? "200px"
                                            : cell === "Service"
                                            ? "200px"
                                            : cell === "Pick-up place"
                                            ? "250px"
                                            : cell === "Booking Status"
                                            ? "150px"
                                            : cell === "Service Status"
                                            ? "150px"
                                            : cell === "FLIGHT NUMBER"
                                            ? "100px"
                                            : cell === "Phone"
                                            ? "150px"
                                            : cell === "DROP OFF "
                                            ? "200px"
                                            : cell === "PICK UP"
                                            ? "200px"
                                            : cell === "Drop-off time"
                                            ? "200px"
                                            : cell === "Notes"
                                            ? "300px"
                                            : "auto",
                                      }}
                                    >
                                      {cell}
                                    </p>
                                  </td>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {newRows.map((row, rowIndex) => (
                                <tr key={rowIndex + formattedData.length}>
                                  <td onClick={() => removeNewRow(rowIndex)}>
                                    <div className="flex items-center gap-1 cursor-pointer">
                                      {rowIndex + 1}
                                      <p className="text-lg">
                                        <MdDeleteForever />
                                      </p>
                                    </div>
                                  </td>
                                  {fieldNames.map((fieldName) => (
                                    <td key={fieldName}>
                                      {fieldName === "DATE" ? (
                                        <input
                                          type="date"
                                          value={row[fieldName]}
                                          onChange={(e) =>
                                            handleNewRowChange(
                                              rowIndex,
                                              fieldName,
                                              e.target.value
                                            )
                                          }
                                        />
                                      ) : fieldName === "TIME" ? (
                                        <input
                                          type="time"
                                          value={row[fieldName]}
                                          onChange={(e) =>
                                            handleNewRowChange(
                                              rowIndex,
                                              fieldName,
                                              e.target.value
                                            )
                                          }
                                        />
                                      ) : fieldName === "PAX" ||
                                        fieldName === "CARS" ? (
                                        <input
                                          type="number"
                                          className="w-[50px]"
                                          value={row[fieldName]}
                                          onChange={(e) =>
                                            handleNewRowChange(
                                              rowIndex,
                                              fieldName,
                                              e.target.value
                                            )
                                          }
                                        />
                                      ) : fieldName === "AGENT" ? (
                                        <Select
                                          className="w-[200px]"
                                          options={AGENTData}
                                          onChange={(selectedOption) =>
                                            handleAgentSelection(
                                              rowIndex,
                                              selectedOption
                                            )
                                          }
                                          value={AGENTData.find(
                                            (agent) =>
                                              agent.value === row["AGENT"]
                                          )}
                                        />
                                      ) : (
                                        <input
                                          type="text"
                                          value={row[fieldName]}
                                          onChange={(e) =>
                                            handleNewRowChange(
                                              rowIndex,
                                              fieldName,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </aside>
                  </main>
                </div>
              </ResizableBox>
            </Draggable>
          </div>
        )}
        {cashsalepopup3 && (
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  bg-white border border-black h-[500px] w-[95%] lg:w-[950px] p-1 z-[9999] text-sm overflow-auto">
            <div className="flex flex-row mt-2">
              <p className="text-red-600 ml-2">Search:</p>
              <input
                className="w-64 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></input>
            </div>

            <button
              className="absolute top-2 right-2 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 ml-2 mr-2    focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                setcashsalepopup3(false);
              }}
            >
              Close
            </button>
            <aside className="service_detials overflow-y-auto overflow-x-hidden h-[450px] w-[90%] ">
              <table class="table_1  ">
                <thead class="header-cell sticky top-0  z-0 text-sm">
                  <tr>
                    <td className="text-right ">BOOKING TRANS NO</td>
                    <td className="text-left">EXCEL SHEEET NAME</td>
                  </tr>
                </thead>
                <tbody>
                  {filtHistoricCash.map((row, index) => (
                    <tr
                      className={"border-b cursor-pointer table-row"}
                      key={index}
                      onClick={() => {
                        setFormattedData([]);
                        setNewRows([]);
                        setSelectedTransaction(row?.BOOKINGTRANSNO);
                        setSlectedSheetName(row?.EXCELSHEEETNAME);
                        setcashsalepopup3(false);
                        setDuplicateAndNewTrueFalse(false);
                      }} // Add onClick event
                    >
                      <td className="text-right ">
                        <p className="pl-[100px]">
                          {row?.BOOKINGTRANSNO ? row?.BOOKINGTRANSNO : ""}
                        </p>{" "}
                      </td>

                      <td>
                        {row?.EXCELSHEEETNAME ? row?.EXCELSHEEETNAME : "N/A"}{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        )}
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <section>
          <ResizableBox
            width="100%"
            height={height}
            minConstraints={[100, 100]}
            maxConstraints={[Infinity, Infinity]}
            resizeHandles={["s"]}
            onResize={handleResize}
          >
            <aside className=" overflow-y-auto " style={{ height: "100%" }}>
              <table className="custom-table text-sm overflow__y__ relative">
                <thead
                  className="header-cell sticky  top-[0px] left-[10px]"
                  style={{ zIndex: 500 }}
                >
                  <tr>
                    <td> DATE</td>
                    <td> TIME</td>
                    <td>REF </td>
                    <td>CLIENT NAME </td>
                    <td>PAX COUNT</td>
                    <td>PICK UP </td>
                    <td>DROP OFF </td>
                    <td>FLIGHT NO </td>

                    <td>DRIVER</td>
                    <td>REPRESENTATIVE</td>
                    <td>NOTES/REMARK</td>
                    <td>AGENT</td>

                    <td>VEHICLE</td>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map((item, index) => (
                      <tr key={index} className="table-row">
                        {/* <td className="text-right ">{item.BOOKINGID || "N/A"}</td> */}
                        <td>
                          <input
                            type="date"
                            defaultValue={item.PLANPICKUPDATE?.slice(0, 10)}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "PLANPICKUPDATE",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="time"
                            defaultValue={item.PLANPICKUPTIME?.slice(11, 16)}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "PLANPICKUPTIME",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td className="text-right ">{item.REFNO || "N/A"}</td>
                        <td className="text-left ">{item.CLIENT || "N/A"}</td>
                        <td>
                          <input
                            className="w-[50px]"
                            type="number"
                            defaultValue={item?.PAXCOUNT ? item?.PAXCOUNT : 0}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "PAXCOUNT",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <textarea
                            className="w-[200px]"
                            type="text"
                            defaultValue={item.PICKUPPLACE}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "PICKUPPLACE",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <textarea
                            className="w-[200px]"
                            type="text"
                            defaultValue={item.DROPOFFPLACE}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "DROPOFFPLACE",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <textarea
                            className="w-[200px]"
                            type="text"
                            defaultValue={item.FLIGHTNO}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "FLIGHTNO",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <div
                            style={{
                              width: "200px",
                              margin: "5px",
                              fontSize: "12px",
                            }}
                          >
                            <Select
                              options={DRIVERData}
                              value={{
                                label: item.DRIVERNAME || "SELECT",
                                value: item.DRIVERCODE || "SELECT",
                              }}
                              onChange={(option) =>
                                handleDriverChange(index, option)
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              width: "200px",
                              margin: "5px",
                              fontSize: "12px",
                            }}
                          >
                            <Select
                              options={REPRESENTATIVEData}
                              value={{
                                label: item.REPNAME || "SELECT",
                                value: item.REPCODE || "SELECT",
                              }}
                              onChange={(option) =>
                                handleRepresentativeChange(index, option)
                              }
                            />
                          </div>
                        </td>

                        <td>
                          <textarea
                            className="w-[200px]"
                            defaultValue={item.TRIPNARRATION}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "TRIPNARRATION",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <div className="w-[200px]">
                            <Select
                              options={AGENTData}
                              value={{
                                label: item.AGENTNAME || "SELECT",
                                value: item.AGENTCODE || "SELECT",
                              }}
                              onChange={(option) =>
                                handleAgentChange(index, option)
                              }
                            />
                          </div>
                        </td>

                        <td>
                          <div className="w-[100px]">
                            <Select
                              options={VEHICLEData}
                              value={{
                                label: item.VEHICLE || "SELECT",
                                value: item.VEHICLE || "SELECT",
                              }}
                              onChange={(option) =>
                                handleVehicleChange(index, option)
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="table-row uppercase">
                      <th colSpan="15">
                        {selectedTransaction === "" &&
                        selectedTransferSafari === ""
                          ? "PLEASE SEARCH TRANSACTION ID"
                          : "No data available"}
                      </th>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="mt-[30vh]"></div>
            </aside>
          </ResizableBox>
        </section>
      )}
    </div>
  );
};

export default DriverAndVehicleAllocation;
