import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
//Client related import
import ProtectedRoute from "./context/ProtectedRoute";

import Login from "./Pages/Login/Login";

import Footer from "./Components/Footer/Footer";
import InternalTransfer from "./Pages/InternalTransfer/InternalTransfer";
import SafariPlanning from "./Pages/SafariPlanning/SafariPlanning";

import NotFound from "./Components/NotFound/NotFound";
import ForgotPwd from "./Components/ForgotPwd";
import SafariDashboard from "./Pages/SafariDashboard/SafariDashboard";
import BookingReference from "./Pages/BookingReference/BookingReference";
import FuelVoucher from "./Pages/FuelVoucher/FuelVoucher";
import VehicleMovement from "./Pages/VehicleMovement/VehicleMovement";
import DriverAllocation from "./Pages/DriverAllocation/DriverAllocation";
import DriverAllTripDetails from "./Pages/DriverAllTripDetails/DriverAllTripDetails";
import DriverTripSubmission from "./Pages/DriverTripSubmission/DriverTripSubmission";
import DriverMenu from "./Pages/DriverMenu/DriverMenu";
import ManagerMenu from "./Pages/ManagerMenu/ManagerMenu";
import DriverFuelVoucher from "./Pages/DriverFuelVoucher/DriverFuelVoucher";
import HistoricalFuelVoucher from "./Pages/HistoricalFuelVoucher/HistoricalFuelVoucher";
import MyPendingTrips from "./Pages/MyPendingTrips/MyPendingTrips";
import AllTripDetails from "./Pages/AllTripDetails/AllTripDetails";
import DriverAttendence from "./Pages/DriverAttendence/DriverAttendence";
import DriverAttendenceHistory from "./Pages/DriverAttendenceHistory/DriverAttendenceHistory";
import EntireTripDetails from "./Pages/EntireTripDetails/EntireTripDetails";
import WeatherApp from "./Pages/WeatherApp/WeatherApp";
import SafariPlan from "./Pages/SafariPlan/SafariPlan";
import VehicleExpenses from "./Pages/VehicleExpenses/VehicleExpenses";
import TaskMaster from "./Pages/TaskMaster/TaskMaster";
import CurrencyExchangeRates from "./Pages/Manager/CurrencyExchangeRates";
import CurrencyMaster from "./Pages/Manager/CurrencyMaster";
import EmployeeMaster from "./Pages/Manager/EmployeeMaster";
import MakeMaster from "./Pages/Manager/MakeMaster";
import MakeandModelMaster from "./Pages/Manager/MakeandModelMaster";
import ModelMaster from "./Pages/Manager/ModelMaster";
import PointsofInterestMaster from "./Pages/Manager/PointsofInterestMaster";
import RouteMaster from "./Pages/Manager/RouteMaster";
import TravelAgents from "./Pages/Manager/TravelAgents";
import TravelSuppliers from "./Pages/Manager/TravelSuppliers";
import VehicleMaster from "./Pages/Manager/VehicleMaster";
import SavedBooking from "./Pages/SavedBooking/SavedBooking";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          {/* Client related */}
          <Route path="/*" element={<NotFound />} />

          <Route path="/" element={<Login />} />
          <Route path="/ForgotPwd" element={<ForgotPwd />} />
          <Route
            path="/SafariDashboard"
            element={
              <ProtectedRoute>
                <SafariDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/InternalTransfer"
            element={
              <ProtectedRoute>
                <InternalTransfer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/SafariPlanning"
            element={
              <ProtectedRoute>
                <SafariPlanning />
              </ProtectedRoute>
            }
          />
          <Route
            path="/BookingReference"
            element={
              <ProtectedRoute>
                <BookingReference />
              </ProtectedRoute>
            }
          />
          <Route
            path="/FuelVoucher"
            element={
              <ProtectedRoute>
                <FuelVoucher />
              </ProtectedRoute>
            }
          />
          <Route
            path="/VehicleMovement"
            element={
              <ProtectedRoute>
                <VehicleMovement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/DriverAllocation"
            element={
              <ProtectedRoute>
                <DriverAllocation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/DriverAllTripDetails"
            element={
              <ProtectedRoute>
                <DriverAllTripDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/DriverTripSubmission"
            element={
              <ProtectedRoute>
                <DriverTripSubmission />
              </ProtectedRoute>
            }
          />
          <Route
            path="/DriverMenu"
            element={
              <ProtectedRoute>
                <DriverMenu />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ManagerMenu"
            element={
              <ProtectedRoute>
                <ManagerMenu />
              </ProtectedRoute>
            }
          />
          <Route
            path="/DriverFuelVoucher"
            element={
              <ProtectedRoute>
                <DriverFuelVoucher />
              </ProtectedRoute>
            }
          />
          <Route
            path="/HistoricalFuelVoucher"
            element={
              <ProtectedRoute>
                <HistoricalFuelVoucher />
              </ProtectedRoute>
            }
          />
          <Route
            path="/MyPendingTrips"
            element={
              <ProtectedRoute>
                <MyPendingTrips />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AllTripDetails"
            element={
              <ProtectedRoute>
                <AllTripDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/DriverAttendence"
            element={
              <ProtectedRoute>
                <DriverAttendence />
              </ProtectedRoute>
            }
          />
          <Route
            path="/DriverAttendenceHistory"
            element={
              <ProtectedRoute>
                <DriverAttendenceHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EntireTripDetails"
            element={
              <ProtectedRoute>
                <EntireTripDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/WeatherApp"
            element={
              <ProtectedRoute>
                <WeatherApp />
              </ProtectedRoute>
            }
          />
          <Route
            path="/SafariPlan"
            element={
              <ProtectedRoute>
                <SafariPlan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/VehicleExpenses"
            element={
              <ProtectedRoute>
                <VehicleExpenses />
              </ProtectedRoute>
            }
          />
          <Route
            path="/TaskMaster"
            element={
              <ProtectedRoute>
                <TaskMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/CurrencyExchangeRates"
            element={
              <ProtectedRoute>
                <CurrencyExchangeRates />
              </ProtectedRoute>
            }
          />
          <Route
            path="/CurrencyMaster"
            element={
              <ProtectedRoute>
                <CurrencyMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EmployeeMaster"
            element={
              <ProtectedRoute>
                <EmployeeMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/MakeMaster"
            element={
              <ProtectedRoute>
                <MakeMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/MakeandModelMaster"
            element={
              <ProtectedRoute>
                <MakeandModelMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ModelMaster"
            element={
              <ProtectedRoute>
                <ModelMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PointsofInterestMaster"
            element={
              <ProtectedRoute>
                <PointsofInterestMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/RouteMaster"
            element={
              <ProtectedRoute>
                <RouteMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/TravelAgents"
            element={
              <ProtectedRoute>
                <TravelAgents />
              </ProtectedRoute>
            }
          />
          <Route
            path="/TravelSuppliers"
            element={
              <ProtectedRoute>
                <TravelSuppliers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/VehicleMaster"
            element={
              <ProtectedRoute>
                <VehicleMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/SavedBooking"
            element={
              <ProtectedRoute>
                <SavedBooking />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
      <div className="mt-[20vh] relative">
        <Footer />
      </div>
    </div>
  );
}

export default App;
