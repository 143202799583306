import React, { useState, useEffect } from "react";
import DriverNavbar from "../../Components/Navbar/DriverMenuNavbar";
import ManagerMenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";
import { FaEdit } from "react-icons/fa";
import { BsFillClipboardDataFill } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import Select from "react-select";
import "../InternalTransfer/InternalTransfer.css";
import { useSelector } from "react-redux";
import axios from "axios";
import Loading from "../../Components/Loading/Loading";
import { useNavigate } from "react-router-dom";

const HistoricalFuelVoucher = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const state = useSelector((state) => state?.changeTheState);
  //console.log(state);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [FuelRequestData, setFuelRequestData] = useState([]);
  const [vehicleListFilter, setVehicleListFilter] = useState("");
  const [DriverFuelCountOfQty, setDriverFuelCountOfQty] = useState("");
  const [vehicleNumber, SetVehicleNumber] = useState("");
  function FuelDetails() {
    setIsLoading(true);
    axios
      .post(
        `${BASEURL}/historicfuelvoucher`,
        {
          DRIVERCODE: state?.user?.userCode,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setFuelRequestData(response.data ? response.data : "");
        SetVehicleNumber(response.data[0]?.VEHICLE);
        setVehicleListFilter(
          response.data.map((item) => ({
            label: item.VEHICLE,
            value: item.VEHICLE,
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .post(
        `${BASEURL}/getDriverFuelCountOfQty`,
        {
          DRIVERCODE: state?.user?.userCode,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setDriverFuelCountOfQty(response.data ? response.data : "");
        //console.log(response.data);
        // setVehicleListFilter(
        //   response.data.map((item) => ({
        //     label: item.VEHICLE,
        //     value: item.VEHICLE,
        //   }))
        // );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    FuelDetails();
  }, []);

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "200px",
      marginTop: "0px",
      minHeight: "initial",
      height: "45px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 0,
    2
  );
  //console.log("First day of current month:", firstDayOfMonth);
  const [fromDateFilter, setFromDateFilter] = useState(
    firstDayOfMonth.toISOString().split("T")[0]
  );
  const [toDateFilter, setToDateFilter] = useState(
    currentDate.toISOString().split("T")[0]
  );

  const [selectedVehicleFilter, setSelectedVehicleFilter] = useState(null);
  const [selectedFuelTypeFilter, setSelectedFuelTypeFilter] = useState(null);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(null);

  const handleVehicleFilterChange = (selectedOption) => {
    setSelectedVehicleFilter(selectedOption);
  };

  const handleFuelTypeFilterChange = (selectedOption) => {
    setSelectedFuelTypeFilter(selectedOption);
  };

  const handleStatusFilterChange = (selectedOption) => {
    setSelectedStatusFilter(selectedOption);
  };

  const handleFromDateFilterChange = (event) => {
    setFromDateFilter(event.target.value);
  };

  const handleToDateFilterChange = (event) => {
    setToDateFilter(event.target.value);
  };

  const filteredData = FuelRequestData.filter((item) => {
    const isVehicleMatch =
      !selectedVehicleFilter || item.VEHICLE === selectedVehicleFilter.value;

    const isFuelTypeMatch =
      !selectedFuelTypeFilter || item.FUELTYPE === selectedFuelTypeFilter.value;

    const FuelStatusFilter = [
      { label: "CANCELLED", value: "CANCELLED" },
      { label: "APPROVED", value: "APPROVED" },
      { label: "PENDING", value: "PENDING" },
    ];

    const isStatusMatch =
      !selectedStatusFilter ||
      (selectedStatusFilter.value === "APPROVED" && item.APPROVED === true) ||
      (selectedStatusFilter.value === "CANCELLED" && item.APPROVED === false) ||
      (selectedStatusFilter.value === "PENDING" && item.APPROVED === null);

    const isDateRangeMatch =
      (!fromDateFilter ||
        new Date(item.REQUESTDATE) >= new Date(fromDateFilter)) &&
      (!toDateFilter || new Date(item.REQUESTDATE) <= new Date(toDateFilter));

    return (
      isVehicleMatch && isFuelTypeMatch && isStatusMatch && isDateRangeMatch
    );
  });
  const FuelTypesFilter = [
    { label: "SAFARI FUEL", value: "SAFARI FUEL" },
    { label: "TRANSFER", value: "TRANSFER" },
    { label: "FUEL SAFARI", value: "FUEL SAFARI" },
  ];
  const FuelStatusFilter = [
    { label: "CANCELLED", value: "CANCELLED" },
    { label: "APPROVED", value: "APPROVED" },
    { label: "PENDING", value: "PENDING" },
  ];
  const render = (
    <div>
      <DriverNavbar />
      <header className="header__of__main flex mx-1 md:mx-3 justify-between items-end px-3">
        <h1 className="header__of__page">HISTORICAL FUEL VOUCHER</h1>
        <h2>
          <button
            onClick={() => navigate("/DriverFuelVoucher")}
            style={{ backgroundColor: "#2f2f5a" }}
            className="text-white px-2 my-2 py-1 rounded"
          >
            {" "}
            BACK
          </button>
        </h2>
      </header>
      <div className="flex ml-[15px] md:mx-5 mt-3">
        <h1 className="w-[80px]"> Vehicle :</h1>{" "}
        <h1 className="text-red-600 font-bold">&nbsp;{vehicleNumber}</h1>
      </div>
      <aside className="main md:mx-4">
        {/* <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label>Vehicle</label>
          <div
            style={{
              width: "200px",
              margin: "5px",
            }}
          >
            <Select
              style={customStyless}
              options={vehicleListFilter}
              onChange={handleVehicleFilterChange}
            />
          </div>
        </p> */}
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label>Fuel Type</label>
          <div className="w-[180px] md:m-[5px]">
            <Select
              options={FuelTypesFilter}
              style={customStyless}
              onChange={handleFuelTypeFilterChange}
            />
          </div>
        </p>
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label>Status</label>
          <div className="w-[180px] md:m-[5px]">
            <Select
              options={FuelStatusFilter}
              style={customStyless}
              onChange={handleStatusFilterChange}
            />
          </div>
        </p>
        {/*  */}
        <div className="ml-[10px] md:ml-0">
          <label>From</label>
          <input
            type="date"
            value={fromDateFilter}
            onChange={handleFromDateFilterChange}
          />
        </div>
        <div className="ml-[10px] md:ml-0">
          <label>To</label>
          <input
            type="date"
            value={toDateFilter}
            onChange={handleToDateFilterChange}
          />
        </div>
        <div className="flex items-center">
          <button
            className="m-2 px-3 py-1 bg-[#43505f] rounded text-white font-semibold"
            onClick={() => {
              window.location.reload();
            }}
          >
            Clear
          </button>
        </div>
      </aside>
      <aside className="table__reference__app ">
        <table class="custom-table">
          <thead class="header-cell">
            <td>VOUCHER NO</td>
            <td>FUEL TYPE</td>
            {/* <td>VEHICLE</td> */}
            <td>REQUESTED DATE</td>
            <td>REQUEST QTY</td>
            {/* <td>HOURS</td> */}
            <td>STATUS</td>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr class="table-row" key={index}>
                <td className="alignleft px-2">
                  <p className="alignleft px-2">{item.VOUCHERNUMBER}</p>
                </td>
                <td>
                  {" "}
                  <p className=" px-2">{item.FUELTYPE}</p>
                </td>
                {/* <td>{item.VEHICLE}</td> */}
                <td>
                  <p className="alignleft px-2">
                    {" "}
                    {item.REQUESTDATE
                      ? `${formattedDate(item.REQUESTDATE).slice(0, 10)} `
                      : "N/A"}
                  </p>
                </td>
                <td className="alignleft">
                  <p className="alignleft px-2">
                    {" "}
                    {item.REQUESTQTY ? item.REQUESTQTY : "N/A"}
                  </p>
                </td>
                {/* <td>{calculateHours(item.REQUESTDATE)}</td> */}
                <td>
                  <p className=" px-2">
                    {" "}
                    {item.APPROVED == "1"
                      ? "APPROVED"
                      : item.APPROVED == "0"
                      ? "CANCELLED"
                      : "PENDING"}
                  </p>
                </td>
              </tr>
            ))}

            <tr>
              <td colSpan="4">
                <p className="text-right">
                  Total Quantity : &nbsp;
                  <b className="text-red-600 px-2">
                    {DriverFuelCountOfQty[0]
                      ? DriverFuelCountOfQty[0].TOTAL
                      : ""}
                  </b>
                </p>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </aside>
    </div>
  );
  return <div>{isLoading ? <Loading /> : render}</div>;
};

export default HistoricalFuelVoucher;
