import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import Select from "react-select";
import DriverMenuNavbar from "../../Components/Navbar/DriverMenuNavbar";
import { useSelector } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import { useNavigate } from "react-router-dom";

const MyPendingTrips = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [search, setSearch] = useState("");
  const [driverAllTrip, setDriverAllTrips] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector((state) => state.changeTheState);
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [TripInitiated, setTripInitiated] = useState([]);
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 0,
    2
  );
  //console.log("First day of current month:", firstDayOfMonth);
  const [fromDateFilter, setFromDateFilter] = useState(
    firstDayOfMonth.toISOString().split("T")[0]
  );
  const [toDateFilter, setToDateFilter] = useState(
    currentDate.toISOString().split("T")[0]
  );

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };

  const statusOptions = [
    { value: "notStarted", label: "Not Started" },
    { value: "started", label: "Started" },
    // { value: "completed", label: "Completed" },
  ];

  const getStatus = (item) => {
    if (item.STARTEDTRIP === null && item.ENDEDTRIP === null) {
      return "notStarted";
    } else if (item.STARTEDTRIP === true && item.ENDEDTRIP === null) {
      return "started";
    } else {
      return "N/A";
    }
  };

  const driverTrip = () => {
    setIsLoading(true);
    axios
      .post(
        `${BASEURL}/myPendingTrips`,
        {
          DRIVERCODE: state?.user?.userCode,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setDriverAllTrips(response.data);
        //console.log(response.data, "setDriverAllTrips");

        setIsLoading(false);
      });

    // axios
    //   .post(`${BASEURL}/getTripInitiated`, {
    //     DRIVERCODE: state?.user?.userCode,
    //   })
    //   .then((response) => {
    //     setTripInitiated(response.data);
    //     //console.log(response.data, "setDriverAllTrips");

    //     setIsLoading(false);
    //   });
  };

  useEffect(() => {
    driverTrip();
  }, []);

  const handleFromDateFilterChange = (event) => {
    setFromDateFilter(event.target.value);
  };

  const handleToDateFilterChange = (event) => {
    setToDateFilter(event.target.value);
  };
  const filteredData = driverAllTrip.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    const textMatch =
      searchData.length === 0 ||
      searchData.every((term) => {
        return [
          "BOOKINGID",
          "TRIPNUMBER",
          "REFNO",
          "VEHICLEPLANNED",
          "PICKUPFROM",
          "DROPOFFPLACE",
          "STARTDATETIME",
          "ENDDATETIME",
          "STARTADDRESS",
          "ENDADDRESS",
        ].some((key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        });
      });

    const statusMatch =
      !selectedStatus || getStatus(item) === selectedStatus.value;
    const isDateRangeMatch =
      (!fromDateFilter ||
        new Date(item["BOOKING DATE"]) >= new Date(fromDateFilter)) &&
      (!toDateFilter ||
        new Date(item["BOOKING DATE"]) <= new Date(toDateFilter));
    return textMatch && statusMatch && isDateRangeMatch;
  });
  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const renderList = (
    <div>
      <DriverMenuNavbar />
      <header className="header__of__main flex mx-1 md:mx-3 justify-between items-end px-3">
        <h1 className="header__of__page">PENDING TRIPS</h1>
      </header>
      <div className="m-4">
        <div className="m-1 md:m-4 md:flex gap-4">
          <div className="searchhhhhh mx-4">
            <p>Search : </p>
            <input
              className="input_1"
              required
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
            <div className="search__icon">
              {" "}
              <FaSearch />
            </div>
          </div>
          <div className="flex items-center">
            <p className="text-lg w-[70px] md:w-auto">Status :&nbsp; </p>
            <div className="w-[200px] md:w-[200px] z-50 ">
              <Select
                options={statusOptions}
                value={selectedStatus}
                onChange={handleStatusChange}
              />
            </div>
          </div>
          <div className="flex items-center my-3">
            <label className="inline-block w-[70px] md:w-auto">From</label>
            <input
              type="date"
              value={fromDateFilter}
              onChange={handleFromDateFilterChange}
            />
          </div>
          <div className="flex items-center my-3">
            <label className="inline-block w-[70px] md:w-auto">To</label>
            <input
              type="date"
              value={toDateFilter}
              onChange={handleToDateFilterChange}
            />
          </div>
          <div className="flex items-center">
            <button
              className="m-1 px-3 py-1 bg-[#43505f] rounded text-white font-semibold"
              onClick={() => {
                window.location.reload();
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
      <aside className="table__reference__app ">
        {/* <h2 className="my-2 text-[22px] font-semibold">All Trip details</h2> */}
        <table className="custom-table text-[14px]">
          <thead className="header-cell">
            <td>BOOKING ID</td>
            <td>TRIP NUMBER</td>
            <td>REF NO</td>
            <td>BOOKING DATE</td>

            {/* <td>VEHICLE </td> */}
            {/* <td>PICKUP FROM</td> */}
            {/* <td>DROPOFF PLACE</td> */}
            {/* <td>START DATE TIME</td>
            <td>END DATE TIME</td>
            <td>START ADDRESS</td>
            <td>END ADDRESS</td> */}
            <td>START DATE </td>
            <td>STATUS</td>
          </thead>
          <tbody>
            {filteredData && filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                let status;
                if (item.STARTEDTRIP === null && item.ENDEDTRIP === null) {
                  status = "YET TO START";
                } else if (
                  item.STARTEDTRIP === true &&
                  item.ENDEDTRIP === null
                ) {
                  status = "STARTED";
                } else if (
                  item.STARTEDTRIP === true &&
                  item.ENDEDTRIP === true
                ) {
                  status = "COMPLETED";
                } else {
                  status = "N/A";
                }

                return (
                  <tr key={index} className="table-row">
                    <td className="text-right ">
                      <p className="pr-4">
                        {" "}
                        {item.BOOKINGID ? item.BOOKINGID : "N/A"}
                      </p>
                    </td>
                    <td className="text-right">
                      <p className="pr-4">
                        {" "}
                        {item.TRIPNUMBER ? item.TRIPNUMBER : "N/A"}{" "}
                      </p>
                    </td>
                    <td>
                      <p className=" px-2">{item.REFNO ? item.REFNO : "N/A"}</p>
                    </td>
                    <td>
                      <p className="text-right px-2">
                        {" "}
                        {item["BOOKING DATE"]
                          ? `${formattedDate(item["BOOKING DATE"]).slice(
                              0,
                              10
                            )} `
                          : "N/A"}{" "}
                      </p>
                    </td>

                    {/* <td>{item.VEHICLEPLANNED ? item.VEHICLEPLANNED : "N/A"}</td> */}
                    {/* <td>{item.PICKUPFROM ? item.PICKUPFROM : "N/A"}</td> */}
                    {/* <td>{item.DROPOFFPLACE ? item.DROPOFFPLACE : "N/A"}</td>
                    <td>
                      {item.STARTDATETIME
                        ? `${formattedDate(item.STARTDATETIME).slice(
                            0,
                            10
                          )} ${item.STARTDATETIME.slice(11, 22)}`
                        : "N/A"}
                    </td>
                    <td>
                      {item.ENDDATETIME
                        ? `${formattedDate(item.ENDDATETIME).slice(
                            0,
                            10
                          )} ${item.ENDDATETIME.slice(11, 22)}`
                        : "N/A"}
                    </td>
                    <td>{item.STARTADDRESS ? item.STARTADDRESS : "N/A"}</td>
                    <td>{item.ENDADDRESS ? item.ENDADDRESS : "N/A"}</td> */}
                    <td>
                      {item.STARTDATETIME
                        ? `${formattedDate(item.STARTDATETIME).slice(0, 10)} `
                        : "NOT STARTED"}
                    </td>
                    <td>
                      <p className=" px-2">{status}</p>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6">No trips available</td>
              </tr>
            )}
          </tbody>
        </table>
      </aside>
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
};

export default MyPendingTrips;
