import React, { useEffect, useState } from "react";
import ManagerMenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";
import Select from "react-select";
import countries from "i18n-iso-countries";
import countryToCurrency, { Currencies, Countries } from "country-to-currency";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import getCountryByCurrency from "currency-to-country";
import Loading from "../../Components/Loading/Loading";

const CurrencyExchangeRates = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const state = useSelector((state) => state?.changeTheState);
  const LoggedInUserName = state?.user?.emailId?.split("@")[0].toUpperCase();
  const [isloading, setisloading] = useState(true);
  const countryList = countries.getNames("en", { select: "official" });
  const [selCountryName, setSelCountryName] = useState("");
  const [selCountryCode, setSelCountryCode] = useState("");
  const options = Object.keys(countryList).map((code) => ({
    value: code,
    label: countryList[code],
  }));
  const [selCurrencyCode, setSelCurrencyCode] = useState("");
  const [baseCurrency, setBaseCurrency] = useState("");
  const [exchangerate, setExchangeRate] = useState("");
  const [datePush, setDatePush] = useState(
    new Date()?.toISOString()?.slice(0, 10)
  );
  const date = new Date()?.toISOString()?.slice(0, 10);
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");
  const [startdate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const filteredData = tableData?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);
    const sDate = item.EXCHDATE;
    const eDate = item.EXCHDATE;

    if (searchData.length === 0 && !startdate && !endDate) {
      return true;
    }

    const textMatch =
      searchData?.length === 0 ||
      searchData.every((term) => {
        return ["CURRCODE", "EXCHRATE"].some((key) => {
          const columnValue = String(item[key])?.toLowerCase();
          return columnValue?.includes(term);
        });
      });

    const dateMatch =
      (!startdate || sDate >= startdate) && (!endDate || eDate <= endDate);

    // const vehicleMakeMatch =
    //   !vehicleMakeSearch ||
    //   !vehicleMakeSearch.length ||
    //   vehicleMakeSearch.toLowerCase() === item.MAKE.toLowerCase();

    // const vehicleModelMatch =
    //   !vehicleModelSearch ||
    //   !vehicleModelSearch.length ||
    //   vehicleModelSearch.toLowerCase() === item.MODEL.toLowerCase();

    return textMatch && dateMatch;
  });

  useEffect(() => {
    axios
      .get(`${BASEURL}/getCompanyDetails`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((res) => {
        setBaseCurrency(res?.data[0]?.BASECURRENCY);
      })
      .catch((err) => {
        setisloading(false);
        //console.log("Error while fetching Company Details", err);
      });

    axios
      .get(`${BASEURL}/getCurrencyExchangeList`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((res) => {
        setTableData(res?.data);
        setisloading(false);
      })
      .catch((err) => {
        setisloading(false);
        //console.log("Error while fetching Currency Exchange List", err);
      });
  }, []);

  useEffect(() => {
    getCurrencyFromCountry(selCountryCode);
  }, [baseCurrency]);

  const getCurrencyFromCountry = (countryCode) => {
    setSelCurrencyCode(countryToCurrency[countryCode]);
    axios
      .get(
        `https://v6.exchangerate-api.com/v6/255f8b377ef27cf43910db15/latest/${countryToCurrency[countryCode]}`
      )
      .then((res) => {
        setExchangeRate(res?.data?.conversion_rates[baseCurrency]);
      })
      .catch((err) => {
        //console.log("Error while fetching Exchnage Rate", err);
      });
  };

  // **************** SAVE ******************

  const confirmSave = () => {
    return window.confirm("Are you sure you want to save the values?");
  };

  async function saveCurrencyExchangeRate() {
    setisloading(true);
    if (!selCurrencyCode || !exchangerate || !datePush) {
      let missingFields = [];

      if (!selCurrencyCode) missingFields.push("Country");
      if (!exchangerate) missingFields.push("Exchange Rate");
      if (!datePush) missingFields.push("Date");

      alert(
        `Please fill in the following fields:\n${missingFields.join("\n")}`
      );
      return;
    }
    if (!confirmSave()) {
      return; // Don't proceed if user cancels
    }
    const datePassingFormat =
      date.slice(8, 10) + "/" + date.slice(5, 7) + "/" + date.slice(0, 4);

    const exchangeDate =
      datePush.slice(8, 10) +
      "/" +
      datePush.slice(5, 7) +
      "/" +
      datePush.slice(0, 4);
    try {
      const response = await axios.post(
        `${BASEURL}/insertExchangeRate`,
        {
          CURRCODE: selCurrencyCode,
          EXCHRATE: exchangerate,
          EXCHDATE: exchangeDate,
          CREATEDBY: LoggedInUserName,
          CREATEDDATE: datePassingFormat,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      if (response.status === 201) {
        alert(response.data.message);
        setisloading(false);
        window.location.reload();
      } else {
        alert(response.data.message);
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
      alert(error.response.data.message);
      console.error(`Error while saving Currency Exchange Rate`, error);
    }
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="block print:hidden">
        <ManagerMenuNavbar />
        <header className="header__of__main lg:flex justify-between items-end px-3">
          <h1 className="header__of__page">Currency exchange Rates</h1>
          <div className="flex my-1">
            {" "}
            <button
              className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300 lg:ml-4"
              onClick={() => {
                window.location.reload();
              }}
            >
              New{" "}
            </button>
            <button
              className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300 lg:ml-2"
              onClick={async () => {
                await saveCurrencyExchangeRate();
              }}
            >
              Save
            </button>
            <button
              className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300 lg:ml-2"
              onClick={() => {
                window.print();
              }}
            >
              Print
            </button>
          </div>
        </header>
        <div className="lg:flex lg:flex-col mt-2 bg-slate-100 lg:h-16 p-1 ">
          {/* Line 1 */}
          <div className="lg:flex mt-2 lg:ml-2 ">
            <p className="mx-2">Country :</p>
            <Select
              className="w-64"
              value={
                selCountryName
                  ? { value: selCountryName, label: selCountryName }
                  : { value: "", label: "Select Country" }
              }
              onChange={(e) => {
                if (e.value !== "") {
                  setSelCountryCode(e.value);
                  setSelCountryName(e.label);
                  getCurrencyFromCountry(e.value);
                }
              }}
              options={[{ value: "", label: "Select Country" }, ...options]}
            ></Select>
            <p className="mx-2"> Currency code </p>
            <input
              className="w-20 h-7"
              type="text"
              value={selCurrencyCode ? selCurrencyCode : ""}
              readOnly
            ></input>
            <p className="mx-2">Base Currency</p>
            <input
              className="w-20 h-7"
              type="text"
              value={baseCurrency ? baseCurrency : ""}
              onChange={(e) => {
                setBaseCurrency(e.target.value.toUpperCase());
              }}
            ></input>
            <p className="mx-2 "> exchange rate </p>
            <input
              className="w-24 h-7 text-right pr-2"
              type="text"
              value={exchangerate ? exchangerate : ""}
              onChange={(e) => setExchangeRate(e.target.value)}
            ></input>
            <p className="mx-2">Date :</p>
            <input
              className="w-32 h-7 lg:ml-2"
              type="date"
              value={datePush?.split("T")[0]}
              onChange={(e) => {
                setDatePush(e.target.value);
              }}
            ></input>
          </div>
        </div>
        <div className="lg:flex mt-2 lg:ml-2 items-center p-1">
          <p>Search :</p>
          <input
            className="w-80 h-7 lg:ml-2"
            type="text"
            value={search ? search : ""}
            onChange={(e) => setSearch(e.target.value)}
          ></input>
          <p className="mx-3">Start Date :</p>
          <input
            type="date"
            value={startdate?.split("T")[0]}
            onChange={(e) => setStartDate(e.target.value)}
          ></input>
          <p className="mx-3">End Date :</p>
          <input
            type="date"
            value={endDate?.split("T")[0]}
            onChange={(e) => setEndDate(e.target.value)}
          ></input>
        </div>
        <div class="overflow-auto m-2.5 p-2.5 lg:w-[50%] rounded-md shadow-md text-xs uppercase bg-gray-100">
          <table className="custom-table w-[95%] m-1 border-separate border-spacing-0 overflow-hidden rounded-md">
            <thead className="header-cell">
              <tr>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  Currency Code
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5] text-right">
                  exchange rate
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5] text-right">
                  date of Exch rate
                </td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData?.map((item, index) => (
                  <tr
                    key={index}
                    className="table-row h-[32px] my-[5px] py-[10px] border-b-[0.3px] border-[#002d62] cursor-pointer"
                    style={{ textTransform: "uppercase" }}
                    onClick={() => {
                      setSelCurrencyCode(item?.CURRCODE);
                      setExchangeRate(item?.EXCHRATE);
                      setDatePush(item?.EXCHDATE);
                      setSelCountryCode(
                        getCountryByCurrency(item?.CURRCODE)[0]?.countryCode
                      );
                      setSelCountryName(
                        countryList[
                          getCountryByCurrency(item?.CURRCODE)[0]?.countryCode
                        ]
                      );
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    <td className="border-l border-transparent border-r">
                      {item.CURRCODE ? item.CURRCODE : "NA"}
                    </td>
                    <td className="border-l border-r border-transparent text-right">
                      {item.EXCHRATE ? item.EXCHRATE : "NA"}
                    </td>
                    <td className="border-l border-r border-transparent text-right">
                      {item.EXCHDATE
                        ? moment(item.EXCHDATE).format("DD/MM/YYYY")
                        : "NA"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Print */}
      <div className="hidden print:block">
        <h1 className="text-2xl mb-2">Currency exchange Rates</h1>
        <table className="custom-table w-[95%] m-1 border-separate border-spacing-0 overflow-hidden rounded-md">
          <thead className="header-cell text-black">
            <tr className="text-black" style={{ textTransform: "uppercase" }}>
              <td className="bg-[#43505f] text-white border border-[#055fc5]">
                Currency Code
              </td>
              <td className="bg-[#43505f] text-white border border-[#055fc5] text-right">
                exchange rate
              </td>
              <td className="bg-[#43505f] text-white border border-[#055fc5] text-right">
                date of Exch rate
              </td>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 &&
              filteredData?.map((item, index) => (
                <tr
                  key={index}
                  className="table-row h-[32px] my-[5px] py-[10px] border-b-[0.3px] border-[#002d62] cursor-pointer"
                  style={{ textTransform: "uppercase" }}
                >
                  <td className="border-l border-transparent border-r">
                    {item.CURRCODE ? item.CURRCODE : "NA"}
                  </td>
                  <td className="border-l border-r border-transparent text-right">
                    {item.EXCHRATE ? item.EXCHRATE : "NA"}
                  </td>
                  <td className="border-l border-r border-transparent text-right">
                    {item.EXCHDATE
                      ? moment(item.EXCHDATE).format("DD/MM/YYYY")
                      : "NA"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CurrencyExchangeRates;
