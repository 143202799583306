import React, { useEffect, useState } from "react";
import Select from "react-select";
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import DriverMenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";
import { useSelector } from "react-redux";

const TaskMaster = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const state = useSelector((state) => state?.changeTheState);
  const user = state?.user?.userName;
  const [date, setDate] = useState(new Date().toISOString());
  const [isloading, setIsloading] = useState(true);
  const [selOperationType, setSelOperationType] = useState("");
  const [OperationTask, setOperationTask] = useState("");
  const [jobtypeList, setJobTypeList] = useState([]);
  const [selJobType, setSelJobType] = useState("");
  const [tasklist, setTaskList] = useState([]);
  const [histflag, setHistflag] = useState(false);
  const [selJobTypeSearch, setSelJobTypeSearch] = useState("");
  const [selOperationTypeSearch, setSelOperationTypeSearch] = useState("");
  const [search, setSearch] = useState("");

  const filteredData = tasklist?.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    if (
      searchData.length === 0 &&
      !selJobTypeSearch &&
      !selOperationTypeSearch
    ) {
      return true;
    }

    // Filter by search term
    const textMatch = searchData.every((term) => {
      return ["OPERATION"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    // // Filter by Sponsors
    const JobTypeMatch =
      !selJobTypeSearch ||
      !selJobTypeSearch.length ||
      selJobTypeSearch.toLowerCase() === item.JOBTYPE.toLowerCase();

    const OperationTypeMatch =
      !selOperationTypeSearch ||
      !selOperationTypeSearch.length ||
      selOperationTypeSearch.toLowerCase() === item.OPERATIONTYPE.toLowerCase();

    return textMatch && JobTypeMatch && OperationTypeMatch;
  });

  useEffect(() => {
    getjobTypes();
    getTasks();
  }, []);

  function getjobTypes() {
    axios
      .get(`${BASEURL}/getjobTypes`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setJobTypeList(response?.data);
      })
      .catch((err) => {
        //console.log("Error while fetching Job Type List", err);
      });
  }

  function getTasks() {
    axios
      .get(`${BASEURL}/getTasks`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        //console.log(response.data);
        setTaskList(response?.data);
        setIsloading(false);
      })
      .catch((err) => {
        setIsloading(false);
        //console.log("Error while fetching Task List", err);
      });
  }
  // new or update
  async function SaveTaskMaster(value) {
    setIsloading(true);
    try {
      const response = await axios.post(
        `${BASEURL}/insertTask/${value}`,
        {
          OPERATIONTASK: OperationTask,
          JOBTYPE: selJobType,
          OPERATIONTYPE: selOperationType,
          CREATEDBY: user,
          SUCCESS_STATUS: "",
          ERROR_STATUS: "",
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      if (response.status === 201) {
        setIsloading(false);
        alert(response.data.message);
      } else {
        setIsloading(false);
        alert(response.data.message);
      }
    } catch (error) {
      setIsloading(false);
      console.error(`Error while saving task master`, error);
      alert(error.response.data.message);
    }
  }

  if (isloading) {
    return <Loading />;
  }
  const handlePrint = () => {
    window.print();
  };
  return (
    <div>
      <div className="print:hidden block">
        <DriverMenuNavbar />
        <header className="header__of__main lg:flex justify-between items-end px-3">
          <h1 className="header__of__page">Task Master</h1>
          <div className="flex items-center gap-1">
            <button
              className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300 lg:ml-7 m-1"
              onClick={() => {
                window.location.reload();
              }}
            >
              New{" "}
            </button>
            <button
              className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300"
              onClick={async () => {
                if (!OperationTask) {
                  alert("Kindly Supply Operation Task");
                } else if (!selJobType) {
                  alert("Kindly Supply Job Type");
                } else if (!selOperationType) {
                  alert("Kinldy Supply Operation Type");
                } else {
                  if (histflag === true) {
                    await SaveTaskMaster("update");
                    window.location.reload();
                  } else {
                    await SaveTaskMaster("new");
                    window.location.reload();
                  }
                }
              }}
            >
              Save
            </button>
            <button
              className="lg:flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300 ml-2"
              onClick={handlePrint}
            >
              Print
            </button>
          </div>
        </header>
        {/* Headers */}
        <div className="lg:flex lg:flex-col mt-2 bg-slate-100 lg:h-16">
          {/* Line 1 */}
          <div className="lg:flex mt-2 ml-2 items-center">
            <p>Operation Task:</p>
            <input
              className="lg:w-[300px] h-[30px] lg:ml-2"
              type="text"
              value={OperationTask ? OperationTask : ""}
              onChange={(e) => setOperationTask(e.target.value)}
            ></input>
            <p
              className="
          lg:ml-4 mr-4"
            >
              Job Type :
            </p>
            <Select
              className="w-64"
              value={
                selJobType
                  ? { label: selJobType, value: selJobType }
                  : { label: "Select Job Type", value: "" }
              }
              onChange={(e) => {
                setSelJobType(e.value);
              }}
              options={[
                { label: "Select Job Type", value: "" },
                ...jobtypeList.map((item) => ({
                  label: item?.JOBTYPE,
                  value: item?.JOBTYPE,
                })),
              ]}
            ></Select>
            <p className="lg:ml-4 mr-4">Operation Type :</p>
            <Select
              className="w-64"
              value={
                selOperationType
                  ? { label: selOperationType, value: selOperationType }
                  : { label: "Select Operation Type", value: "" }
              }
              onChange={(e) => {
                setSelOperationType(e.value);
              }}
              options={[
                { label: "Select Operation Type", value: "" },
                { label: "SERVICE", value: "SERVICE" },
                { label: "STANDARD", value: "STANDARD" },
              ]}
            ></Select>
          </div>
        </div>

        {/* Filters */}
        <div className="lg:flex mt-2 ml-2 items-center">
          <p className="lg:w-[110px] lg:text-right">Search :</p>
          <input
            className="lg:w-[300px] lg:h-[30px] lg:ml-2"
            type="text"
            value={search ? search : ""}
            onChange={(e) => setSearch(e.target.value)}
          ></input>
          <p className="lg:ml-4 mr-4">Job Type :</p>
          <Select
            className="w-64"
            value={
              selJobTypeSearch
                ? { label: selJobTypeSearch, value: selJobTypeSearch }
                : { label: "Select Job Type", value: "" }
            }
            onChange={(e) => {
              setSelJobTypeSearch(e.value);
            }}
            options={[
              { label: "Select Job Type", value: "" },
              ...jobtypeList.map((item) => ({
                label: item?.JOBTYPE,
                value: item?.JOBTYPE,
              })),
            ]}
          ></Select>
          <p className="lg:ml-4 mr-4">Operation Type :</p>
          <Select
            className="w-64"
            value={
              selOperationTypeSearch
                ? {
                    label: selOperationTypeSearch,
                    value: selOperationTypeSearch,
                  }
                : { label: "Select Operation Type", value: "" }
            }
            onChange={(e) => {
              setSelOperationTypeSearch(e.value);
            }}
            options={[
              { label: "Select Operation Type", value: "" },
              { label: "SERVICE", value: "SERVICE" },
              { label: "STANDARD", value: "STANDARD" },
            ]}
          ></Select>
        </div>

        <div class="overflow-auto m-2.5 p-2.5 lg:w-[50%] rounded-md shadow-md text-xs uppercase bg-gray-100">
          <table className="custom-table w-[95%] m-1 border-separate border-spacing-0 overflow-hidden rounded-md">
            <thead className="header-cell">
              <tr>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  OPERATION
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  OPERATION TYPE
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  JOB TYPE
                </td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData?.map((item, index) => (
                  <tr
                    key={index}
                    className="table-row h-[32px] my-[5px] py-[10px] border-b-[0.3px] border-[#002d62] cursor-pointer"
                    style={{ textTransform: "uppercase" }}
                    onClick={() => {
                      setHistflag(true);
                      setOperationTask(item.OPERATION);
                      setSelOperationType(item.OPERATIONTYPE);
                      setSelJobType(item.JOBTYPE);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    <td className="w-[280px] border-l border-transparent border-r py-1 px-4">
                      {item.OPERATION ? item.OPERATION : "NA"}
                    </td>
                    <td className="w-[280px] border-l border-r border-transparent">
                      {item.OPERATIONTYPE ? item.OPERATIONTYPE : "NA"}
                    </td>
                    <td className="w-[280px] border-l border-r border-transparent">
                      {item.JOBTYPE ? item.JOBTYPE : "NA"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="hidden print:block">
        <header className="header__of__main lg:flex justify-between items-end px-3">
          <h1 className="header__of__page">Task Master</h1>
        </header>
        <div class="overflow-auto m-2.5 p-2.5 lg:w-[50%] rounded-md shadow-md text-xs uppercase bg-gray-100">
          <table className="custom-table w-[95%] m-1 border-separate border-spacing-0 overflow-hidden rounded-md">
            <thead className="custom-table text-black uppercase font-medium">
              <tr>
                <td className="border border-[#055fc5]">OPERATION</td>
                <td className=" border border-[#055fc5]">OPERATION TYPE</td>
                <td className=" border border-[#055fc5]">JOB TYPE</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData?.map((item, index) => (
                  <tr
                    key={index}
                    className="table-row h-[32px] my-[5px] py-[10px] border-b-[0.3px] border-[#002d62] cursor-pointer"
                    style={{ textTransform: "uppercase" }}
                    onClick={() => {
                      setHistflag(true);
                      setOperationTask(item.OPERATION);
                      setSelOperationType(item.OPERATIONTYPE);
                      setSelJobType(item.JOBTYPE);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    <td className="w-[280px] border-l border-transparent border-r py-1 px-4">
                      {item.OPERATION ? item.OPERATION : "NA"}
                    </td>
                    <td className="w-[280px] border-l border-r border-transparent">
                      {item.OPERATIONTYPE ? item.OPERATIONTYPE : "NA"}
                    </td>
                    <td className="w-[280px] border-l border-r border-transparent">
                      {item.JOBTYPE ? item.JOBTYPE : "NA"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TaskMaster;
