import React, { useState, useEffect } from "react";
import DriverMenuNavbar from "../../Components/Navbar/DriverMenuNavbar";
import DriverNavbar from "../../Components/Navbar/DriverNavbar";
import { FaEdit } from "react-icons/fa";
import { BsFillClipboardDataFill } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import Select from "react-select";
import "../InternalTransfer/InternalTransfer.css";
import { useSelector } from "react-redux";
import axios from "axios";
import Loading from "../../Components/Loading/Loading";
import { useNavigate } from "react-router-dom";

const DriverAttendence = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const state = useSelector((state) => state?.changeTheState);
  //console.log(state?.user, "userrr");
  //console.log(state?.user?.userName, "nav");
  //console.log(state);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [fuelLocationSelect, setFuelLocationsSelect] = useState([]);

  const [unitIdFuel, setUnitIdFuel] = useState("");
  const [kmReadingGeo, setKmReadingGeo] = useState("");

  const [kenyaDateTimeShow, setKenyaDateTimeShow] = useState("");

  const [currentDateTime, setcurrentDateTime] = useState("");

  //console.log(state);
  //console.log(kmReadingGeo);

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "200px",
      marginTop: "0px",
      minHeight: "initial",
      height: "45px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  function gatFuelData() {
    setIsLoading(true);

    axios
      .get(`${BASEURL}/newFuelStations`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setFuelLocationsSelect(
          response.data.map((item) => ({
            label: item.FUELLOCATION,
            value: item.FUELLOCATION,
          }))
        );
      })
      .catch((error) => console.error(error));
    //getVehicleList

    if (state?.user?.vehicle) {
      axios
        .post(
          `${BASEURL}/getUnitID`,
          {
            VEHICLEREGNO: state?.user?.vehicle,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setUnitIdFuel(response.data[0].UNITID);
          //console.log(response.data[0].UNITID, "VEHICLEREGNO");
          // if (unitIdFuel) {
          //   fetchData();
          // }
        })
        .catch((error) => console.error(error));
    }
  }

  // function fetchData() {
  //   setIsLoading(true);
  //   //console.log(currentDateTime);
  //   //console.log(unitIdFuel);
  //   if (unitIdFuel.length > 0) {
  //     axios
  //       .post(
  //         "https://mtt-ts-api.azurewebsites.net/tracking/getunitposition",
  //         {
  //           // REQUESTEDDATE: "09/01/2024T000000",
  //           // REQUESTEDUNITID: "5BDFC8",
  //           // REQUESTEDDATE: "09/01/2024T000000",
  //           // REQUESTEDUNITID: "T298DST",
  //           REQUESTEDDATE: currentDateTime,
  //           REQUESTEDUNITID: unitIdFuel,
  //         },
  //         {
  //           headers: {
  //             "auth-token": authToken,
  // "session-token": sessiontoken,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         // setResponseData(response.data);
  //         //console.log(response.data, "geo fuel");
  //         //console.log(response.data.Result.Position.Odometer, "geo fuel");
  //         setKmReadingGeo(response.data.Result.Position.Odometer);
  //         setIsLoading(false);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching data:", error);
  //         setIsLoading(false);
  //       });
  //   }
  // }
  async function fetchKmDataToPass(status, messageInOut) {
    const formatWithLeadingZeros = (number) => {
      return number.toString().padStart(2, "0");
    };
    const currentDate = new Date();
    const formattedDateTOPASS = currentDate.toLocaleDateString("en-GB"); // Adjust locale as needed
    const formattedTimeTOPASS = currentDate.toLocaleTimeString("en-GB"); // Adjust locale as needed

    const now = new Date();
    const currentHours = formatWithLeadingZeros(now.getHours());
    const currentMinutes = formatWithLeadingZeros(now.getMinutes());
    const formattedTime = `${currentHours}:${currentMinutes}`;

    const currentYear = now.getFullYear();
    const currentMonth = formatWithLeadingZeros(now.getMonth() + 1); // Months are zero-indexed
    const currentDay = formatWithLeadingZeros(now.getDate());
    const formattedDate = `${currentYear}-${currentMonth}-${currentDay}`;
    const currentSeconds = formatWithLeadingZeros(now.getSeconds());
    const formattedDateTime = `${currentDay}/${currentMonth}/${currentYear}T${currentHours}${currentMinutes}${currentSeconds}`;

    //console.log(formattedDateTime);
    //console.log(
    //   "Using user's system:",
    //   formattedDateTOPASS,
    //   formattedTimeTOPASS
    // );
    //console.log(unitIdFuel);
    if (Number(kmReadingGeo) < 1) {
      alert("Fuel KM cannot be less than 0");
      return;
    }
    if (kmReadingGeo) {
      const confirmed = window.confirm(
        `Are you sure you want to ${messageInOut}?`
      );

      if (confirmed) {
        setIsLoading(true);
        //console.log(
        //   formattedDateTOPASS,
        //   formattedTimeTOPASS,
        //   state?.user?.userCode,
        //   state?.user?.vehicle,
        //   kmReadingGeo,
        //   status,

        //   "pass km"
        // );
        try {
          const response = await axios.post(
            `${BASEURL}/insertDriverRepAttendance`,
            {
              EMPCODE: state?.user?.userCode,
              DATE: formattedDateTOPASS,
              VEHICLE: state?.user?.vehicle,
              STATUS: status, // Passed status parameter here
              KMREADING: kmReadingGeo ? kmReadingGeo : 0,
              TIME: formattedTimeTOPASS,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          );

          //console.log(response);
          alert(response.data.message);
          window.location.reload();
        } catch (err) {
          alert(err.response.data.message);
        } finally {
          setIsLoading(false);
        }
      } else {
        alert("Submission canceled by the user.");
      }
    } else {
      alert("Fetching latest km reading try again.");
    }
    // if (unitIdFuel.length > 0 && formattedDateTime) {
    //   try {
    //     const response = await axios.post(
    //       "https://mtt-ts-api.azurewebsites.net/tracking/getunitposition",
    //       {
    //         REQUESTEDDATE: formattedDateTime,
    //         REQUESTEDUNITID: unitIdFuel,
    //       },
    //       {
    //         headers: {
    //           "auth-token": authToken,
    // "session-token": sessiontoken,
    //         },
    //       }
    //     );

    //     //console.log(response?.data, "geo fuel");
    //     //console.log(
    //       response?.data.Result?.Position?.Odometer,
    //       "geo to pass fuel"
    //     );
    //     const kmreadigpass = response?.data?.Result?.Position?.Odometer;

    //     setIsLoading(false);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //     setIsLoading(false);
    //   }
    // }
  }

  useEffect(() => {
    gatFuelData();
    const currentDate = new Date().toISOString();
    //console.log("Using user's system datetime:", currentDate);
    setKenyaDateTimeShow(currentDate);

    const formatWithLeadingZeros = (number) => {
      return number.toString().padStart(2, "0");
    };

    // Get the current time and date
    const now = new Date();
    const currentHours = formatWithLeadingZeros(now.getHours());
    const currentMinutes = formatWithLeadingZeros(now.getMinutes());
    const formattedTime = `${currentHours}:${currentMinutes}`;

    const currentYear = now.getFullYear();
    const currentMonth = formatWithLeadingZeros(now.getMonth() + 1); // Months are zero-indexed
    const currentDay = formatWithLeadingZeros(now.getDate());
    const formattedDate = `${currentYear}-${currentMonth}-${currentDay}`;
    const currentSeconds = formatWithLeadingZeros(now.getSeconds());
    const formattedDateTime = `${currentDay}/${currentMonth}/${currentYear}T${currentHours}${currentMinutes}${currentSeconds}`;

    setcurrentDateTime(formattedDateTime);
    // fetchData();
    setIsLoading(false);
  }, [unitIdFuel]);

  const renderList = (
    <div>
      <DriverMenuNavbar />
      <header className="header__of__main flex justify-between items-end px-3">
        <h1 className="header__of__page"> attendance</h1>
        <div className="my-1">
          {" "}
          <input
            type="text"
            disabled
            value={
              kenyaDateTimeShow
                ? kenyaDateTimeShow?.slice(8, 10) +
                  "-" +
                  kenyaDateTimeShow?.slice(5, 7) +
                  "-" +
                  kenyaDateTimeShow?.slice(0, 4)
                : "N/A"
            }
            className="mx-4 w-[100px]"
          />
          <button
            type="submit"
            onClick={() => navigate("/DriverAttendenceHistory")}
            style={{ backgroundColor: "#2f2f5a" }}
            className="text-white px-2 py-1 rounded"
          >
            {" "}
            Past Attendance
          </button>
        </div>
      </header>
      <div className="m-1 md:mx-5 text-lg bg-slate-100 p-2 rounded for__submit__driver uppercase">
        <div>
          <h2 className="md:w-[170px]">Driver Name : </h2>

          <h3>{state?.user?.userName}</h3>
        </div>

        <div>
          <h2 className="md:w-[170px]">Driver Code : </h2>

          <h3>{state?.user?.userCode}</h3>
        </div>
        <div>
          <h2 className="md:w-[170px]">vehicle : </h2>

          <h3>{state?.user?.vehicle}</h3>
        </div>
        <div>
          <h2 className="md:w-[170px]">KM Reading : </h2>

          <h3>
            <input
              className=" w-[150px] text-right"
              type="number"
              onChange={(e) => {
                setKmReadingGeo(e.target.value);
              }}
              defaultValue={kmReadingGeo ? kmReadingGeo : "0"}
            ></input>
          </h3>
        </div>
      </div>
      <aside className="mx-4 my-1 font-semibold">
        <button
          className="w-[140px] px-2 py-1 rounded mx-1 bg-green-200 "
          onClick={() => {
            fetchKmDataToPass("START", "Clock In");
          }}
        >
          CLOCK IN{" "}
        </button>

        <button
          className="w-[140px] px-2 py-1 bg-red-200 rounded m-2"
          onClick={() => {
            fetchKmDataToPass("END", "Clock Out");
          }}
        >
          CLOCK OUT{" "}
        </button>
      </aside>
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
};

export default DriverAttendence;
