import React, { useState, useEffect } from "react";
import axios from "axios";
import ManagerMenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";
import Loading from "../../Components/Loading/Loading";
import { useSelector } from "react-redux";

const MakeMaster = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const state = useSelector((state) => state?.changeTheState);
  const LoggedInUserName = state?.user?.emailId?.split("@")[0].toUpperCase();
  const [isloading, setisloading] = useState(true);
  const [makeName, setmakeName] = useState("");
  const [search, setSearch] = useState("");
  const [makeList, setMakeList] = useState([]);
  const [datePush, setDatePush] = useState(
    new Date()?.toISOString()?.slice(0, 10)
  );

  const filteredData = makeList?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);

    const textMatch =
      searchData?.length === 0 ||
      searchData.every((term) => {
        return ["MAKE"].some((key) => {
          const columnValue = String(item[key])?.toLowerCase();
          return columnValue?.includes(term);
        });
      });

    return textMatch;
  });

  const confirmSave = () => {
    return window.confirm("Are you sure you want to save the values?");
  };

  async function submitCategory() {
    setisloading(true);
    // Check if required fields are filled
    if (!makeName || !datePush || !LoggedInUserName) {
      let missingFields = [];

      if (!makeName) missingFields.push("Make Name");
      if (!datePush) missingFields.push("Date");
      if (!LoggedInUserName) missingFields.push("Processed By");

      alert(
        `Please fill in the following fields:\n${missingFields.join("\n")}`
      );
      return;
    }
    if (!confirmSave()) {
      return; // Don't proceed if user cancels
    }
    const datePassingFormat =
      datePush.slice(8, 10) +
      "/" +
      datePush.slice(5, 7) +
      "/" +
      datePush.slice(0, 4);
    try {
      const response = await axios.post(
        `${BASEURL}/insertVehicleMake`,
        {
          MAKE: makeName,
          DATE: datePassingFormat,
          PROCESSEDBY: LoggedInUserName,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      if (response.status === 201) {
        alert(response.data.message);
        setisloading(false);
        window.location.reload();
      } else {
        setisloading(false);
        alert(response.data.message);
      }
    } catch (error) {
      alert(error.response.data.message);
      setisloading(false);
      console.error(`Error while saving Category`, error);
    }
  }

  useEffect(() => {
    axios
      .get(`${BASEURL}/getVehiclemakelist`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setMakeList(response?.data);
        setisloading(false);
      })
      .catch((err) => {
        setisloading(false);
        //console.log("Error while fetching List Of Vehicle Makes", err);
      });
  }, []);

  const handleRowClick = (rowData) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setmakeName(rowData.MAKE);
  };

  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      {" "}
      <div className="print:hidden block">
        <ManagerMenuNavbar />{" "}
        <div className="">
          <header className="header__of__main lg:flex justify-between items-end px-3">
            <h1 className="header__of__page"> Make Master</h1>
            <div className="lg:my-0 my-1">
              <button
                className=" bg-[#002d62]  text-white rounded-md px-2 h-[30px]  lg:ml-2 mr-2 "
                onClick={() => {
                  window.location.reload();
                }}
              >
                New
              </button>
              <button
                className=" bg-[#002d62]  text-white rounded-md px-2 h-[30px]  lg:ml-2 mr-2 "
                onClick={submitCategory}
              >
                Save
              </button>
              <button
                className=" bg-[#002d62]  text-white rounded-md px-2 h-[30px]  lg:ml-2 mr-2 "
                onClick={() => {
                  window.print();
                }}
              >
                Print
              </button>
            </div>
          </header>

          <section className=" shadow-slate-100	m-2 bg-gray-100 border-black rounded	p-2 ">
            <aside className="lg:flex lg:my-1  lg:mb-3">
              <p className="">Make &nbsp;</p>
              <input
                className="w-[200px] lg:mr-[50px]"
                value={makeName}
                onChange={(e) => {
                  setmakeName(e.target.value);
                }}
              ></input>
              <p>Date </p>
              <input
                type="date"
                className="w-[130px] lg:mx-2"
                value={datePush}
                onChange={(e) => {
                  setDatePush(e.target.value);
                }}
              ></input>{" "}
            </aside>
          </section>
          <article>
            <section className="table__pos__app w-[98%] px-3 ">
              <div className="lg:flex">
                <p className="lg:mx-2 text-lg">Search</p>
                <input
                  className="w-[200px] lg:mr-[50px]"
                  required
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
              </div>
              <table class="custom-table ">
                <thead class="header-cell">
                  <tr>
                    <td> SNo</td>
                    <td> VEHICLE MAKE</td>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(filteredData) && filteredData.length > 0 ? (
                    filteredData.map((value, index) => (
                      <tr
                        key={index}
                        className="table-row cursor-pointer"
                        onClick={() => handleRowClick(value)}
                      >
                        <td className="text-right">{index + 1}</td>{" "}
                        {/* Add 1 to start index from 1 */}
                        <td>{value.MAKE}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </section>
          </article>
        </div>
      </div>
      <div className="hidden print:block">
        <h1 className="text-2xl mb-2"> Make Master</h1>
        <table class="custom-table ">
          <thead class="header-cell" className="text-black">
            <tr>
              <td> SNo</td>
              <td> VEHICLE MAKE</td>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(filteredData) && filteredData.length > 0 ? (
              filteredData.map((value, index) => (
                <tr
                  key={index}
                  className="table-row cursor-pointer"
                  onClick={() => handleRowClick(value)}
                >
                  <td className="text-right">{index + 1}</td>{" "}
                  {/* Add 1 to start index from 1 */}
                  <td>{value.MAKE}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MakeMaster;
