import React, { useState, useEffect, useRef } from "react";
import ManagerMenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";

import Loading from "../../Components/Loading/Loading";

import UploadBookingsfromExcel from "../BookingMain/UploadBookingsfromExcel";
import DriverAndVehicleAllocation from "../BookingMain/DriverAndVehicleAllocation";
import ALLBOOKINGS from "../BookingMain/ALLBOOKINGS";
const BookingReference = () => {
  const [activeTab, setActiveTab] = useState(2);
  const [isLoading, setIsLoading] = useState(false);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const renderList = (
    <div>
      <ManagerMenuNavbar />
      <header className="header__of__main flex justify-between items-end px-3">
        <h1 className="header__of__page">SAFARI Booking </h1>
      </header>
      <article className="button_full_width">
        <div className="mx-2">
          <button
            className={activeTab === 2 ? "active__tab" : "button_style__tab"}
            onClick={() => handleTabClick(2)}
          >
            Upload Bookings from Excel
          </button>
          <button
            className={activeTab === 3 ? "active__tab" : "button_style__tab"}
            onClick={() => handleTabClick(3)}
          >
            Planning and Scheduling{" "}
          </button>
          <button
            className={activeTab === 4 ? "active__tab" : "button_style__tab"}
            onClick={() => handleTabClick(4)}
          >
            Transfers Allocation{" "}
          </button>
        </div>
      </article>

      <section
        style={{ display: activeTab === 2 ? "block" : "none" }}
        className={activeTab === 2 ? "active__section" : ""}
      >
        <UploadBookingsfromExcel />
      </section>
      <section
        style={{ display: activeTab === 3 ? "block" : "none" }}
        className={activeTab === 3 ? "active__section" : ""}
      >
        <DriverAndVehicleAllocation />
      </section>
      <section
        style={{ display: activeTab === 4 ? "block" : "none" }}
        className={activeTab === 4 ? "active__section" : ""}
      >
        <ALLBOOKINGS />
      </section>
      <div className="mt-[30vh]"></div>
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
};
export default BookingReference;
