import React, { useEffect, useState } from "react";
import DriverNavbar from "../../Components/Navbar/DriverNavbar";
import { FaEdit } from "react-icons/fa";
import Loading from "../../Components/Loading/Loading";
import { BsFillClipboardDataFill } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import { useSelector } from "react-redux";
import { journeyStatusUpdate } from "../../Redux/Action/journeyStatusUpdate";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import { HiDocumentCheck } from "react-icons/hi2";
import Select from "react-select";
import "../InternalTransfer/InternalTransfer.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tripStatus } from "../../Redux/Action/TripStatus";
const DriverMenu = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const state = useSelector((state) => state.changeTheState);
  const dispatch = useDispatch();
  const { userCode } = state.user;
  //console.log(state?.user?.designation);
  const navigate = useNavigate();
  const [tripstatus, settripstatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [selectedDate, setSelectedDate] = useState(formattedDate);

  // const [onChangeDate, setonChangeDate] = useState("");

  //console.log(userCode);
  function getstatus() {
    //console.log("hi");
    if (state?.user?.designation === "DRIVER") {
      if (selectedDate && userCode) {
        //console.log(selectedDate, "dtaw");
        setIsLoading(true);

        axios
          .post(
            `${BASEURL}/gettripstatuscount`,
            {
              DRIVERCODE: userCode,
              // BOOKINGDATE: selectedDate,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          )
          .then((response) => {
            settripstatus(response.data);
            //console.log(response.data, "settripstatu");
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);

            console.log(err);
          });
      }
    }
    if (state?.user?.designation === "REP") {
      if (selectedDate && userCode) {
        //console.log(selectedDate, "dtaw");
        setIsLoading(true);

        axios
          .post(
            `${BASEURL}/getreptripstatuscount`,
            {
              REPCODE: userCode,
              // BOOKINGDATE: selectedDate,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          )
          .then((response) => {
            settripstatus(response.data);
            //console.log(response.data, "settripstatu");
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      }
    }
  }

  useEffect(() => {
    getstatus();
  }, []);

  function HandleTripNotStarted(count, value) {
    //console.log(value);
    //console.log(count);
    if (count > 0) {
      dispatch(tripStatus(value));
      navigate("/DriverAllocation");
    }
  }

  const render = (
    <div>
      <DriverNavbar />
      <header className="header__of__main flex justify-between lg:mx-3 items-end px-3">
        <h1 className="header__of__page">MY TRIPS</h1>
        <div className="my-1">
          {" "}
          <button
            type="submit"
            onClick={() => navigate("/MyPendingTrips")}
            style={{ backgroundColor: "#2f2f5a" }}
            className="text-white px-2 py-1 rounded"
          >
            {" "}
            My Pending trips
          </button>
        </div>
      </header>
      <div className="mx-4 flex place-items-center gap-8 ">
        {/* <div className="searchhhhhh mx-4">
          <p>Filter By Date : </p>
          <input
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          ></input>
        </div> */}
        {/* <div>
          <button
            type="submit"
            onClick={getstatus}
            style={{ backgroundColor: "#2f2f5a" }}
            className="text-white p-1 rounded"
          >
            {" "}
            Submit
          </button>
        </div> */}
      </div>

      <div className="m-1 p-2 md:m-[20px] w-[95%] bg-slate-100 md:p-3   rounded">
        <table class="custom-table text-[16px]">
          <thead class="header-cell">
            <tr>
              <td className="w-[400px]">Status</td>
              <td>Count</td>
            </tr>
          </thead>

          <tbody>
            {tripstatus.length > 0 && (
              <>
                <tr
                  onClick={() =>
                    HandleTripNotStarted(
                      tripstatus[0].NOTYETSTARTED,
                      "NOTYETSTARTED"
                    )
                  }
                  className="table-row cursor-pointer hover:bg-blue-200"
                >
                  <td>TRIP NOT STARTED</td>
                  <td>
                    {" "}
                    <p className="text-right px-2">
                      {tripstatus[0].NOTYETSTARTED}
                    </p>
                  </td>
                </tr>
                <tr
                  onClick={() =>
                    HandleTripNotStarted(tripstatus[0].INPROGRESS, "INPROGRESS")
                  }
                  className="table-row cursor-pointer hover:bg-blue-200"
                >
                  <td>TRIP STARTED NOT ENDED</td>
                  <td>
                    {" "}
                    <p className="text-right px-2">
                      {tripstatus[0].INPROGRESS}
                    </p>
                  </td>
                </tr>
                <tr
                  onClick={() =>
                    HandleTripNotStarted(tripstatus[0].COMPLETED, "COMPLETED")
                  }
                  className="table-row cursor-pointer hover:bg-blue-200"
                >
                  <td>TRIP COMPLETED</td>
                  <td>
                    <p className="text-right px-2">{tripstatus[0].COMPLETED}</p>
                  </td>
                </tr>
              </>
            )}
            {tripstatus.length === 0 && (
              <tr>
                <td colSpan="2">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
  return <div>{isLoading ? <Loading /> : render}</div>;
};

export default DriverMenu;
