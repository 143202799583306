import React, { useEffect, useState } from "react";
import Select from "react-select";
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import DriverMenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";
import { useSelector } from "react-redux";
import moment from "moment";

const VehicleExpenses = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const state = useSelector((state) => state?.changeTheState);
  const user = state?.user?.userName;
  const [date, setDate] = useState(new Date().toISOString());
  const [isloading, setIsloading] = useState(true);
  const [vehicleList, setVehicleList] = useState([]);
  const [selVehicle, setSelVehicle] = useState("");
  const [driverList, setDriverList] = useState([]);
  const [selDriverCode, setSelDriverCode] = useState("");
  const [selDriverName, setSelDriverName] = useState("");
  const [newJobNo, setNewJobNo] = useState("");
  const [firstJobNo, setFirstJobNo] = useState("");
  const [latestJobNo, setLatestJobNo] = useState("");
  const [jobtypeList, setJobTypeList] = useState([]);
  const [selJobType, setSelJobType] = useState("");
  const [supplierList, setSupplierList] = useState([]);
  const [selSupplierName, setSelSupplierName] = useState("");
  const [selSupplierCode, setSelSupplierCode] = useState("");
  const [selSupplierCurrency, setSelSupplierCurrency] = useState("");
  const [problemReported, setProblemReported] = useState("");
  const [km, setKM] = useState(null);
  const [amountCharged, setAmountCharged] = useState(null);
  const [labourData, setLabourData] = useState([]);
  const [showHeaders, setShowheader] = useState(false);
  const [selectedRowIndex, setselectedRowIndex] = useState(null);
  const [tasklist, setTaskList] = useState([]);
  const [seltask, setSelTask] = useState("");
  const [totalLabourAmount, setTotalLabourAmount] = useState("");
  const [itemdescription, setItemDescription] = useState("");
  const [quantity, setQuantity] = useState("");
  const [rate, setRate] = useState("");
  const [amount, setAmount] = useState("");
  const [itemData, setItemData] = useState([]);
  const [selectedRowIndex1, setselectedRowIndex1] = useState(null);
  const [showItems, setShowItems] = useState(false);
  const [totalItemsCost, setTotalItemsCost] = useState("");
  const [exchangeRate, setExchangeRate] = useState("");
  const [histHeader, setHistHeader] = useState([]);
  const [histPopup, setHistPopup] = useState(false);
  const [search, setSearch] = useState("");
  const [startdate, setstartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );

  // Filters
  const filtJobId = histHeader?.filter((item) => {
    const searchData = search?.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.DATEOFJOB;
    const eDate = item.DATEOFJOB;

    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }
    const textMatch = searchData?.every((term) => {
      return ["JOBID", "JOBTYPE", "VEHICLE", "FOREMAN", "KMCURRENT"].some(
        (key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        }
      );
    });
    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return textMatch && dateMatch;
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (labourData.length > 0) {
      let totalAmountCharged = 0;
      labourData?.forEach((item) => {
        totalAmountCharged += parseFloat(item?.AMOUNT);
      });
      setTotalLabourAmount(totalAmountCharged);
    }
  }, [labourData]);

  useEffect(() => {
    if (itemData.length > 0) {
      let totalAmount = 0;
      itemData?.forEach((item) => {
        totalAmount += parseFloat(item?.ITEMAMOUNT);
      });
      setTotalItemsCost(totalAmount);
    }
  }, [itemData]);

  useEffect(() => {
    getjobHeader();
    getNewJobId();
    getVehicleList();
    getEmployeeListDriver();
    getjobTypes();
    getSuppliers();
    getTasks();
  }, []);

  // Fetch Headers
  function getjobHeader() {
    axios
      .get(`${BASEURL}/getjobHeader`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setHistHeader(response?.data);
        setFirstJobNo(response?.data[0]?.JOBID);
      })
      .catch((err) => {
        //console.log("Error while fetching Job Headers", err);
      });
  }

  // Get New Job Id
  function getNewJobId() {
    axios
      .get(`${BASEURL}/getNewJobId`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setNewJobNo(response?.data[0]?.JOBID);
        setLatestJobNo(response?.data[0]?.JOBID);
      })
      .catch((err) => {
        //console.log("Error while fetching New Job Id", err);
      });
  }

  // Fetch List Of Vehicles
  function getVehicleList() {
    axios
      .get(`${BASEURL}/getVehicleList`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setVehicleList(response?.data);
      })
      .catch((err) => {
        //console.log("Error while fetching Vehicle List", err);
      });
  }

  // Fetch List Of Drivers
  function getEmployeeListDriver() {
    axios
      .get(`${BASEURL}/getEmployeeListDriver`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setDriverList(response?.data);
      })
      .catch((err) => {
        //console.log("Error while fetching Employee List Drivers", err);
      });
  }

  // Fetch Job Type
  function getjobTypes() {
    axios
      .get(`${BASEURL}/getjobTypes`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setJobTypeList(response?.data);
      })
      .catch((err) => {
        //console.log("Error while fetching Job Type List", err);
      });
  }

  // Fetch Supplier Details
  function getSuppliers() {
    axios
      .get(`${BASEURL}/getSuppliers`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setSupplierList(response?.data);
      })
      .catch((err) => {
        //console.log("Error while fetching Supplier List", err);
      });
  }

  // Fetch List Of Tasks
  function getTasks() {
    axios
      .get(`${BASEURL}/getTasks`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setTaskList(response?.data);
        setIsloading(false);
      })
      .catch((err) => {
        setIsloading(false);
        //console.log("Error while fetching Task List", err);
      });
  }
  // Exchange Rates
  function getExchangeRate(currCode) {
    axios
      .post(
        `${BASEURL}/getExchangeRate`,
        {
          CURRCODE: currCode,
          CURRDATE:
            date?.slice(8, 10) +
            "/" +
            date?.slice(5, 7) +
            "/" +
            date?.slice(0, 4),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setExchangeRate(response?.data[0]?.RATE);
      })
      .catch((err) => {
        //console.log("Error while fetching Exchange Rate", err);
      });
  }

  // Handle Driver
  function handleDriverCode(value) {
    const selectedOption = driverList?.find(
      (obj) => obj?.["EMPLOYEE NAME"] === value
    );
    setSelDriverCode(selectedOption?.["EMPLOYEE CODE"]);
  }

  // Handle Supplier
  function handleSupplierCode(value) {
    const selectedOption = supplierList?.find(
      (obj) => obj?.["VENDOR NAME"] === value
    );
    setSelSupplierCode(selectedOption?.["VENDOR CODE"]);
    setSelSupplierCurrency(selectedOption?.["VENDOR CURRENCY"]);
    getExchangeRate(selectedOption?.["VENDOR CURRENCY"]);
  }

  // Handle Supplier
  function handleSupplierName(value) {
    const selectedOption = supplierList?.find(
      (obj) => obj?.["VENDOR CODE"] === value
    );
    setSelSupplierName(selectedOption?.["VENDOR NAME"]);
  }

  //   *************************** Adding Items in Labour Tasks ****************************
  const AddTableDetails = () => {
    if (!seltask) {
      alert("kindly Supply the Task Name");
    } else if (!amountCharged) {
      alert("Kindly Supply the Amount Charged");
    } else {
      const newValue = {
        TASKNAME: seltask,
        AMOUNT: amountCharged,
      };

      const existingRowIndex = labourData?.findIndex(
        (charge) => charge.TASKNAME === newValue.TASKNAME
      );

      if (existingRowIndex !== -1) {
        const updatedItems = [...labourData];
        updatedItems[existingRowIndex] = newValue;
        setLabourData(updatedItems);
      } else {
        setLabourData([...labourData, newValue]);
      }

      setShowheader(true);
      clearAll();
    }
  };

  const clearAll = () => {
    setSelTask("");
    setAmountCharged("");
  };

  const RemoveTableDetails = () => {
    if (labourData.length > 0) {
      const updatedValues = [...labourData];

      if (
        selectedRowIndex !== null &&
        selectedRowIndex >= 0 &&
        selectedRowIndex < updatedValues.length
      ) {
        updatedValues.splice(selectedRowIndex, 1);
        setselectedRowIndex(null);
      } else {
        updatedValues.pop();
      }
      clearAll();
      setLabourData(updatedValues);
      if (updatedValues.length === 0) {
        setShowheader(false);
      }
    } else {
      alert("Please add Task Details First");
    }
  };

  //   *************************** Adding Items in Labour Tasks ****************************
  const AddItemData = () => {
    if (!itemdescription) {
      alert("kindly Supply the Item Description");
    } else if (!quantity) {
      alert("Kindly Supply the Quantity");
    } else if (!rate) {
      alert("Kindly Supply the Rate");
    } else {
      const newValue = {
        ITEMNAME: itemdescription,
        QTY: quantity,
        ITEMRATE: rate,
        ITEMAMOUNT: amount,
      };

      const existingRowIndex = itemData?.findIndex(
        (charge) => charge.ITEMNAME === newValue.ITEMNAME
      );

      if (existingRowIndex !== -1) {
        const updatedItems = [...itemData];
        updatedItems[existingRowIndex] = newValue;
        setItemData(updatedItems);
      } else {
        setItemData([...itemData, newValue]);
      }

      setShowItems(true);
      clearAllItems();
    }
  };

  const clearAllItems = () => {
    setItemDescription("");
    setQuantity("");
    setRate("");
    setAmount("");
  };

  const RemoveItems = () => {
    if (itemData.length > 0) {
      const updatedValues = [...itemData];

      if (
        selectedRowIndex1 !== null &&
        selectedRowIndex1 >= 0 &&
        selectedRowIndex1 < updatedValues.length
      ) {
        updatedValues.splice(selectedRowIndex1, 1);
        setselectedRowIndex1(null);
      } else {
        updatedValues.pop();
      }
      clearAllItems();
      setItemData(updatedValues);
      if (updatedValues.length === 0) {
        setShowItems(false);
      }
    } else {
      alert("Please add Item Details First");
    }
  };

  // ************************************** SAVE ***********************
  // Save Headers
  async function SaveJobHeader() {
    try {
      const response = await axios.post(
        `${BASEURL}/insertJobHeader`,
        {
          JOBID: newJobNo ? Number(newJobNo) : 0,
          JOBTYPE: selJobType ? selJobType : "",
          VEHICLE: selVehicle ? selVehicle : "",
          JOBDATE:
            date?.slice(8, 10) +
            "/" +
            date?.slice(5, 7) +
            "/" +
            date?.slice(0, 4),
          JOBSERVICE: 0,
          FOREMAN: selDriverName ? selDriverName : "",
          VEHICLEKM: km ? Number(km) : 0,
          VEHICLEKMREADINGPREVIOUS: 0,
          VEHICLEKMREADINGPREVIOUSDATE: "",
          PROBLEMSREPORTED: problemReported ? problemReported : "",
          JOBSTATUS: 1,
          CLOSURENARRATION: "",
          DIVISION: "",
          CREATEDBY: user ? user : "",
          CREATEDDATE:
            date?.slice(8, 10) +
            "/" +
            date?.slice(5, 7) +
            "/" +
            date?.slice(0, 4),
          CREATEDTIME: currentTime,
          JOBCLOSEDATE: "",
          JOBCLOSETIME: "",
          SUPPLIERCODE: selSupplierCode ? selSupplierCode : "",
          SUPPLIERCURRENCY: selSupplierCurrency ? selSupplierCurrency : "",
          EXCHRATE: exchangeRate ? Number(exchangeRate) : 0,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      if (response.status === 201) {
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(`Error while saving Job headers`, error);
      alert(error.response.data.message);
    }
  }

  // Save Labour
  async function SaveLabour() {
    setIsloading(true);
    let successCount = 0;
    let successMessage = null;
    for (let index = 0; index < labourData.length; index++) {
      const value = labourData[index];
      try {
        const response = await axios.post(
          `${BASEURL}/insertJobTaskCost`,
          {
            JOBID: Number(newJobNo),
            LABOURNAME: value.TASKNAME,
            LABOURAMOUNT: Number(value.AMOUNT),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );

        if (response.status === 201) {
          successCount++;
          successMessage = response.data.message;
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        setIsloading(false);
        console.error(`Error while saving Labour Details `, error);
        alert(error.response.data.message);
      }
    }
    setIsloading(false);
    if (successCount == labourData.length && successMessage) {
      alert(successMessage);
    }
  }

  // Save Inventory
  async function SaveInventory() {
    setIsloading(true);
    let successCount = 0;
    let successMessage = null;
    for (let index = 0; index < itemData.length; index++) {
      const value = itemData[index];
      try {
        const response = await axios.post(
          `${BASEURL}/insertJobInventorycost`,
          {
            JOBID: Number(newJobNo),
            ITEMNAME: value.ITEMNAME,
            QTY: Number(value.QTY),
            ITEMRATE: Number(value.ITEMRATE),
            ITEMAMOUNT: Number(value.ITEMAMOUNT),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );

        if (response.status === 201) {
          successCount++;
          successMessage = response.data.message;
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        setIsloading(false);
        console.error(`Error while saving Item Details `, error);
        alert(error.response.data.message);
      }
    }
    setIsloading(false);
    if (successCount == itemData.length && successMessage) {
      alert(successMessage);
    }
  }

  // *********************************** Fetching Back Details ********************
  function handleHistoricJobCard(row) {
    setNewJobNo(row?.JOBID);
    setSelJobType(row?.JOBTYPE);
    setSelVehicle(row?.VEHICLE);
    setSelDriverName(row?.FOREMAN);
    handleDriverCode(row?.FOREMAN);
    setKM(row?.KMCURRENT);
    setDate(row?.DATEOFJOB);
    setSelSupplierCode(row?.SUPPLIERCODE);
    handleSupplierName(row?.SUPPLIERCODE);
    setSelSupplierCurrency(row?.CURRENCY);
    setExchangeRate(row?.EXCHRATE);
    setProblemReported(row?.NARRATION);

    axios
      .get(`${BASEURL}/getjobTasks?JOBNO=${row?.JOBID}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setLabourData(response?.data);
        setShowheader(true);
      })
      .catch((err) => {
        //console.log("Error while fetching Job Tasks", err);
      });

    // Fetch Inventory
    axios
      .get(`${BASEURL}/getjobItemCost?JOBNO=${row?.JOBID}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setItemData(response?.data);
        setShowItems(true);
      })
      .catch((err) => {
        //console.log("Error while fetching Job Inventory", err);
      });

    setHistPopup(false);
  }

  const handleButtonClick = (action) => {
    let newJobId;
    //console.log(action);
    switch (action) {
      case "first":
        newJobId = firstJobNo;
        break;
      case "decrement":
        newJobId = Math.max(firstJobNo, newJobNo - 1);
        if (newJobId < firstJobNo) {
          return;
        }
        break;
      case "increment":
        newJobId = Math.min(Number(newJobNo) + 1, latestJobNo);
        if (newJobId >= latestJobNo) {
          return;
        }
        break;
      case "latest":
        newJobId = latestJobNo - 1;
        break;
      default:
        break;
    }

    setNewJobNo(newJobId);
    getHeadersNavigation(newJobId);
  };

  function clearInputsHist() {
    setNewJobNo("");
    setSelJobType("");
    setSelVehicle("");
    setSelDriverName("");
    setSelDriverCode("");
    setKM("");
    setDate(date);
    setSelSupplierCode("");
    setSelSupplierName("");
    setSelSupplierCurrency("");
    setExchangeRate("");
    setProblemReported("");
  }

  function getHeadersNavigation(value) {
    clearInputsHist();
    axios
      .get(`${BASEURL}/getjobHeader?JOBID=${value}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setNewJobNo(response.data[0]?.JOBID);
        setSelJobType(response.data[0]?.JOBTYPE);
        setSelVehicle(response.data[0]?.VEHICLE);
        setSelDriverName(response.data[0]?.FOREMAN);
        handleDriverCode(response.data[0]?.FOREMAN);
        setKM(response.data[0]?.KMCURRENT);
        setDate(response.data[0]?.DATEOFJOB);
        setSelSupplierCode(response.data[0]?.SUPPLIERCODE);
        handleSupplierName(response.data[0]?.SUPPLIERCODE);
        setSelSupplierCurrency(response.data[0]?.CURRENCY);
        setExchangeRate(response.data[0]?.EXCHRATE);
        setProblemReported(response.data[0]?.NARRATION);
      })
      .catch((error) => {
        console.error("Error fetching Historic Job Headers:", error);
      });

    // Fetch Labour Details
    axios
      .get(`${BASEURL}/getjobTasks?JOBNO=${value}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setLabourData(response?.data);
        setShowheader(true);
      })
      .catch((err) => {
        //console.log("Error while fetching Job Tasks", err);
      });

    // Fetch Inventory
    axios
      .get(`${BASEURL}/getjobItemCost?JOBNO=${value}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setItemData(response?.data);
        setShowItems(true);
      })
      .catch((err) => {
        //console.log("Error while fetching Job Inventory", err);
      });
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <DriverMenuNavbar />
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page"> Vehicle Expenses</h1>
      </header>

      <div className="flex lg:justify-end gap-2 mr-2">
        <button
          className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300"
          onClick={() => {
            window.location.reload();
          }}
        >
          New
        </button>
        <button
          className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300"
          onClick={async () => {
            if (!newJobNo) {
              alert("Kindly Supply Job Id");
            } else if (labourData.length === 0) {
              alert("Kindly Add Labour Task To Save");
            } else if (itemData.length === 0) {
              alert("Kindly Add Items to Save");
            }
            await SaveJobHeader();
            await SaveLabour();
            await SaveInventory();
            window.location.reload();
          }}
        >
          Save
        </button>
        <button className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300">
          Delete
        </button>
        <button className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300">
          Approve
        </button>
        <button className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300">
          Print
        </button>
      </div>

      {/* Headers */}
      <div className="lg:flex lg:flex-col mt-2 bg-slate-100 lg:h-52 p-2 lg:p-0">
        <div className="lg:flex felx-row mt-2 lg:ml-[30px] lg:ml-[10px]">
          <button
            className="  border border-black bg-[#002d62] text-white rounded-md px-2    mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
            onClick={() => {
              handleButtonClick("first");
            }}
          >
            {"<<"}
          </button>
          <button
            className="  border border-black bg-[#002d62] text-white rounded-md px-2  mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
            onClick={() => {
              handleButtonClick("decrement");
            }}
          >
            {"<"}
          </button>
          <button
            className="  border border-black bg-[#002d62] text-white rounded-md px-2   mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
            onClick={() => {
              handleButtonClick("increment");
            }}
          >
            {">"}
          </button>
          <button
            className="  border border-black bg-[#002d62] text-white rounded-md px-2   mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
            onClick={() => {
              handleButtonClick("latest");
            }}
          >
            {">>"}
          </button>
        </div>
        {/* Line 1 */}
        <div className="lg:flex mt-2 lg:ml-2">
          <p>Job No :</p>
          <input
            className="w-20 h-9 lg:ml-20 text-right pr-2"
            type="text"
            value={newJobNo ? newJobNo : ""}
            readOnly
          ></input>
          <button
            className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300 lg:ml-1 mr-1 h-9"
            onClick={() => {
              setHistPopup(true);
            }}
          >
            ...
          </button>
          <p className="lg:ml-4 mr-4">Vehicle :</p>
          <Select
            className="w-52"
            value={
              selVehicle
                ? { label: selVehicle, value: selVehicle }
                : { label: "Select Vehicle", value: "" }
            }
            onChange={(e) => setSelVehicle(e.value)}
            options={[
              { label: "Select Vehicle", value: "" },
              ...vehicleList.map((item) => ({
                label: item?.["VEHICLE REG NO"],
                value: item?.["VEHICLE REG NO"],
              })),
            ]}
          ></Select>
          <p className="lg:ml-4 mr-4">Driver :</p>
          <Select
            className="w-80"
            value={
              selDriverName
                ? { label: selDriverName, value: selDriverName }
                : { label: "Select Driver", value: "" }
            }
            onChange={(e) => {
              setSelDriverName(e.value);
              handleDriverCode(e.value);
            }}
            options={[
              { label: "Select Driver", value: "" },
              ...driverList.map((item) => ({
                label: item?.["EMPLOYEE NAME"],
                value: item?.["EMPLOYEE NAME"],
              })),
            ]}
          ></Select>
          <input
            className="w-24 h-9"
            type="text"
            readOnly
            value={selDriverCode ? selDriverCode : ""}
          ></input>
          <p className="lg:ml-[72px] mr-2">Date :</p>
          <input
            type="date"
            className="w-32 h-9"
            value={date?.split("T")[0]}
            onChange={(e) => setDate(e.target.value)}
          ></input>
        </div>

        {histPopup && (
          <div className="fixed top-80 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black lg:h-[500px] lg:w-[900px] p-1 lg:overflow-y-auto">
            <div className="lg:flex lg:flex-row mt-2 mb-2">
              <p className="text-red-600 lg:ml-2">Search</p>
              <input
                className="lg:w-64 h-8 border border-black lg:ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></input>
              <p className="text-red-600 lg:ml-2">Start Date:</p>
              <input
                type="date"
                className="lg:w-36 h-8 border border-black lg:ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                onChange={(e) => {
                  setstartdate(e.target.value);
                }}
                value={startdate.split("T")[0]}
              ></input>
              <p className="text-red-600 lg:ml-2">End Date:</p>
              <input
                type="date"
                className="lg:w-36 h-8 border border-black lg:ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                onChange={(e) => {
                  setEnddate(e.target.value);
                }}
                value={enddate.split("T")[0]}
              ></input>
            </div>

            <button
              className="absolute top-2 right-2 lg:flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 lg:ml-2 mr-2    focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => setHistPopup(false)}
            >
              Close
            </button>
            <aside className="table__pos__app ">
              <table class="custom-table">
                <thead class="header-cell sticky top-0 lg:w-98 z-0 text-sm">
                  <tr>
                    <td className="text-right ">Job ID</td>
                    <td className="text-left">Job Type</td>
                    <td className="text-left">Job Date</td>
                    <td className="text-left"> Vehicle</td>
                    <td className="text-left">Driver Name</td>
                    <td className="text-right">KM Reading</td>
                  </tr>
                </thead>
                <tbody>
                  {filtJobId?.map((row, index) => (
                    <tr
                      key={row?.JOBID}
                      onClick={() => {
                        handleHistoricJobCard(row);
                      }}
                      className={
                        index < histHeader?.length
                          ? "border-b cursor-pointer text-sm table-row"
                          : ""
                      }
                    >
                      <td className="text-right pr-6">
                        {row.JOBID ? row.JOBID : ""}
                      </td>
                      <td>{row.JOBTYPE ? row.JOBTYPE : ""}</td>
                      <td>
                        {row.DATEOFJOB
                          ? moment(row.DATEOFJOB)?.format("DD/MM/YYYY")
                          : ""}
                      </td>
                      <td>{row.VEHICLE ? row.VEHICLE : ""}</td>
                      <td>{row.FOREMAN ? row.FOREMAN : ""}</td>

                      <td className="text-right">
                        {row.KMCURRENT
                          ? row.KMCURRENT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        )}

        {/* Line 2 */}
        <div className="lg:flex mt-5 lg:ml-2">
          <p>Problem Reported </p>
          <textarea
            className="lg:w-[700px] h-12 lg:ml-2 resize-none w-[95%]"
            value={problemReported ? problemReported : ""}
            onChange={(e) => setProblemReported(e.target.value)}
          ></textarea>
          <p className="lg:ml-4 mr-2">Job Type :</p>
          <Select
            className="w-48 h-7"
            value={
              selJobType
                ? { label: selJobType, value: selJobType }
                : { label: "Select Job Type", value: "" }
            }
            onChange={(e) => {
              setSelJobType(e.value);
            }}
            options={[
              { label: "Select Job Type", value: "" },
              ...jobtypeList.map((item) => ({
                label: item?.JOBTYPE,
                value: item?.JOBTYPE,
              })),
            ]}
          ></Select>
          <p className="lg:ml-4 mr-2">KM :</p>
          <input
            className="w-32 h-9 text-right pr-2"
            type="number"
            value={km ? km : ""}
            onChange={(e) => setKM(e.target.value)}
          ></input>
        </div>

        {/* Line 3 */}
        <div className="lg:flex mt-3 lg:ml-2">
          <p>Supplier :</p>
          <Select
            className="w-96 lg:ml-[68px]"
            value={
              selSupplierName
                ? { label: selSupplierName, value: selSupplierName }
                : { label: "Select Supplier Name", value: "" }
            }
            onChange={(e) => {
              setSelSupplierName(e.value);
              handleSupplierCode(e.value);
            }}
            options={[
              { label: "Select Supplier Name", value: "" },
              ...supplierList.map((item) => ({
                label: item?.["VENDOR NAME"],
                value: item?.["VENDOR NAME"],
              })),
            ]}
          ></Select>
          <input
            className="w-32 h-9"
            type="text"
            value={selSupplierCode ? selSupplierCode : ""}
            readOnly
          ></input>
          <p className="lg:ml-4 mr-2">Currency :</p>
          <input
            className="w-20 h-9"
            type="text"
            value={selSupplierCurrency ? selSupplierCurrency : ""}
            readOnly
          ></input>
          <p className="lg:ml-4 mr-2">Exch Rate :</p>
          <input
            className="w-20 h-9 text-right pr-2"
            value={exchangeRate ? exchangeRate : ""}
          ></input>
        </div>
      </div>

      {/* Labour Tasks */}
      <div className="ml-2 mt-2">
        <h1 className="text-blue-600 text-lg font-bold ">Labour Tasks</h1>
      </div>

      <div className="lg:flex ml-2 mt-2">
        <p>Task Name :</p>
        <Select
          className="w-96 lg:ml-[52px]"
          value={
            seltask
              ? { label: seltask, value: seltask }
              : { label: "Select Task", value: "" }
          }
          onChange={(e) => {
            if (!selVehicle) {
              alert("Kindly Suply Vehicle Details");
            } else if (!selDriverName) {
              alert("Kindly Suply Driver Details");
            } else if (!problemReported) {
              alert("Kindly Suply Problem Reported");
            } else if (!selJobType) {
              alert("Kindly Suply Job Type Details");
            } else if (!km) {
              alert("Kindly Suply KM reading");
            } else if (!selSupplierName) {
              alert("Kindly Suply Supplier Details");
            } else {
              setSelTask(e.value);
            }
          }}
          options={[
            { label: "Select Task", value: "" },
            ...tasklist.map((item) => ({
              label: item?.OPERATION,
              value: item?.OPERATION,
            })),
          ]}
        ></Select>
        <p className="lg:ml-4 mr-2">Amount Charged :</p>
        <input
          className="w-32 h-9 mr-20 text-right pr-2"
          type="number"
          value={amountCharged ? amountCharged : ""}
          onChange={(e) => {
            if (!seltask) {
              alert("Kindly Provide Task Details First");
            } else {
              setAmountCharged(e.target.value);
            }
          }}
        ></input>
        <button
          className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300 lg:ml-7 mr-1"
          onClick={() => {
            AddTableDetails();
          }}
        >
          Add
        </button>
        <button
          className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300"
          onClick={() => {
            RemoveTableDetails();
          }}
        >
          Remove
        </button>
      </div>
      {showHeaders && (
        <div class="overflow-auto m-2.5 p-2.5 lg:w-[50%] rounded-md shadow-md text-xs uppercase bg-gray-100">
          <table className="custom-table w-[95%] m-1 border-separate border-spacing-0 overflow-hidden rounded-md">
            <thead className="header-cell">
              <tr>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  TASK NAME
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  AMOUNT CHARGED
                </td>
              </tr>
            </thead>
            <tbody>
              {labourData?.length > 0 &&
                labourData?.map((item, index) => (
                  <tr
                    key={index}
                    className="cursor-pointer table-row h-[32px] my-[5px] py-[10px] border-b-[0.3px] border-[#002d62]"
                    style={{ textTransform: "uppercase" }}
                    onClick={() => {
                      setSelTask(item?.TASKNAME);
                      setAmountCharged(item?.AMOUNT);
                    }}
                  >
                    <td className="w-[280px] border-l border-transparent border-r py-1 px-4">
                      {item.TASKNAME ? item.TASKNAME : "NA"}
                    </td>
                    <td className="w-[280px] border-l border-r border-transparent text-right pr-10">
                      {item.AMOUNT
                        ? item.AMOUNT?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "NA"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="lg:flex justify-end gap-2 mt-2 pr-6">
            <p>Labour Count :</p>
            <input
              className="w-32 bg-yellow-200 text-right pr-2"
              value={
                totalLabourAmount
                  ? totalLabourAmount?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : ""
              }
              readOnly
            ></input>
          </div>
        </div>
      )}

      {/* Inventory */}
      <div className="ml-2 mt-2">
        <h1 className="text-blue-600 text-lg font-bold">Inventory</h1>
      </div>

      <div className="lg:flex ml-2 mt-2">
        <p>Item Description :</p>
        <input
          className="lg:w-[450px] h-7 lg:ml-3 w-[90%]"
          type="text"
          value={itemdescription ? itemdescription : ""}
          onChange={(e) => {
            if (!selVehicle) {
              alert("Kindly Suply Vehicle Details");
            } else if (!selDriverName) {
              alert("Kindly Suply Driver Details");
            } else if (!problemReported) {
              alert("Kindly Suply Problem Reported");
            } else if (!selJobType) {
              alert("Kindly Suply Job Type Details");
            } else if (!km) {
              alert("Kindly Suply KM reading");
            } else if (!selSupplierName) {
              alert("Kindly Suply Supplier Details");
            } else {
              setItemDescription(e.target.value);
            }
          }}
        ></input>
        <p className="lg:ml-2 mr-2">Qty :</p>
        <input
          className="w-24 h-7 text-right pr-2"
          type="number"
          value={quantity ? quantity : ""}
          onChange={(e) => {
            if (!itemdescription) {
              alert("Knidly Provide Item Description");
            } else {
              if (!rate) {
                setQuantity(e.target.value);
              } else {
                setQuantity(e.target.value);
                setAmount(Number(e.target.value) * Number(rate));
              }
            }
          }}
        ></input>
        <p className="lg:ml-2 mr-2">Rate :</p>
        <input
          className="w-32 h-7 text-right pr-2"
          type="number"
          value={rate ? rate : ""}
          onChange={(e) => {
            if (!quantity) {
              alert("Please supply the quantity");
            } else {
              setRate(e.target.value);
              setAmount(Number(e.target.value) * Number(quantity));
            }
          }}
        ></input>
        <p className="lg:ml-2 mr-2">Amount :</p>
        <input
          className="w-32 h-7 text-right pr-2"
          value={
            amount
              ? amount?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : ""
          }
          readOnly
        ></input>
        <button
          className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300 lg:ml-14 mr-1"
          onClick={() => {
            AddItemData();
          }}
        >
          Add
        </button>
        <button
          className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300"
          onClick={() => {
            RemoveItems();
          }}
        >
          Remove
        </button>
      </div>
      {showItems && (
        <div class="overflow-auto m-2.5 p-2.5 lg:w-[70%] rounded-md shadow-md text-xs uppercase bg-gray-100">
          <table className="custom-table w-[95%] m-1 border-separate border-spacing-0 overflow-hidden rounded-md">
            <thead className="header-cell">
              <tr>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  ITEM DESCRIPTION
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  QUANTITY
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  RATE
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  AMOUNT
                </td>
              </tr>
            </thead>
            <tbody>
              {itemData?.length > 0 &&
                itemData?.map((item, index) => (
                  <tr
                    key={index}
                    className="cursor-pointer table-row h-[32px] my-[5px] py-[10px] border-b-[0.3px] border-[#002d62]"
                    style={{ textTransform: "uppercase" }}
                    onClick={() => {
                      setItemDescription(item?.ITEMNAME);
                      setQuantity(item?.QTY);
                      setRate(item?.ITEMRATE);
                      setAmount(item?.ITEMAMOUNT);
                    }}
                  >
                    <td className="w-[280px] border-l border-transparent border-r py-1 px-4">
                      {item.ITEMNAME ? item.ITEMNAME : "NA"}
                    </td>
                    <td className="border-l border-transparent border-r py-1 px-4">
                      {item.QTY ? item.QTY : "NA"}
                    </td>
                    <td className="border-l border-transparent border-r py-1 px-4">
                      {item.ITEMRATE
                        ? item.ITEMRATE?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "NA"}
                    </td>
                    <td className="border-l border-transparent border-r py-1 px-4">
                      {item.ITEMAMOUNT
                        ? item.ITEMAMOUNT?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "NA"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="lg:flex justify-between mt-2 lg:ml-2 mr-10">
            <div className="lg:flex gap-2">
              <p>Total Cost :</p>
              <input
                className="w-24 bg-green-200 text-right pr-2"
                readOnly
                value={
                  totalItemsCost
                    ? Number(
                        Number(totalItemsCost) + Number(totalLabourAmount)
                      )?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
              ></input>
            </div>
            <div className="lg:flex gap-2">
              <p>Spares Cost :</p>
              <input
                className="w-24 bg-yellow-200"
                value={
                  totalItemsCost
                    ? totalItemsCost?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
              ></input>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleExpenses;
