import React, { useState, useEffect } from "react";
import ManagerMenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";
import axios from "axios";
import { BiSolidEditAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
import Loading from "../../Components/Loading/Loading";

const TravelSuppliers = () => {
  const [search, setSearch] = useState("");
  const [Supplier, setSupplier] = useState([]);
  const [SupplierCode, setSupplierCode] = useState("");
  const [Suppliername, setSuppliername] = useState("");
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [isloading, setisloading] = useState(true);
  const state = useSelector((state) => state.changeTheState);
  const LoggedInUserName = state?.user?.emailId?.split("@")[0].toUpperCase();

  async function Suppliers() {
    setisloading(true);
    try {
      const result = await axios.get(`${BASEURL}/getSupplierList`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      });
      setSupplier(result?.data);
      setisloading(false);
    } catch (err) {
      //console.log(err);
      setisloading(false);
    }

    try {
      const result = await axios.get(`${BASEURL}/getNewSupplierNumber`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      });
      setSupplierCode(result?.data[0]?.NEXTNO);
      setisloading(false);
    } catch (err) {
      //console.log(err);
      setisloading(false);
    }
  }

  useEffect(() => {
    Suppliers();
  }, []);

  const filteredData = Supplier?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);
    if (searchData.length === 0) {
      return true;
    }
    return searchData.every((term) =>
      ["TRAVELSUPPLIERCODE", "TRAVELSUPPLIERNAME"].some((key) =>
        String(item[key]).toLowerCase().includes(term)
      )
    );
  });

  function handleEdit(item) {
    setSupplierCode(item.TRAVELSUPPLIERCODE);
    setSuppliername(item.TRAVELSUPPLIERNAME);
  }

  function SaveSupplier() {
    if (!SupplierCode || !Suppliername) {
      alert("All Fields Mandatory");
      return;
    }
    setisloading(true);
    axios
      .post(
        `${BASEURL}/insertTravelSupplier`,
        {
          SUPPLIERCODE: SupplierCode,
          SUPPLIERNAME: Suppliername,
          CREATEDBY: LoggedInUserName,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((res) => {
        alert(res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        //console.log(err);
        setisloading(false);
      });
  }

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handlePrint = () => {
    window.print();
  };

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="print:hidden block">
        <ManagerMenuNavbar />
        <header className="header__of__main lg:flex justify-between items-end px-3 ">
          <h1 className="header__of__page">Travel suppliers</h1>
        </header>
        <div className="lg:flex lg:flex-col mt-2 bg-slate-100 lg:h-16">
          <div className="block lg:flex lg:justify-between mt-2 lg:ml-2 items-center p-2">
            <div className="lg:flex mb-4 items-center">
              <p>Supplier Code </p>
              <input
                className="w-[100px] h-9 lg:ml-2 text-red-500 border-black"
                type="text"
                value={SupplierCode || ""}
              />
              <p className="lg:ml-8 ">Supplier Name</p>
              <input
                className="w-[100%] lg:w-80 h-9 lg:ml-2"
                type="text"
                value={Suppliername || ""}
                onChange={(e) => setSuppliername(e.target.value)}
              />
            </div>
            <div className="lg:flex p-2 ">
              <button
                className="lg:flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300 ml-2"
                onClick={() => window.location.reload()}
              >
                New
              </button>
              <button
                className="lg:flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300 ml-2"
                onClick={SaveSupplier}
              >
                Save
              </button>
              <button
                className="lg:flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300 ml-2"
                onClick={handlePrint}
              >
                Print
              </button>
            </div>
          </div>
        </div>
        <div className="lg:flex mt-2 lg:ml-2 items-center p-2">
          <p>Search :</p>
          <input
            className="w-80 h-9 lg:ml-2"
            type="text"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="overflow-auto m-2.5 p-2.5 lg:w-[50%] rounded-md shadow-md text-xs uppercase bg-gray-100">
          <table className="custom-table w-[95%] m-1 border-separate border-spacing-0 overflow-hidden rounded-md">
            <thead className="header-cell">
              <tr>
                <td className="bg-[#43505f] text-right text-white border border-[#055fc5]">
                  Supplier code
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  Supplier Name
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  Edit
                </td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData.map((item, index) => (
                  <tr
                    key={index}
                    className="table-row h-[32px] my-[5px] py-[17px] border-b-[0.3px] border-[#002d62] cursor-pointer"
                    style={{ textTransform: "uppercase" }}
                  >
                    <td className="w-[100px] text-right border-l border-transparent border-r py-1 px-4">
                      {item.TRAVELSUPPLIERCODE || "NA"}
                    </td>
                    <td className="border-l border-r border-transparent">
                      {item.TRAVELSUPPLIERNAME || "NA"}
                    </td>
                    <td
                      className="w-[80px] border-l border-r border-transparent"
                      onClick={() => {
                        scrollToSection();
                        handleEdit(item);
                      }}
                    >
                      <BiSolidEditAlt />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="hidden print:block">
        <header className="header__of__main lg:flex justify-between items-end px-3 ">
          <h1 className="header__of__page">Travel suppliers</h1>
        </header>
        <table className="custom-table w-[95%] m-1 border-separate border-spacing-0 overflow-hidden rounded-md">
          <thead className=" custom-table ">
            <tr className="">
              <td className="text-black font-medium uppercase text-right  border border-[#055fc5]">
                Supplier code
              </td>
              <td className="text-black font-medium uppercase text-border border-[#055fc5]">
                Supplier Name
              </td>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 &&
              filteredData.map((item, index) => (
                <tr
                  key={index}
                  className="table-row h-[32px] my-[5px] py-[17px] border-b-[0.3px] border-[#002d62] cursor-pointer"
                  style={{ textTransform: "uppercase" }}
                >
                  <td className="w-[100px] text-right border-l border-transparent border-r py-1 px-4">
                    {item.TRAVELSUPPLIERCODE || "NA"}
                  </td>
                  <td className="border-l border-r border-transparent">
                    {item.TRAVELSUPPLIERNAME || "NA"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TravelSuppliers;
