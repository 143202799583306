import React, { useState, useEffect } from "react";
import ManagerMenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";
import Select from "react-select";
import axios from "axios";
import { BiSolidEditAlt } from "react-icons/bi";
import { SiIterm2 } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Components/Loading/Loading";
const TravelAgents = () => {
  const [search, setSearch] = useState("");
  const [Agent, setAgent] = useState([]);
  const [AgentCode, setAgentCode] = useState("");
  const [Agentname, setAgentname] = useState("");
  const [isloading, setisloading] = useState(true);
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const state = useSelector((state) => state.changeTheState);
  //console.log(state?.user?.emailId);
  const LoggedInUserName = state?.user?.emailId?.split("@")[0].toUpperCase();
  //console.log(LoggedInUserName);
  async function Agents() {
    setisloading(true);
    try {
      const result = await axios.get(`${BASEURL}/getAgents`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      });
      //console.log(result);
      setAgent(result?.data);
      setisloading(false);
    } catch (err) {
      //console.log(err);
      setisloading(false);
    }
    try {
      const result = await axios.get(`${BASEURL}/getNewTravelagentNo`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      });
      //console.log(result);
      setAgentCode(result?.data[0]?.NEXTNO);
      setisloading(false);
    } catch (err) {
      //console.log(err);
      setisloading(false);
    }
  }
  useEffect(() => {
    Agents();
  }, []);

  const filteredData = Agent?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);
    if (
      searchData.length === 0
      // && !fromDateFilter && !toDateFilter
    ) {
      return true;
    }
    const textMatch = searchData.every((term) => {
      return [
        // ["BILLNO"],
        ["AGENTCODE"],
        ["AGENTNAME"],
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    // const Date = item.RECEIPTDATE;
    // if (searchData.length === 0 && !fromDateFilter && !toDateFilter) {
    //   return true;
    // }
    // const dateMatch =
    //   (!fromDateFilter || Date >= fromDateFilter) &&
    //   (!toDateFilter || Date <= toDateFilter);

    return textMatch;
    // && dateMatch;
  });

  function handleEdit(item) {
    setAgentCode(item.AGENTCODE);
    setAgentname(item.AGENTNAME);
  }
  function SaveAgent() {
    //console.log(AgentCode, Agentname, LoggedInUserName);
    if (!AgentCode || !Agentname) {
      alert("All Fields Mandatory");
      return;
    }
    setisloading(true);
    axios
      .post(
        `${BASEURL}/insertTravelAgent`,

        {
          TRAVELAGENTCODE: AgentCode,
          TRAVELAGENTNAME: Agentname,
          CREATEDBY: LoggedInUserName,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((res) => {
        //console.log(res);
        alert(res.data.message);
        window.location.reload();
        setisloading(false);
      })
      .catch((err) => {
        //console.log(err);
        setisloading(false);
      });
  }

  if (isloading) {
    return <Loading />;
  }
  const handlePrint = () => {
    window.print();
  };
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      {" "}
      <div className="print:hidden block">
        <ManagerMenuNavbar />
        <header className="header__of__main lg:flex justify-between items-end px-3">
          <h1 className="header__of__page">Travel Agents</h1>
        </header>
        <div className="lg:flex lg:flex-col mt-2 bg-slate-100 lg:h-16">
          <div className="block lg:flex lg:justify-between items-center lg:ml-2 p-2">
            <div className="lg:flex mb-4 items-center px-1">
              <p>Agent Code </p>
              <input
                className="w-[100px] h-9 lg:ml-2 text-red-500 border-black"
                type="text"
                value={AgentCode ? AgentCode : ""}
                // onChange={(e) => setOperationTask(e.target.value)}
              ></input>
              <p className="lg:ml-4 ">Agent Name </p>
              <input
                className="w-[100%] lg:w-80 h-9 lg:ml-2"
                type="text"
                value={Agentname ? Agentname : ""}
                onChange={(e) => setAgentname(e.target.value)}
              ></input>
            </div>
            <div className="lg:flex p-2  ">
              <button
                className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300 "
                onClick={() => {
                  window.location.reload();
                }}
              >
                New{" "}
              </button>
              <button
                className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300 ml-2"
                onClick={async () => {
                  if (!AgentCode) {
                    alert("Kindly Supply Agent Code");
                  } else if (!Agentname) {
                    alert("Kindly Supply Agent Name");
                  } else {
                    await SaveAgent();
                    // window.location.reload();
                  }
                }}
              >
                Save
              </button>
              <button
                className="lg:flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300 ml-2"
                onClick={handlePrint}
              >
                Print
              </button>
            </div>
          </div>
        </div>
        <div className="lg:flex mt-2 lg:ml-2 items-center p-1">
          <p>Search :</p>
          <input
            className="w-80 h-9 lg:ml-2"
            type="text"
            // value={search ? search : ""}
            onChange={(e) => setSearch(e.target.value)}
          ></input>
          {/* <p className="lg:ml-4 mr-4">Job Type :</p>
        <Select
          className="w-64"
          value={
            selJobTypeSearch
              ? { label: selJobTypeSearch, value: selJobTypeSearch }
              : { label: "Select Job Type", value: "" }
          }
          onChange={(e) => {
            setSelJobTypeSearch(e.value);
          }}
          options={[
            { label: "Select Job Type", value: "" },
            ...jobtypeList.map((item) => ({
              label: item?.JOBTYPE,
              value: item?.JOBTYPE,
            })),
          ]}
        ></Select> */}
          {/* <p className="lg:ml-4 mr-4">Operation Type :</p>
        <Select
          className="w-64"
          value={
            selOperationTypeSearch
              ? { label: selOperationTypeSearch, value: selOperationTypeSearch }
              : { label: "Select Operation Type", value: "" }
          }
          onChange={(e) => {
            setSelOperationTypeSearch(e.value);
          }}
          options={[
            { label: "Select Operation Type", value: "" },
            { label: "SERVICE", value: "SERVICE" },
            { label: "STANDARD", value: "STANDARD" },
          ]}
        ></Select> */}
        </div>
        <div class="overflow-auto m-2.5 p-2.5 lg:w-[50%] rounded-md shadow-md text-xs uppercase bg-gray-100">
          <table className="custom-table w-[95%] m-1 border-separate border-spacing-0 overflow-hidden rounded-md">
            <thead className="header-cell">
              <tr>
                <td className="bg-[#43505f] text-white border border-[#055fc5] text-right">
                  Agent code
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  Agent name
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  Edit
                </td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData?.map((item, index) => (
                  <tr
                    key={index}
                    className=" table-row h-[32px] my-[5px] py-[10px] border-b-[0.3px] border-[#002d62] cursor-pointer"
                    style={{ textTransform: "uppercase" }}
                  >
                    <td className="w-[80px] text-right border-l border-transparent border-r py-1 px-4">
                      {item.AGENTCODE ? item.AGENTCODE : "NA"}
                    </td>
                    <td className="w-[280px] border-l border-r border-transparent">
                      {item.AGENTNAME ? item.AGENTNAME : "NA"}
                    </td>
                    <td
                      className="w-[80px] border-l border-r border-transparent"
                      onClick={() => {
                        handleEdit(item);
                        scrollToSection();
                      }}
                    >
                      <BiSolidEditAlt />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="hidden print:block">
        <header className="header__of__main lg:flex justify-between items-end px-3">
          <h1 className="header__of__page">Travel Agents</h1>
        </header>
        <div class="overflow-auto m-2.5 p-2.5 lg:w-[50%] rounded-md shadow-md text-xs uppercase bg-gray-100">
          <table className="custom-table w-[95%] m-1 border-separate border-spacing-0 overflow-hidden rounded-md">
            <thead className=" custom-table ">
              <tr>
                <td className="text-black font-medium uppercase border border-[#055fc5] text-right">
                  Agent code
                </td>
                <td className="text-black font-medium uppercase border border-[#055fc5] px-4">
                  Agent name
                </td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData?.map((item, index) => (
                  <tr
                    key={index}
                    className=" table-row h-[32px] my-[5px] py-[10px] border-b-[0.3px] border-[#002d62] cursor-pointer"
                    style={{ textTransform: "uppercase" }}
                  >
                    <td className="w-[80px] text-right border-l border-transparent border-r py-1 px-4">
                      {item.AGENTCODE ? item.AGENTCODE : "NA"}
                    </td>
                    <td className="w-[280px] border-l border-r border-transparent px-8">
                      {item.AGENTNAME ? item.AGENTNAME : "NA"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TravelAgents;
