import React, { useState, useContext } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdLogout } from "react-icons/md";
import { logIn } from "../../Redux/Action";
import { FaStepBackward } from "react-icons/fa";
import { AuthContext } from "../../context/AuthContext";

import "./Navbar.css";

const ManagerNavbar = () => {
  const { setAuthUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const state = useSelector((state) => state?.changeTheState);
  const dispatch = useDispatch();

  //console.log(state?.user?.userName, "nav");
  //console.log(state?.user?.ADMINISTRATOR, "ADMINISTRATOR");

  //console.log(state, "nav");

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  function logOut() {
    dispatch(logIn([]));
    navigate("/");
    setAuthUser(null);
  }

  const handleCategory = (category) => {
    setSelectedCategory((prevCategory) =>
      prevCategory === category ? null : category
    );
  };

  return (
    <nav className="centernav sticky top-0 z-[1000] bg-white">
      <div className="navbar-container">
        <nav className="navbar block  ">
          <div className="block ">
            <div className="fabar" onClick={toggleNavbar}>
              {isOpen ? <FaTimes /> : <FaBars />}
            </div>
          </div>
          <ul className={`nav-menu ${isOpen ? "open" : ""}`}>
            <div className="menu-category cursor-pointer ">
              <h3
                className="menu-heading pl-2 font-semibold"
                onClick={() => handleCategory("master")}
              >
                Master
              </h3>
              {selectedCategory === "master" && (
                <ul className="submenu pl-5">
                  <li onClick={() => navigate("/TaskMaster")}>Task Master</li>
                  <li onClick={() => navigate("/VehicleMaster")}>
                    Vehicle master
                  </li>
                  <li onClick={() => navigate("/MakeMaster")}>Make master</li>
                  <li onClick={() => navigate("/ModelMaster")}>Model master</li>
                  <li onClick={() => navigate("/MakeandModelMaster")}>
                    Make and Model master
                  </li>
                  <li onClick={() => navigate("/EmployeeMaster")}>
                    Employee master
                  </li>
                  <li onClick={() => navigate("/TravelSuppliers")}>
                    Travel suppliers
                  </li>
                  <li onClick={() => navigate("/TravelAgents")}>
                    Travel Agents
                  </li>
                  <li onClick={() => navigate("/PointsofInterestMaster")}>
                    Points of Interest Master
                  </li>
                  <li onClick={() => navigate("/RouteMaster")}>Route Master</li>
                  <li onClick={() => navigate("/CurrencyMaster")}>
                    Currency Master
                  </li>
                  <li onClick={() => navigate("/CurrencyExchangeRates")}>
                    Currency exchange rates
                  </li>
                </ul>
              )}
            </div>
            <div className="menu-category pb-2 cursor-pointer">
              <h3
                className="menu-heading pl-2 font-semibold"
                onClick={() => handleCategory("transaction")}
              >
                Transaction
              </h3>
              {selectedCategory === "transaction" && (
                <ul className="submenu pl-5">
                  <li onClick={() => navigate("/AllTripDetails")}>
                    All Bookings
                  </li>
                  <li onClick={() => navigate("/VehicleMovement")}>
                    Vehicle Movement
                  </li>
                  <li onClick={() => navigate("/FuelVoucher")}>Fuel Voucher</li>
                  <li onClick={() => navigate("/VehicleExpenses")}>
                    Vehicle Expenses
                  </li>
                  <li onClick={() => navigate("/BookingReference")}>
                    Booking Reference
                  </li>
                </ul>
              )}
            </div>
          </ul>
        </nav>
        <div className="flex justify-between items-center w-[80%]">
          <aside className="welcom__nav">
            <h1 className="font-bold text-lg text-white ">Welcome to TecH23</h1>
          </aside>
          <aside>
            <h1 className="font-bold ml-1 md:text-lg text-white uppercase">
              Welcome : {state?.user?.userName ? state?.user?.userName : " "}
            </h1>
          </aside>
        </div>
        <aside>
          <button onClick={logOut} className="button__logout__navbar">
            LOGOUT
          </button>
        </aside>
        <div className="logout__fullscren">
          <button onClick={logOut} className="log__out__icon">
            <MdLogout />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default ManagerNavbar;
