import React, { useState, useEffect, useRef } from "react";
import ManagerNavbar from "../../Components/Navbar/ManagerNavbar";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Add from "../../Components/Advertising/InfiniteCardSlider";
import { GiNotebook } from "react-icons/gi";
import { GiMineTruck } from "react-icons/gi";
import { FaTruckArrowRight } from "react-icons/fa6";
import { HiPuzzlePiece } from "react-icons/hi2";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { GiExpense } from "react-icons/gi";
import { GiTakeMyMoney } from "react-icons/gi";
import { TbFileReport } from "react-icons/tb";
import { FaEdit } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import { MdManageAccounts } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
const ManagerMenu = () => {
  const navigate = useNavigate();

  return (
    <div>
      <ManagerNavbar />
      <section className="flex all__page__main__section">
        {/* <aside className="w-[100%]">
          <Add />
        </aside> */}
      </section>
      <header className="dashboard__header">
        <div onClick={() => navigate("/AllTripDetails")}>
          <aside>
            <GiNotebook />
          </aside>
          <p>All Bookings</p>
        </div>

        <div onClick={() => navigate("/FuelVoucher")}>
          <aside>
            {" "}
            <BsFillFuelPumpFill />
          </aside>
          <p>Fuel Voucher </p>
        </div>
        <div onClick={() => navigate("/VehicleExpenses")}>
          <aside>
            {" "}
            <GiReceiveMoney />
          </aside>
          <p>Vehicle Expenses</p>
        </div>

        <div onClick={() => navigate("/TaskMaster")}>
          <aside>
            {" "}
            <MdManageAccounts />
          </aside>
          <p>Task Master </p>
        </div>
        <div onClick={() => navigate("/BookingReference")}>
          <aside>
            {" "}
            <FaRegCalendarAlt />
          </aside>
          <p>Safari Booking</p>
        </div>
      </header>
      {/* <main className="flex flex-wrap justify-between">
        <aside
          className="
          table__safari__app "
        >
          <h1>BOOKINGS PENDING TRANSFERS</h1>
          <table class="custom-table">
            <thead class="header-cell">
              <td>MODULE&nbsp;ID</td>

              <td>EDIT</td>
            </thead>
            <tbody>
              <tr class="table-row">
                <td className="alignleft">1</td>

                <td>
                  <FaEdit />
                </td>
              </tr>
            </tbody>
          </table>
        </aside>
        <aside
          className="
             table__safari__app "
        >
          <h1>PICK UPS DONE AND PENDING ARRIVALS</h1>
          <table class="custom-table">
            <thead class="header-cell">
              <td>MODULE&nbsp;ID</td>

              <td>EDIT</td>
            </thead>
            <tbody>
              <tr class="table-row">
                <td className="alignleft">1</td>

                <td>
                  <FaEdit />
                </td>
              </tr>
            </tbody>
          </table>
        </aside>
        <aside
          className="
             table__safari__app "
        >
          <h1>TRANSFERS CONFIRMED PENDING PICK UPS</h1>
          <table class="custom-table">
            <thead class="header-cell">
              <td>MODULE&nbsp;ID</td>

              <td>EDIT</td>
            </thead>
            <tbody>
              <tr class="table-row">
                <td className="alignleft">1</td>

                <td>
                  <FaEdit />
                </td>
              </tr>
            </tbody>
          </table>
        </aside>

        <aside
          className="
             table__safari__app "
        >
          <h1>PENDING JOURNEY PLANNING</h1>
          <table class="custom-table">
            <thead class="header-cell">
              <td>MODULE&nbsp;ID</td>

              <td>EDIT</td>
            </thead>
            <tbody>
              <tr class="table-row">
                <td className="alignleft">1</td>

                <td>
                  <FaEdit />
                </td>
              </tr>
            </tbody>
          </table>
        </aside>
      </main> */}
      <div className="mt-[20vh]">&nbsp;</div>
    </div>
  );
};

export default ManagerMenu;
