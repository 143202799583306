const initializse = {}
const Tripstatusreducer = (state = initializse, action) => {
    switch (action.type) {
        case "TRIP":
            return action.payload
        default:
            return state;
    }


}
export default Tripstatusreducer;