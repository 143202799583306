import React from "react";

import "../NotFound/NotFound.css";
import { useNavigate } from "react-router";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div>
      <p className="flex justify-end">
        <button
          onClick={() => navigate("/ClientDashboard")}
          className="bg-blue-600 p-1 m-2 text-white rounded "
        >
          Back
        </button>
      </p>
      <div className="not_found">
        <h1>404</h1>
        <p>NOT FOUND</p>
      </div>
    </div>
  );
};

export default NotFound;
