import React, { useEffect, useState } from "react";
import DriverNavbar from "../../Components/Navbar/DriverNavbar";
import DriverMenuNavbar from "../../Components/Navbar/DriverMenuNavbar";
import { FaEdit } from "react-icons/fa";
import { BsFillClipboardDataFill } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import { useSelector } from "react-redux";
import { journeyStatusUpdate } from "../../Redux/Action/journeyStatusUpdate";
import Loading from "../../Components/Loading/Loading";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import { HiDocumentCheck } from "react-icons/hi2";
import Select from "react-select";
import "../InternalTransfer/InternalTransfer.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const DriverAllocation = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const state = useSelector((state) => state.changeTheState);
  // const [isLoading, setIsLoading] = useState(false);
  const TripState = useSelector((state) => state.Tripstatusreducer);
  const [driverdetails, setDriverdetails] = useState([]);
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState("ALL");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [TransferOrSafari, setTransferOrSafari] = useState();
  //console.log(state);
  //console.log(TripState);
  const navigate = useNavigate();
  const { userCode } = state.user;

  function driverInfo() {
    if (state?.user?.designation === "DRIVER") {
      if (TripState == "COMPLETED") {
        //console.log(userCode);
        setIsLoading(true);
        axios
          .post(
            `${BASEURL}/getTripCompleted`,
            {
              DRIVERCODE: userCode,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          )
          .then((response) => {
            setDriverdetails(response.data);
            //console.log(response.data);
            setIsLoading(false);
          })

          .catch((error) => console.error(error));
      }

      if (TripState == "NOTYETSTARTED") {
        //console.log(userCode);
        setIsLoading(true);
        axios
          .post(
            `${BASEURL}/getTripNotStarted`,
            {
              DRIVERCODE: userCode,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          )
          .then((response) => {
            setDriverdetails(response.data);
            //console.log(response.data);
            // setTransferOrSafari(response.data["TRANSFER/SAFARI"]);
            // //console.log(response.data["TRANSFER/SAFARI"]);

            setIsLoading(false);
          })

          .catch((error) => console.error(error));
      }

      if (TripState == "INPROGRESS") {
        //console.log(userCode);
        setIsLoading(true);
        axios
          .post(
            `${BASEURL}/getTripInitiated`,
            {
              DRIVERCODE: userCode,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          )
          .then((response) => {
            setDriverdetails(response.data);
            //console.log(response.data);
            setIsLoading(false);
          })

          .catch((error) => console.error(error));
      }
    }

    // /getreptripstatuscount
    // /getrepTripNotStarted
    // /getrepTripInitiated
    // /getrepTripCompleted

    // REPCODE = '${req.body.REPCODE}'`
    if (state?.user?.designation === "REP") {
      if (TripState == "COMPLETED") {
        //console.log(userCode);
        setIsLoading(true);
        axios
          .post(
            `${BASEURL}/getrepTripCompleted`,
            {
              REPCODE: userCode,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          )
          .then((response) => {
            setDriverdetails(response.data);
            //console.log(response.data);
            setIsLoading(false);
          })

          .catch((error) => console.error(error));
      }

      if (TripState == "NOTYETSTARTED") {
        //console.log(userCode);
        setIsLoading(true);
        axios
          .post(
            `${BASEURL}/getrepTripNotStarted`,
            {
              REPCODE: userCode,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          )
          .then((response) => {
            setDriverdetails(response.data);
            //console.log(response.data);
            setIsLoading(false);
          })

          .catch((error) => console.error(error));
      }

      if (TripState == "INPROGRESS") {
        //console.log(userCode);
        setIsLoading(true);
        axios
          .post(
            `${BASEURL}/getrepTripInitiated`,
            {
              REPCODE: userCode,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          )
          .then((response) => {
            setDriverdetails(response.data);
            //console.log(response.data);
            setIsLoading(false);
          })

          .catch((error) => console.error(error));
      }
    }
  }

  useEffect(() => {
    driverInfo();
  }, []);

  // const handleConfirmJourney = (confirmationType) => {
  //   const isConfirmed = window.confirm(`Confirm ${confirmationType} Journey?`);

  //   if (isConfirmed) {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           const { latitude, longitude } = position.coords;

  //           // Get current date and time
  //           const currentDate = new Date();
  //           const currentDateTime = currentDate.toLocaleString();

  //           // Use the captured information as needed
  //           //console.log("Latitude:", latitude);
  //           //console.log("Longitude:", longitude);
  //           //console.log("Current Date and Time:", currentDateTime);

  //           alert(`Journey ${confirmationType} confirmed.`);
  //         },
  //         (error) => {
  //           console.error("Error getting location:", error.message);
  //         }
  //       );
  //     }
  //   } else {
  //     // If not confirmed, do nothing or handle accordingly
  //     //console.log(`Journey ${confirmationType} not confirmed.`);
  //   }
  // };
  const filteredData = driverdetails.filter((item) => {
    const searchData = search?.toLowerCase().split(" ").filter(Boolean);

    // Convert statusFilter to uppercase for comparison
    const selectedStatus = statusFilter?.toUpperCase();

    // Check if statusFilter matches the trip type
    const statusMatch =
      selectedStatus === "ALL" ||
      item["TRANSFER/SAFARI"]?.toUpperCase() === selectedStatus;

    // Check if any search term matches any of the specified keys
    const textMatch =
      searchData.length === 0 ||
      searchData.every((term) =>
        [
          "BOOKINGID",
          "REFNO",
          "DRIVERNAME",
          "DROPOFFPLACE",
          "PICKUPFROM",
          "STARTEDTRIP",
          "VEHICLEPLANNED",
          "TRIPNUMBER",
          "TRANSFER/SAFARI",
        ].some((key) => String(item[key])?.toLowerCase().includes(term))
      );

    // Return true only if both statusMatch and textMatch are true
    return statusMatch && textMatch;
  });

  //console.log(filteredData);
  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };
  function proceedToUpdate(item) {
    //console.log(item);
    //console.log(item["TRANSFER/SAFARI"]);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          //console.log("Latitude:", latitude);
          //console.log("Longitude:", longitude);
          const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
              if (data.display_name) {
                const address = data.display_name;
                //console.log(address);
                const datas = {
                  dbData: item,
                  geoCod: `${latitude}, ${longitude}`,
                  address: address,
                  fromPage: "DriverAllocation",
                };

                dispatch(journeyStatusUpdate(datas));
                if (item["TRANSFER/SAFARI"] == "Safari") {
                  navigate("/SafariPlan");
                } else {
                  navigate("/DriverTripSubmission");
                }
              }
            });
        },
        (error) => {
          console.error("Error getting location:", error.message);
        }
      );
    }
  }

  const renderList = (
    <div>
      <DriverMenuNavbar />
      <header className="header__of__main flex justify-between items-end px-3">
        {TripState === "NOTYETSTARTED" && (
          <h1 className="header__of__page"> TRIP NOT STARTED</h1>
        )}
        {TripState === "INPROGRESS" && (
          <h1 className="header__of__page">TRIP STARTED NOT ENDED</h1>
        )}
        {TripState === "COMPLETED" && (
          <h1 className="header__of__page">TRIP COMPLETED</h1>
        )}
        <h2 className="text-white">
          <button
            onClick={() => {
              navigate("/DriverMenu");
            }}
            style={{ backgroundColor: "#2f2f5a", color: "white" }}
            className="button__logout__navbar mb-6 text-white p-1 rounded "
          >
            BACK
          </button>
        </h2>
      </header>
      <div className="mx-4">
        <div className="searchhhhhh mx-4">
          <p>Search : </p>
          <input
            className="input_1"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>
          <div className="search__icon">
            {" "}
            <FaSearch />
          </div>
          <div>
            <label>Select Trip Type </label>
            <select
              style={{
                border: "1px solid",
                borderRadius: "4px",
                marginLeft: "4px",
                fontSize: "16px",
              }}
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="ALL" className="text-sm">
                All
              </option>
              <option value="TRANSFER" className="text-sm">
                TRANSFER
              </option>
              <option value="SAFARI" className="text-sm">
                SAFARI
              </option>
            </select>
          </div>
        </div>
      </div>

      <aside className="table__reference__app ">
        {/* <h2 className="my-2 text-[22px] font-semibold">All Trip details</h2> */}
        <table className="custom-table ">
          <thead className="header-cell">
            <td>Booking ID</td>
            {/* <td>Trip Number</td> */}
            <td>Reference Number</td>
            <td>Guest Name</td>
            <td>AGENT NAME</td>

            <td>
              {TripState == "NOTYETSTARTED" && (
                <p>Planned Pick-up Date & Time</p>
              )}
              {TripState == "INPROGRESS" && <p>Start Date & Time</p>}
              {TripState == "COMPLETED" && <p>Start Date & Time</p>}
              {/* {filteredData.some((item) => item.PLANPICKUPDATE)
                ? "Planned Pick-up Date & Time"
                : filteredData.some(
                    (item) => item.STARTDATETIME && item.STARTTIME
                  )
                ? "Start Date & Time "
                : filteredData.some((item) => item.ENDDATETIME && item.ENDTIME)
                ? "End Date & Time"
                : "N/A"} */}
            </td>

            {/* <td>
             
              {TripState == "NOTYETSTARTED" && (
                <p>Planned Drop-off Date & Time</p>
              )}
              {TripState == "INPROGRESS" && <p>Planned Drop-off Date & Time</p>}
              {TripState == "COMPLETED" && <p>End Date & Time</p>}
            </td> */}
            {/* <td>
              {filteredData.some((item) => item.STARTTIME) ? "Start Time" : " "}
            </td>
            <td>
              {filteredData.some((item) => item.ENDTIME) ? "End Time" : " "}
            </td> */}

            {/* <td>Narration</td> */}
            {/* <td>STATUS</td> */}
            <td>Trip Type</td>
          </thead>
          <tbody>
            {filteredData && filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr
                  onClick={() => {
                    if (TripState !== "COaaMPLETED") {
                      proceedToUpdate(item);
                    }
                  }}
                  key={index}
                  className="table-row cursor-pointer hover:bg-blue-200"
                >
                  <td>
                    <p className="alignleft px-2">
                      {item.BOOKINGID ? item.BOOKINGID : "N/A"}
                    </p>
                  </td>
                  {/* <td>
                    <p className="alignleft px-2">
                      {item.TRIPNUMBER ? item.TRIPNUMBER : "N/A"}
                    </p>
                  </td> */}
                  <td> {item.REFNO ? item.REFNO : "N/A"}</td>
                  <td>
                    {" "}
                    {item["CLIENT NAME"]
                      ? item["CLIENT NAME"]
                      : "Not Available"}
                  </td>
                  <td>
                    {" "}
                    {item["AGENT NAME"] ? item["AGENT NAME"] : "Not Available"}
                  </td>
                  <td>
                    {TripState == "NOTYETSTARTED" && (
                      <p>
                        {item.PLANPICKUPDATE
                          ? `${formattedDate(item.PLANPICKUPDATE).slice(
                              0,
                              10
                            )} , ${item.PLANPICKUPTIME.slice(11, 19)}`
                          : "N/A"}
                      </p>
                    )}
                    {TripState == "INPROGRESS" && (
                      <p>
                        {item.STARTDATETIME && item.STARTTIME
                          ? `${formattedDate(item.STARTDATETIME).slice(
                              0,
                              10
                            )} , ${item.STARTTIME.slice(11, 19)}`
                          : "N/A"}
                      </p>
                    )}
                    {TripState == "COMPLETED" && (
                      <p>
                        {item.STARTDATETIME && item.STARTTIME
                          ? `${formattedDate(item.STARTDATETIME).slice(
                              0,
                              10
                            )} , ${item.STARTTIME.slice(11, 19)}`
                          : "N/A"}
                      </p>
                    )}
                    {/* {item.PLANPICKUPDATE
                      ? `${formattedDate(item.PLANPICKUPDATE).slice(
                          0,
                          10
                        )} , ${item.PLANPICKUPTIME.slice(11, 19)}`
                      : item.STARTDATETIME && item.STARTTIME
                      ? `${formattedDate(item.STARTDATETIME).slice(
                          0,
                          10
                        )} , ${item.STARTTIME.slice(11, 19)}`
                      : item.ENDDATETIME && item.ENDTIME
                      ? `${formattedDate(item.ENDDATETIME).slice(
                          0,
                          10
                        )} , ${item.ENDTIME.slice(11, 19)}`
                      : "N/A"}
                    {} */}
                  </td>
                  {/* planned drop date */}
                  {/* <td>
                    {TripState == "NOTYETSTARTED" && (
                      <p>
                        {item.PLANDROPDATE
                          ? `${formattedDate(item.PLANDROPDATE).slice(
                              0,
                              10
                            )} , ${item.PLANDROPTIME.slice(11, 19)}`
                          : " "}
                      </p>
                    )}
                    {TripState == "INPROGRESS" && (
                      <p>
                        {item.PLANDROPDATE
                          ? `${formattedDate(item.PLANDROPDATE).slice(
                              0,
                              10
                            )} , ${item.PLANDROPTIME.slice(11, 19)}`
                          : " "}
                      </p>
                    )}
                    {TripState == "COMPLETED" && (
                      <p>
                        {item.ENDDATETIME && item.ENDTIME
                          ? `${formattedDate(item.ENDDATETIME).slice(
                              0,
                              10
                            )} , ${item.ENDTIME.slice(11, 19)}`
                          : "N/A"}
                      </p>
                    )}
                   
                  </td> */}

                  {/* <td>
                    {item.STARTTIME ? ` ${item.STARTTIME.slice(11, 19)}` : " "}
                  </td>
                  <td>
                    {item.ENDTIME ? ` ${item.ENDTIME.slice(11, 19)}` : " "}
                  </td> */}
                  {/* <td> {item.NARRATION ? item.NARRATION : "N/A"}</td> */}
                  {/* <td style={{ color: "red" }}>
                    {item.STARTEDTRIP & 1 ? "INITIATED" : "NOT INITIATED"}
                  </td> */}
                  {/* <td className="text-center text-lg">
                    <button
                      onClick={() => {
                        proceedToUpdate(item);
                      }}
                    >
                      {" "}
                      <HiDocumentCheck />
                    </button>
                  </td> */}
                  <td style={{ color: "red" }}>
                    {" "}
                    {item["TRANSFER/SAFARI"] ? item["TRANSFER/SAFARI"] : "N/A"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="15">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </aside>
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
};

export default DriverAllocation;
