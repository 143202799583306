import React from "react";
import "./Footer.css";
import {
  FaSortAmountDownAlt,
  FaSortAmountUpAlt,
  FaRegArrowAltCircleUp,
} from "react-icons/fa";
const Footer = () => {
  // year has been added
  const today = new Date();
  const year = today.getFullYear();
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="mt-[30vh]">
      <footer id="Support" className="footerstyle z-50">
        <div className="toalignaside">
          {/* brand name is given in H tag */}
          <aside className="aside1">
            <h3 className="font-bold">TeCH23</h3>

            {/* email of the company  */}

            <p>Email : Support@tech23.net</p>
            {/* contact of company is given here */}
            <p>Contact : +254 798074411 </p>
          </aside>
          <aside className="aside2">
            {/* detail information about terms of use  */}
            <p>
              <h4 className="font-bold">Terms of Use</h4>
              This Software program is protected by the copyright law and
              international treaties.Unauthorized reproduction or redistribution
              of this program or any portion of it may result in severe civil
              and penalties, and will be prosecuted to maximum extent possible
              under the law.
            </p>
          </aside>
        </div>
        {/* copyright with symbol and all right reserved */}
        <div
          className="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", textAlign: "center" }}
        >
          {/* year has been added dynamically */}
          Copyright © {year} TeCH23. All Rights Reserved
        </div>
      </footer>
      {/* <section
        style={{ display: activeTab === 1 ? "block " : "none" }}
        className={activeTab === 1 ? "active__section" : ""}
      >
        {" "}
        <aside>
          <main>
            {" "}
            <button className="button_style ">New</button>
            <button className="button_style ">Save</button>
            <button className="button_style ">Delete</button>
          </main>
        </aside>
        <aside className="flex justify-between px-2 items-center">
          <p className="flex items-center gap-1">
            <label>System Booking Number</label>
            <input style={{ width: "100px" }}></input>
            <button className="button_style__pop ">....</button>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>Actual Booking Number</label>
            <input style={{ width: "100px" }}></input>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>Driver</label>
            <input style={{ width: "150px" }}></input>
          </p>
          <p>
            <label>Date</label>
            <input
              // type="date"
              value={DateValue}
              style={{ width: "130px" }}
            ></input>
          </p>
        </aside>
        <aside className="flex justify-between px-2 items-center">
          <p className="flex items-center gap-1">
            <label>No. of Pax</label>
            <input style={{ width: "100px" }}></input>
            <button className="button_style__pop ">....</button>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>From Country</label>
            <div
              style={{
                width: "300px",
                margin: "5px",
              }}
            >
              <Select
                value={{ value: country, label: country }}
                style={customStyless}
              ></Select>
            </div>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>Booking Status</label>
            <div
              style={{
                width: "300px",
                margin: "5px",
              }}
            >
              <Select
                value={{ value: bookingStatus, label: bookingStatus }}
                style={customStyless}
              ></Select>
            </div>
          </p>
        </aside>
        <aside className="flex justify-between px-2 items-center">
          <p className="flex items-center gap-1">
            <label>Client Name</label>
            <input style={{ width: "100px" }}></input>
            <button className="button_style__pop ">....</button>
            <input value={client} style={{ width: "400px" }}></input>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>Supplier</label>
            <div
              style={{
                width: "300px",
                margin: "5px",
              }}
            >
              <Select
                value={{ value: supplier, label: supplier }}
                style={customStyless}
              ></Select>
            </div>
          </p>
        </aside>
        <aside className="flex justify-between px-2 items-center">
          <p className="flex items-center gap-1">
            <label>Service Description</label>
            <textarea
              name=""
              id=""
              className="max-w-[400px] min-w-[400px] max-h-[60px] min-h-[60px] text__Area"
            ></textarea>
          </p>
          <aside>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <label>Service Status</label>
              <div
                style={{
                  width: "300px",
                  margin: "5px",
                }}
              >
                <Select
                  value={{ value: serviceStatus, label: serviceStatus }}
                  style={customStyless}
                ></Select>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <label htmlFor="">Ad/Ch/Int</label>
              <input type="number" style={{ width: "80px" }}></input>
              <input type="number" style={{ width: "80px" }}></input>
              <input type="number" style={{ width: "80px" }}></input>
            </div>
          </aside>
        </aside>
        <aside className="flex justify-between px-2 items-center">
          <aside>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <label htmlFor="">Pick Up Time</label>
              <input
                // type="number"
                value={pickupTime}
                style={{ width: "80px" }}
              ></input>
              <label htmlFor="">Drop Off Time</label>
              <input
                value={dropOffTime}
                // type="number"
                style={{ width: "80px" }}
              ></input>
              <label htmlFor="">Flight No</label>
              <input
                value={flightNo}
                // type="number"
                style={{ width: "80px" }}
              ></input>
            </div>
          </aside>
        </aside>
        <aside className="flex gap-4 px-2 items-center">
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>Pick up Place</label>
            <div
              style={{
                width: "300px",
                margin: "5px",
              }}
            >
              <Select
                value={{ value: pickupPlace, label: pickupPlace }}
                style={customStyless}
              ></Select>
            </div>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>Drop Off Place</label>
            <div
              style={{
                width: "300px",
                margin: "5px",
              }}
            >
              <Select
                value={{ value: dropOffPlace, label: dropOffPlace }}
                style={customStyless}
              ></Select>
            </div>
          </p>
        </aside>
        <aside className="flex justify-between px-2 items-center">
          <aside>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <label>Booking Phone</label>
              <input value={phone} style={{ width: "200px" }}></input>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <label>Booking Agent</label>
              <div
                style={{
                  width: "300px",
                  margin: "5px",
                }}
              >
                <Select
                  value={{ value: bookingAgent, label: bookingAgent }}
                  style={customStyless}
                ></Select>
              </div>
            </div>
          </aside>
          <p className="flex items-center gap-1">
            <label>Notes</label>
            <textarea
              value={note}
              name=""
              id=""
              className="max-w-[400px] min-w-[400px] max-h-[60px] min-h-[60px] text__Area"
            ></textarea>
          </p>
        </aside>
        <h1 className="text-[25px] text-red-800 font-bold px-2">
          List of Historical Bookings
        </h1>
        <aside className="flex justify-between px-2 items-center">
          <p className="flex items-center gap-1">
            <label>From</label>
            <input type="date" style={{ width: "120px" }}></input>
          </p>
          <p className="flex items-center gap-1">
            <label>To</label>
            <input type="date" style={{ width: "120px" }}></input>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <aside className="flex items-center gap-2">
                <button className="bg-green-400 p-3 m-1 rounded block "></button>{" "}
                <p>Internal Transfers</p>
              </aside>
              <aside className="flex items-center gap-2">
                {" "}
                <button className=" bg-red-400  p-3 m-1 rounded block"></button>
                <p> Safari (lncludes Half Day and Full Day)</p>
              </aside>
            </div>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>View</label>
            <div
              style={{
                width: "300px",
                margin: "5px",
              }}
            >
              <Select style={customStyless}></Select>
            </div>
          </p>
          <p>
            <button className="bg-slate-300 px-2 h-[30px] m-1 rounded block ">
              Refresh
            </button>
          </p>
        </aside>
        <main className="flex flex-wrap justify-between">
          <aside className="table__reference__app ">
            <table class="custom-table">
              <thead class="header-cell">
                <tr>
                  <td>Booking ID</td>
                  <td>Client Code</td>
                  <td>Booking Date</td>
                  <td>PICKUP TIME</td>
                  <td>Booking Status</td>
                  <td>SERVICE Status</td>
                  <td>FLIGHT NO</td>

                  <td>EDIT</td>
                </tr>
              </thead>
              <tbody>
                {safariDetails.map((booking, index) => (
                  <tr class="table-row" key={index}>
                    <td className="alignleft">{booking.BOOKINGID || "N/A"}</td>
                    <td>{booking.CLIENTCODE || "N/A"}</td>
                    <td>
                      {booking.BOOKINGDATE
                        ? new Date(booking.BOOKINGDATE).toLocaleDateString(
                            "en-GB"
                          )
                        : "N/A"}
                    </td>
                    <td>
                      {booking.PICKUPTIME
                        ? new Date(booking.PICKUPTIME).toLocaleTimeString(
                            "en-GB"
                          )
                        : "N/A"}
                    </td>

                    <td>{booking.BOOKINGSTATUS || "N/A"}</td>
                    <td>{booking.SERVICESTATUS || "N/A"}</td>
                    <td className="w-[200px]">{booking.FLIGHTNO || "N/A"}</td>
                    <td>
                      <p className="cursor-pointer">
                        {" "}
                        <FaEdit />
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </aside>
        </main>
     API Key: 44a3b014636d4a838e7123935243101 LIVETRIAL Ends on 14/Feb/2024
     </section> */}
    </div>
  );
};

export default Footer;
