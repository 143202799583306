import React, { useState } from "react";
import MenuNavbar from "../../Components/Navbar/MenuNavbar";
import { FaEdit } from "react-icons/fa";
import { BsFillClipboardDataFill } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import Select from "react-select";
import "../InternalTransfer/InternalTransfer.css";
function InternalTransfer() {
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "400px",
      marginTop: "0px",
      minHeight: "initial",
      height: "45px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  return (
    <div>
      <MenuNavbar />
      <header className="header__of__main flex justify-between items-end px-3">
        <h1 className="header__of__page">Internal Transfer</h1>
        <aside className="btn_end">
          <button className="button_style ">New</button>
          <button className="button_style ">View Occupied Days</button>
        </aside>
      </header>

      <div className="last_transac">
        <fieldset className="fieldset_pro">
          <legend className="text-xl">Last Transaction</legend>
          <label>Trans No</label>
          <input style={{ width: "100px" }} />
          <input style={{ width: "100px" }} />
          <label>Date</label>
          <input />
          <label>Time</label>
          <input />
          <label>Location</label>
          <input />
          <label>Pax</label>
          <input />
        </fieldset>
      </div>
      <aside className="main">
        <p>
          <label>Transaction No</label>
          <input style={{ width: "100px" }}></input>
        </p>
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label>Vehicle</label>
          <div
            style={{
              width: "300px",
              margin: "5px",
            }}
          >
            <Select style={customStyless}></Select>
          </div>
        </p>
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label>Driver</label>
          <div
            style={{
              width: "300px",
              margin: "5px",
            }}
          >
            {" "}
            <Select style={customStyless}></Select>
          </div>
        </p>
        <p>
          <label>Pax</label>
          <input />
        </p>
      </aside>
      <aside className="main">
        <p>
          <label>Date</label>
          <input type="date"></input>
        </p>
        <p>
          <label>Pick Time</label>
          <input type="time"></input>
        </p>
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label>Pick Up</label>
          <p
            style={{
              width: "200px",
              margin: "5px",
            }}
          >
            <Select type="text"></Select>
          </p>
        </p>
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label>Drop</label>
          <div
            style={{
              width: "300px",
              margin: "5px",
            }}
          >
            <Select type="text"></Select>
          </div>
        </p>
        <p>
          <label>Drop Time</label>
          <input type="time"></input>
        </p>
      </aside>

      <aside>
        <main className="main_narr">
          <div className="main">
            <label>Narration</label>
            <textarea></textarea>
          </div>
          <div>
            <p>
              <label>Booking Ref</label>
              <input></input>
              <button className="button_style ">...</button>
              <input></input>
              <button className="button_style ">Save</button>
            </p>
            <p>
              <label>Client</label>
              <input className="ml-[50px]"></input>
            </p>
          </div>
        </main>
      </aside>
      <aside className="main_narr">
        <div>
          <label>From</label>
          <input type="date"></input>
        </div>
        <div>
          <label>To</label>
          <input type="date"></input>
        </div>
        <div>
          <button className="button_style " s>
            Refresh
          </button>
        </div>
      </aside>
      <article className="button_full_width">
        <div className="mx-2">
          <button
            className={activeTab === 1 ? "active__tab" : "button_style__tab"}
            onClick={() => handleTabClick(1)}
          >
            Pending Transfer
          </button>
          <button
            className={activeTab === 2 ? "active__tab" : "button_style__tab"}
            onClick={() => handleTabClick(2)}
          >
            Pending Drop Offs
          </button>
        </div>
      </article>

      <section
        style={{ display: activeTab === 1 ? "block " : "none" }}
        className={activeTab === 1 ? "active__section" : ""}
      >
        <aside className="table__reference__app ">
          <table class="custom-table">
            <thead class="header-cell">
              <td>MODULE&nbsp;ID</td>

              <td>EDIT</td>
            </thead>
            <tbody>
              <tr class="table-row">
                <td className="alignleft">1</td>

                <td>
                  <FaEdit />
                </td>
              </tr>
            </tbody>
          </table>
        </aside>
      </section>
      <section
        style={{ display: activeTab === 2 ? "block" : "none" }}
        className={activeTab === 2 ? "active__section" : ""}
      ></section>
    </div>
  );
}

export default InternalTransfer;
